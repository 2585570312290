var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"InflationAdjustmentReservation"},[_c('div',{staticClass:"tableWrap"},[_c('table',{staticClass:"percentYears"},[_c('thead',[_c('tr',[_c('th',{attrs:{"rowspan":"2"}},[_vm._v(" Kliento ID ")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v(" Sutarties Nr. ")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v(" Klientas ")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v(" kW ")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v(" Pradinis ")]),_vm._l((_vm.years),function(year){return _c('th',{key:year,attrs:{"colspan":"3"}},[_vm._v(" "+_vm._s(year)+" ")])})],2),_c('tr',[_vm._l((_vm.years),function(year){return [_c('th',{key:(year + "-1")},[_vm._v(" Sąskaitos data ")]),_c('th',{key:(year + "-2")},[_vm._v(" Indeksavimo data ")]),_c('th',{key:(year + "-3")},[_vm._v(" Indeksacija, % ")])]})],2)]),_c('tbody',[_c('tr',{staticClass:"projectPercentYears"},[_c('th',{attrs:{"colspan":"5"}},[_vm._v(" Bendra ")]),_vm._l((_vm.years),function(year){return [_c('th',{key:(year + "-1")}),_c('th',{key:(year + "-2")},[_c('Field',{class:{ invalid: !_vm.isValidDate(_vm.projectPercentYears[year]) },attrs:{"disabled":_vm.isSaving,"placeholder":(year + "-XX-XX"),"value":_vm.projectPercentYears[year].date},on:{"update:value":function($event){return _vm.setProjectDate(year, $event)}}})],1),_c('th',{key:(year + "-3")},[_c('Field',{class:{ invalid: !_vm.isValid(_vm.projectPercentYears[year]) },attrs:{"disabled":_vm.isSaving,"value":_vm.projectPercentYears[year].percent},on:{"update:value":function($event){return _vm.setProjectPercent(year, $event)}}})],1)]})],2),_vm._l((_vm.reservationsPercentYears),function(ref,reservationIndex){
var percentYears = ref.percentYears;
var contract = ref.contract;
var maintenanceDates = ref.maintenanceDates;
var reservation = ref.reservation;
var user = ref.user;
return _c('tr',{key:reservation.id,class:{
            alt: reservationIndex % 2,
            changed: _vm.hasChanged(
              reservation.inflationAdjustments,
              _vm.toAdjustmentRows(percentYears)
            ),
          }},[_c('td',{staticClass:"nowrap"},[_vm._v(" "+_vm._s(user.id)+" ")]),_c('td',{staticClass:"nowrap"},[_vm._v(" "+_vm._s(contract.contractNo)+" ")]),_c('td',{staticClass:"nowrap"},[_vm._v(" "+_vm._s(_vm._f("nameInitial")(user))+" ")]),_c('td',{staticClass:"nowrap"},[_vm._v(" "+_vm._s(reservation.capacity)+" ")]),_c('td',{staticClass:"nowrap"},[_vm._v(" "+_vm._s(_vm._f("currency")(reservation.maintenanceAnnualTotal))+" ")]),_vm._l((_vm.years),function(year){return [_c('td',{key:(year + "-1"),class:{ soon: maintenanceDates[year].isSoon }},[_c('Field',{attrs:{"disabled":true,"icon":maintenanceDates[year].isCreated ? 'bill' : 'clock',"value":maintenanceDates[year].date}})],1),_c('td',{key:(year + "-2")},[_c('Field',{class:{ invalid: !_vm.isValidDate(percentYears[year]) },attrs:{"disabled":_vm.isSaving,"placeholder":_vm.projectPercentYears[year].date,"value":percentYears[year].date},on:{"update:value":function($event){return _vm.setReservationDate(reservationIndex, year, $event)}}})],1),_c('td',{key:(year + "-3")},[_c('Field',{class:{ invalid: !_vm.isValid(percentYears[year]) },attrs:{"disabled":_vm.isSaving,"placeholder":_vm.projectPercentYears[year].percent,"value":percentYears[year].percent},on:{"update:value":function($event){return _vm.setReservationPercent(reservationIndex, year, $event)}}})],1)]})],2)})],2)])]),_c('Btn',{attrs:{"isLoading":_vm.isSaving},on:{"click":_vm.save}},[_vm._v(" Išsaugoti indeksavimą ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }