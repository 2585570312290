<template>
  <div class="AlertBarEso">
    <transition appear name="slide-up">
      <Notice
        v-if="projectsOutdated && !isClosed"
        class="danger"
        :isClosable="true"
        @close="isClosed = true">
        Reikia pateikti atnaujintus duomenis ESO apie šiuos projektus: {{ projectsOutdated }}.
        <Route class="Btn ib xs" to="AdminEsoSnapshotAdd">
          Generuoti ESO dokumentą
        </Route>
      </Notice>
    </transition>
  </div>
</template>

<script>
import { getters } from 'views/utils'

export default {
  data() {
    return {
      isClosed: false,
    }
  },
  computed: {
    projectsOutdated() {
      return this
        .query('project', { esoOutdatedAt: { $ne: null } })
        .map(project => project.name)
        .join(', ')
    },
    ...getters(),
  },
}
</script>

<style lang="scss">
.AlertBarEso {
  .Notice {
    margin: 0;

    .Btn {
      line-height: 1.25rem;
      margin-left: 1rem;
      padding: 0 1rem;
    }
  }
}
</style>
