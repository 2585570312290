<template>
  <BoardView hasBoardTable :pagination="pagination" title="Klientų procesas" :total="total">
    <BoardTable :hasResults="!!total" :isFetching="isFetching" :pagination="pagination">
      <CardUserStep
        v-for="userStep in results"
        :key="userStep.id"
        :userStep="userStep" />
    </BoardTable>
    <router-view />
  </BoardView>
</template>

<script>
import { pluckId, pluckIds } from 'views/utils/collections'
import CardUserStep from './components/admin/CardUserStep'
import Table from './mixins/Table'
import { actions } from './utils'

export default {
  mixins: [Table],
  components: { CardUserStep },
  data() {
    return {
      paginationOptions: {
        name: 'userStep',
        service: 'userStep',
        searchIn: [],
        filters: [
          {
            label: 'Kliento ID',
            type: 'number',
            append: 'userId',
          },
          {
            label: 'Būsena',
            type: 'select',
            options: [
              [1, 'Laukiama'],
              [2, 'Atliekama'],
              [3, 'Atlikta'],
              [4, 'Atšaukta'],
              [5, 'Nepavyko'],
            ],
            append: (value) => {
              if (!value) return

              if (value === 1) {
                return {
                  executionStartedAt: null,
                  cancelledAt: null,
                }
              }

              if (value === 2) {
                return {
                  executionStartedAt: { $ne: null },
                  executionDoneAt: null,
                  cancelledAt: null,
                  failure: null,
                }
              }

              if (value === 3) {
                return {
                  executionDoneAt: { $ne: null },
                  failure: null,
                }
              }

              if (value === 4) {
                return {
                  cancelledAt: { $ne: null },
                }
              }

              if (value === 5) {
                return {
                  failure: { $ne: null },
                }
              }
            },
          },
        ],
        customQuery: {
          executionDoneAt: null,
          cancelledAt: null,
        },
        sortOptions: [
          {
            label: 'Anksčiausi',
            sort: {
              executesAt: 1,
            },
          },
          {
            label: 'Naujausi',
            sort: {
              id: 1,
            },
          },
        ],
      },
    }
  },
  methods: actions(
    'FETCH_PROJECT',
    'FETCH_RESERVATION',
    'FETCH_USER',
  ),
  watch: {
    async results(userSteps) {
      if (!userSteps.length) return

      const reservationIds = pluckId('reservationId', userSteps)

      await this.LOAD(async () => {
        await Promise.all([
          this.FETCH_RESERVATION(reservationIds),
        ])

        const reservations = this.query('reservation', reservationIds)
        const [projectIds, userIds] = pluckIds([
          'projectId',
          'userId',
        ], reservations)

        return Promise.all([
          this.FETCH_PROJECT(projectIds),
          this.FETCH_USER(userIds),
        ])
      })
    },
  },
  created() {
    this.LOAD([
      'FETCH_STEP',
      'FETCH_STEP_CONTRACT',
      'FETCH_STEP_MESSAGE',
      'FETCH_STEP_PAYMENT',
    ])
  },
}
</script>

<style lang="scss">
</style>
