<template>
  <div class="BtnMulti" :class="{ expanded: isExpanded, expandDone: isExpandDone }">
    <div class="primary">
      <slot />
      <span class="expand" @click="toggle">
        <Icon name="down" />
      </span>
    </div>
    <transition name="slide-down">
      <div v-if="isExpanded" class="secondary">
        <slot name="secondary" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isExpanded: false,
      isExpandDone: false,
    }
  },
  methods: {
    toggle() {
      this.isExpanded = !this.isExpanded
      setTimeout(() => {
        this.isExpandDone = this.isExpanded
      }, 0.4)
    },
  },
}
</script>

<style lang="scss">
.BtnMulti {
  position: relative;

  > .primary {
    @include trans;
    background: $admin-background;
    border: 2px solid $border-color-dark;
    border-radius: $radius-xl;
    display: flex;
    overflow: hidden;
    position: relative;
    transition-delay: 0;
    z-index: 3;

    > .Btn {
      border: 0;
      // border-radius: $radius-xl 0 0 $radius-xl;
      border-radius: 0;
      transition-delay: inherit;
    }

    > .expand {
      @include trans;
      border-left: 2px solid $border-color-dark;
      // border-radius: 0 $radius-xl $radius-xl 0;
      cursor: pointer;
      padding: $grid-unit ($grid-unit * 1.25) $grid-unit $grid-unit;
      text-align: center;
      vertical-align: middle;

      &:hover {
        background: $warning;
      }
    }
  }

  > .secondary {
    background: $white;
    border: 2px solid $border-color-dark;
    border-radius: 0 0 $radius-xl $radius-xl;
    border-top: 0;
    overflow: hidden;
    position: absolute;
    transition-delay: 0.2s;
    width: 100%;
    z-index: 1;

    > .Btn {
      border: 0;
      border-radius: 0;
    }
  }

  &.expanded {
    > .primary {
      background: $white;
      border-radius: $radius-xl $radius-xl 0 0;

      > .expand {
        background: $warning;
      }
    }

    > .secondary {
      box-shadow: $shadow-lg;
    }
  }

  &.expandDone {
    > .primary {
      transition-delay: 0.3s;
    }

    > .secondary {
      transition-delay: 0;
    }
  }
}
</style>
