<template>
  <BoardView id="AdminEsoSnapshotAdd" title="Naujas ESO dokumentas">
    <FormValidation @submit="create">
      <FormEsoSnapshot :esoSnapshot="esoSnapshot" />
      <Field
        label="Tik pilnai apmokėti užsakymai"
        type="checkbox"
        :value.sync="isOnlyFullyPaid" />
      <template v-if="esoSnapshot.projectId">
        <FormSect
          v-if="esoSnapshotSent && esoSnapshotSent.authorUserId"
          title="Paskutinis pateiktas ESO dokumentas">
          <CardEsoSnapshot
            :esoSnapshot="esoSnapshotSent" />
        </FormSect>
        <Notice v-else class="confirm warning">
          Šis projektas dar neturi sugeneruotų ir išsiųstų ESO dokumentų.
          <Field
            data-cy="isConfirmed"
            label="Patikrinau ir patvirtinu, kad yra gautas leidimas gaminti elektrą šiam projektui"
            type="checkbox"
            :value.sync="isConfirmed" />
        </Notice>
        <Btn
          class="info"
          :disabled="!isSafe"
          :isLoading="isSaving"
          type="submit">
          Generuoti naują
        </Btn>
      </template>
    </FormValidation>
  </BoardView>
</template>

<script>
import { actions, getters } from './utils'
import BoardView from 'components/board/BoardView'
import CardEsoSnapshot from './components/admin/CardEsoSnapshot'
import FormEsoSnapshot from './components/forms/FormEsoSnapshot'

export default {
  components: {
    BoardView,
    CardEsoSnapshot,
    FormEsoSnapshot,
  },
  data() {
    return {
      isSaving: false,
      isConfirmed: false,
      isOnlyFullyPaid: false,
      esoSnapshot: this.$store.getters.esoSnapshotFactory({
        authorUserId: this.$store.getters.authUser.id,
      }),
      esoSnapshotCreatedId: null,
    }
  },
  computed: {
    esoSnapshotCreated() {
      return this.query('esoSnapshot', this.esoSnapshotCreatedId)
    },
    esoSnapshotSent() {
      if (!this.esoSnapshotsSent || !this.esoSnapshotsSent.length) return null

      return this.esoSnapshotsSent[0]
    },
    esoSnapshotsSent() {
      const { projectId } = this.esoSnapshot

      if (!projectId) return null

      return this.query('esoSnapshot', {
        projectId,
        sentAt: { $ne: null },
        $sort: { sentAt: -1 },
      })
    },
    isSafe() {
      return this.isConfirmed || this.esoSnapshotSent
    },
    ...getters(),
  },
  methods: {
    async create() {
      const { id } = await this.$wrap(
        this.CREATE_ESO_SNAPSHOT({
          ...this.esoSnapshot,
          isOnlyFullyPaid: this.isOnlyFullyPaid,
        }),
        'isSaving',
        'ESO dokumentas sukurtas',
      )

      this.$router.push({ name: 'AdminEsoSnapshot', params: { id } })
    },
    ...actions(
      'CREATE_ESO_SNAPSHOT',
      'FETCH_ESO_SNAPSHOT',
      'FETCH_USER',
    ),
  },
  watch: {
    async esoSnapshotSent(esoSnapshotSent) {
      if (!esoSnapshotSent) return

      await this.FETCH_USER(esoSnapshotSent.authorUserId)
    },
    async 'esoSnapshot.projectId'(projectId) {
      if (!projectId) return

      await this.FETCH_ESO_SNAPSHOT({ projectId })
    },
  },
}
</script>

<style lang="scss">
#AdminEsoSnapshotAdd {
  .Notice.confirm {
    padding: 2rem 1rem 1.5rem;

    .Field-cont {
      margin: 0;
    }
  }
}
</style>
