<template>
  <form class="PaymentAccountForm">
    <div class="fields">
      <Field
        :disabled="disabled"
        label="Pavadinimas atpažinimui"
        required
        type="text"
        :value.sync="paymentAccount.label" />
      <Field
        :disabled="disabled || isLocked"
        label="Payseros projekto ID"
        placeholder="123456"
        required
        type="number"
        :value.sync="paymentAccount.originProjectId" />
      <Field
        label="IBAN (įjungiamas Payseros MIP)"
        placeholder="LT60..."
        type="text"
        :value.sync="paymentAccount.iban" />
      <Field
        autocomplete="current-password"
        :disabled="disabled || isLocked"
        label="Projekto slaptažodis"
        placeholder="abc123...def456"
        required
        type="password"
        :value.sync="paymentAccount.password" />
      <Field
        :disabled="disabled || isLocked"
        label="Testiniai mokėjai"
        type="checkbox"
        :value.sync="paymentAccount.isTest" />
    </div>
    <div v-if="paymentAccount.iban">
      <Row>
        <Column :md="2">
          <Field
            label="Valstybė"
            placeholder="LT, LV, EE, GB, PL, DE..."
            :value.sync="paymentAccount.country" />
        </Column>
        <Column :md="3">
          <Field
            label="Apskirtis"
            placeholder="Vilniaus apskritis"
            :value.sync="paymentAccount.countrySubdivision" />
        </Column>
        <Column :md="2">
          <Field
            label="Miestas"
            placeholder="Vilnius"
            :value.sync="paymentAccount.town" />
        </Column>
        <Column :md="3">
          <Field
            label="Gatvė"
            placeholder="Pilaitės pr. 16"
            :value.sync="paymentAccount.street" />
        </Column>
        <Column :md="2">
          <Field
            label="Pastato numeris"
            :value.sync="paymentAccount.building" />
        </Column>
      </Row>
      <Row>
        <Column :md="3">
          <Field
            label="Pašto kodas"
            placeholder="LT12345"
            :value.sync="paymentAccount.postcode" />
        </Column>
        <Column :md="3">
          <Field
            label="Banko pavadinimas"
            :value.sync="paymentAccount.bankName" />
        </Column>
        <Column :md="3">
          <Field
            label="Pavadinimas"
            :value.sync="paymentAccount.name" />
        </Column>
        <Column :md="3">
          <Field
            label="BIC/SWIFT kodas"
            :value.sync="paymentAccount.bic" />
        </Column>
      </Row>
    </div>
    <Field
      :disabled="disabled"
      label="Banko sąskaitos informacija (rodoma sąskaitose-faktūrose)"
      required
      type="textarea"
      :value.sync="paymentAccount.bankDetails" />
    <div class="footerSlot">
      <slot />
    </div>
  </form>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    isLocked: Boolean,
    paymentAccount: Object,
  },
  data() {
    return {
      isAdding: false,
    }
  },
}
</script>

<style lang="scss">
.PaymentAccountForm {
  display: grid;
  gap: $grid-unit;
  grid-template-rows: auto;

  > .fields {
    display: grid;
    gap: $grid-unit;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  }

  .Field-cont {
    margin: 0;
  }

  .footerSlot {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
