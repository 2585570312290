var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Bar"},[_c('div',{staticClass:"Bar-parts"},[_vm._l((_vm.partsVisible),function(ref,index){
var color = ref.color;
var theme = ref.theme;
var width = ref.width;
return _c('div',{key:index,staticClass:"Bar-part",style:({ width: width })},[_c('div',{staticClass:"colored",class:theme,style:({ background: color ? ("#" + color) : undefined })})])}),(_vm.hasRemaining)?_c('div',{staticClass:"part remaining"},[_c('div',{staticClass:"colored"})]):_vm._e()],2),(_vm.hasLabels)?_c('div',{staticClass:"Bar-labels"},_vm._l((_vm.partsVisible),function(ref,index){
var label = ref.label;
var valueDisplayed = ref.valueDisplayed;
var width = ref.width;
return _c('BarSet',{key:index,staticClass:"Bar-label",style:({ width: width }),attrs:{"alt":(label + ": " + valueDisplayed),"label":label}},[_vm._v(" "+_vm._s(valueDisplayed)+" ")])}),1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }