<template>
  <div class="ReservationQuery">
    <div class="fields">
      <div v-for="(field, key) in fieldsTemplates" :key="key">
        <Field
          v-bind="field"
          @update:value="field.setValue(key, $event)" />
      </div>
    </div>
  </div>
</template>

<script>
import { always, assocPath, equals, path, pipe, when } from 'rambda'
import { cleanQuery } from './utils'
import { getters } from '@/views/utils'

export default {
  props: {
    value: Object,
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    fieldsTemplates() {
      const getValue = (key, defaultImplicit) => {
        const value = path(key, this.value)

        return value === undefined
          ? defaultImplicit
          : value
      }

      // for easier testing
      const NOT_TRUE = { $ne: true }

      return {
        hasSkippedReservation: {
          type: 'select',
          options: [
            { label: 'Visos', value: null },
            { label: 'Be rezervacinio mokėjimo', value: true },
            { label: 'Su rezervaciniu mokėjimu', value: false },
          ],
          label: 'Rezervacijos',
          value: pipe(
            getValue,
            when(equals(NOT_TRUE), always(false)),
          )('hasSkippedReservation', null),
          setValue: (key, value) => {
            const valueInQuery = {
              [null]: [], // empty array gets omitted in cleanQuery
              [false]: NOT_TRUE,
              [true]: true,
            }[value]

            return this.setKeyValue(key, valueInQuery)
          },
        },
      }
    },
    ...getters(),
  },
  methods: {
    setValue(value) {
      this.$emit('update:value', cleanQuery(value))
    },
    setKeyValue(fieldKey, value) {
      this.setValue(assocPath(fieldKey, value, this.value))
    },
  },
}
</script>

<style lang="scss">
.ReservationQuery {
}
</style>
