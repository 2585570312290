<template>
  <div class="TableHead">
    <TableTabs v-if="hasResults && hasTabs" :pagination="pagination" />

    <template v-if="hasFilters">
      <transition appear name="slide-down">
        <TableFilters v-if="doShowFilters" :pagination="pagination" />
      </transition>
      <div>
        <Btn class="sm inv info" data-cy="showFilters" @click="doShowFilters = !doShowFilters">
          <Icon :name="doShowFilters ? 'up' : 'down'" />
          {{ doShowFilters ? 'Paslėpti' : 'Rodyti' }} filtrus
        </Btn>
      </div>
    </template>

    <TableColumns
      v-if="hasColumns"
      :pagination="pagination" />
  </div>
</template>

<script>
import TableColumns from './TableColumns'
import TableFilters from './TableFilters'
import TableTabs from './TableTabs'

export default {
  components: {
    TableColumns,
    TableFilters,
    TableTabs,
  },
  props: {
    hasResults: Boolean,
    pagination: Object,
  },
  data() {
    return {
      doShowFilters: false,
    }
  },
  computed: {
    hasTabs() {
      return !!this.pagination.tabs.length
    },
    hasFilters() {
      return !!this.pagination.filters.length
    },
    hasColumns() {
      return !!this.pagination.dataColumns.length
    },
  },
}
</script>

<style lang="scss">
.TableHead {
  display: none;
  margin: 1rem 0;
  @include md {
    display: block;
  }

  .TableFilters {
    margin-bottom: 0.5rem;
  }
}
</style>
