<template>
  <BoardView hasBoardTable :pagination="pagination" title="Saulės moduliai" :total="total">
    <Route slot="button" class="Btn with-icon icon-side-left info" to="AdminSolarModuleAdd">
      <Icon name="plus" />
      Naujas
    </Route>
    <BoardTable :hasResults="!!total" :isFetching="isFetching" :pagination="pagination">
      <CardSolarModule v-for="solarModule in results" :key="solarModule.id" :solarModule="solarModule" />
    </BoardTable>
    <router-view />
  </BoardView>
</template>

<script>
import CardSolarModule from './components/admin/CardSolarModule'
import Table from './mixins/Table'
import { actions } from './utils'

export default {
  mixins: [Table],
  components: { CardSolarModule },
  data() {
    return {
      paginationOptions: {
        name: 'SolarModule',
        service: 'solarModule',
        searchIn: ['manufacturer', 'model'],

        sortOptions: [
          {
            label: 'Naujausi',
            sort: {
              id: -1,
            },
          },
          {
            label: 'Seniausi',
            sort: {
              id: 1,
            },
          },
        ],
      },
    }
  },
  methods: actions(),
  created() {
    this.LOAD('FETCH_SOLAR_MODULE')
  },
}
</script>
