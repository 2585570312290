<template>
  <div class="TableSearch">
    <Row>
      <Column v-if="hasSearch" :xs="columns">
        <Field
          icon="search"
          :placeholder="placeholder || 'Ieškoti'"
          type="text"
          :value="pagination.search"
          @update:value="$emit('update:search', $event)" />
      </Column>
      <Column v-if="hasSort" :xs="columns">
        <Field
          v-if="hasSort"
          :options="sortOptions"
          type="select"
          :value="pagination.sortIndex"
          @update:value="$emit('update:sortIndex', $event)" />
      </Column>
      <!--
      <template v-if="queryCount">
        <Column v-for="(field, key) in pagination.query" :md="12 / queryCount" :key="key">
          <QueryField :field="field"/>
        </Column>
      </template>
      -->
    </Row>
  </div>
</template>

<script>
// import QueryField from './QueryField'

export default {
  props: {
    pagination: Object,
    placeholder: String,
  },
  data() {
    return {
      sortLabels: {
        'sort.old': 'Seniausi',
        'sort.new': 'Naujausi',
      },
    }
  },
  computed: {
    columns() {
      return this.hasSort && this.hasSearch ? 6 : 12
    },
    hasSearch() {
      return this.pagination.search !== false
    },
    hasSort() {
      return this.pagination.sortOptions.length > 1
    },
    sortOptions() {
      return this.pagination
        .sortOptions
        .map(({ label, labelKey }, index) => [index, label || this.sortLabels[labelKey]])
    },
    // queryCount() {
    //   return Object.keys(this.pagination.query).length + (this.filterExists ? 1 : 0)
    // },
  },
}
</script>

<style lang="scss">
.TableSearch {
  padding: 0;

  .Field {
    margin-bottom: 0;

    &.with-icon {
      border: 1px solid $border-color;

      input,
      select {
        background: transparent;
        font-size: $h6;
      }

      > .Icon {
        border-right: none;
        color: $text-color-mid;
      }
    }
  }
}
</style>
