<script>
export default {
  props: {
    pagination: Object,
  },
  methods: {
    patchCustomQuery(customQuery) {
      this.patchPagination({
        customQuery: {
          ...this.pagination.customQuery || {},
          ...customQuery,
        },
      })
    },
    patchPagination(paginationPatch) {
      this.$store.dispatch('PATCH_PAGINATION', {
        pagination: this.pagination,
        ...paginationPatch,
      })
    },
    setCustomQuery(key, value) {
      // TODO: univesal dot solution
      if (key.includes('.')) {
        const [keyOuter, keyInner] = key.split('.')

        this.patchCustomQuery({
          [keyOuter]: { [keyInner]: value },
        })

        return
      }

      this.patchCustomQuery({
        [key]: value,
      })
    },
    setFilter(filterIndex) {
      this.patchPagination({
        filterIndex,
        pageIndex: 0,
      })
    },
    setPage(page) {
      this.patchPagination({
        page,
      })
    },
    setSearch(search) {
      this.patchPagination({
        pageIndex: 0,
        search,
      })
    },
    setSort(sortIndex) {
      this.patchPagination({
        pageIndex: 0,
        sortIndex,
      })
    },
  },
}
</script>
