<template>
  <div class="InflationAdjustmentReservation">
    <div class="tableWrap">
      <table class="percentYears">
        <thead>
          <tr>
            <th rowspan="2">
              Kliento ID
            </th>
            <th rowspan="2">
              Sutarties Nr.
            </th>
            <th rowspan="2">
              Klientas
            </th>
            <th rowspan="2">
              kW
            </th>
            <th rowspan="2">
              Pradinis
            </th>
            <th v-for="year in years" :key="year" colspan="3">
              {{ year }}
            </th>
          </tr>
          <tr>
            <template v-for="year in years">
              <th :key="`${year}-1`">
                Sąskaitos data
              </th>
              <th :key="`${year}-2`">
                Indeksavimo data
              </th>
              <th :key="`${year}-3`">
                Indeksacija, %
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <tr class="projectPercentYears">
            <th colspan="5">
              Bendra
            </th>
            <template v-for="year in years">
              <th :key="`${year}-1`" />
              <th :key="`${year}-2`">
                <Field
                  :class="{ invalid: !isValidDate(projectPercentYears[year]) }"
                  :disabled="isSaving"
                  :placeholder="`${year}-XX-XX`"
                  :value="projectPercentYears[year].date"
                  @update:value="setProjectDate(year, $event)" />
              </th>
              <th :key="`${year}-3`">
                <Field
                  :class="{ invalid: !isValid(projectPercentYears[year]) }"
                  :disabled="isSaving"
                  :value="projectPercentYears[year].percent"
                  @update:value="setProjectPercent(year, $event)" />
              </th>
            </template>
          </tr>
          <tr
            v-for="(
              { percentYears, contract, maintenanceDates, reservation, user },
              reservationIndex
            ) in reservationsPercentYears"
            :key="reservation.id"
            :class="{
              alt: reservationIndex % 2,
              changed: hasChanged(
                reservation.inflationAdjustments,
                toAdjustmentRows(percentYears),
              ),
            }">
            <td class="nowrap">
              {{ user.id }}
            </td>
            <td class="nowrap">
              {{ contract.contractNo }}
            </td>
            <td class="nowrap">
              {{ user | nameInitial }}
            </td>
            <td class="nowrap">
              {{ reservation.capacity }}
            </td>
            <td class="nowrap">
              {{ reservation.maintenanceAnnualTotal | currency }}
            </td>
            <template v-for="year in years">
              <td :key="`${year}-1`" :class="{ soon: maintenanceDates[year].isSoon }">
                <Field
                  :disabled="true"
                  :icon="maintenanceDates[year].isCreated ? 'bill' : 'clock'"
                  :value="maintenanceDates[year].date" />
              </td>
              <td :key="`${year}-2`">
                <Field
                  :class="{ invalid: !isValidDate(percentYears[year]) }"
                  :disabled="isSaving"
                  :placeholder="projectPercentYears[year].date"
                  :value="percentYears[year].date"
                  @update:value="setReservationDate(reservationIndex, year, $event)" />
              </td>
              <td :key="`${year}-3`">
                <Field
                  :class="{ invalid: !isValid(percentYears[year]) }"
                  :disabled="isSaving"
                  :placeholder="projectPercentYears[year].percent"
                  :value="percentYears[year].percent"
                  @update:value="setReservationPercent(reservationIndex, year, $event)" />
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
    <Btn :isLoading="isSaving" @click="save">
      Išsaugoti indeksavimą
    </Btn>
  </div>
</template>

<script>
import { toAdjustment, toAdjustmentRows } from './toAdjustmentRow'
import { actions } from 'views/utils'
import hasChanged from './hasChanged'
import { intoSequentialPromise } from 'views/utils/async'
import { pick } from 'rambda'

export default {
  props: {
    project: { type: Object, required: true },
    projectPercentYears: { type: Object, required: true },
    reservationsPercentYears: { type: Array, required: true },
    years: { type: Array, required: true },
  },
  data() {
    return {
      isSaving: false,
    }
  },
  methods: {
    hasChanged,
    toAdjustmentRows,
    isValidDate({ date, percent }) {
      if (!date) return !percent

      return date.length === 10 && date.charAt(4) === '-'
    },
    isValid({ date, percent }) {
      if (!percent) return !date

      if (!this.project.inflationLimit) return true

      return toAdjustment(percent) <= this.project.inflationLimit
    },
    setReservationDate(index, year, date) {
      this.reservationsPercentYears[index].percentYears[year].date = date
    },
    setReservationPercent(index, year, percent) {
      this.reservationsPercentYears[index].percentYears[year].percent = percent
    },
    setProjectDate(year, date) {
      this.projectPercentYears[year].date = date
    },
    setProjectPercent(year, percent) {
      this.projectPercentYears[year].percent = percent
    },
    async save() {
      const { projectPercentYears, reservationsPercentYears } = this

      await this.$wrap(
        async () => {
          await this.saveProjectPercentYears(projectPercentYears)
          await this.saveReservationAdjustments(reservationsPercentYears)
        },
        'isSaving',
        'Išsaugota',
      )
    },
    saveProjectPercentYears(projectPercentYears) {
      const inflationAdjustments = toAdjustmentRows(projectPercentYears)

      if (!hasChanged(this.project.inflationAdjustments, inflationAdjustments)) return

      return this.PATCH_PROJECT({
        id: this.project.id,
        inflationAdjustments,
      })
    },
    saveReservationAdjustments(reservationsPercentYears) {
      const patches = reservationsPercentYears
        .map(({ percentYears, reservation }) => ({
          id: reservation.id,
          inflationAdjustmentsExisting: reservation.inflationAdjustments,
          inflationAdjustments: toAdjustmentRows(percentYears),
        }))
        .filter(({ inflationAdjustments, inflationAdjustmentsExisting }) =>
          hasChanged(inflationAdjustmentsExisting, inflationAdjustments))

      if (!patches.length) return

      return patches
        .map(pick(['id', 'inflationAdjustments']))
        .map(reservationPatch => () => this.PATCH_RESERVATION(reservationPatch))
        .reduce(intoSequentialPromise, [])
    },
    ...actions(
      'PATCH_PROJECT',
      'PATCH_RESERVATION',
    ),
  },
}
</script>

<style lang="scss">
.InflationAdjustmentReservation {
  .tableWrap {
    overflow-x: scroll;
  }

  table.percentYears {
    margin-top: $grid-unit * 2;
    min-width: 100%;
    text-align: left;

    thead {
      background: $info;
      color: $white;
    }

    tbody tr {
      &.alt {
        background: $white-bis;
      }

      &.changed {
        background: $info-lighter;
      }

      &.changed.alt {
        background: $info-lightish;
      }

      &:hover {
        background: $grey-lightest;
      }

      &.projectPercentYears {
        background: $grey-lightest;
      }
    }

    td, th {
      min-width: 4rem;
      padding: $grid-unit-half;
    }

    th, td.nowrap {
      white-space: nowrap;
    }

    td.soon {
      background: $warning;
    }

    .Field-cont {
      margin: 0;
    }

    .Field {
      border-radius: $radius-sm;

      input {
        border-radius: $radius-sm;
        min-width: 6.5rem;
      }

      &.with-icon > .Icon {
        padding: 0.33rem 0.5rem 0.25rem;
      }
    }

    .invalid .Field input {
      background: $danger-light;
      border: 1px solid $danger;
    }
  }
}
</style>
