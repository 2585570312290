<template>
  <CardTable>
    <Row>
      <Column :lg="3" :xs="6">
        <Set icon="clock" name="Laikas">
          {{ userStep.executesAt | dateTime }}
        </Set>
      </Column>
      <Column :lg="3" :xs="6">
        <Set icon="bolt" name="Projektas">
          {{ project.name }}
        </Set>
      </Column>
      <Column :lg="3" :xs="6">
        <Set icon="user" name="Klientas">
          {{ user | name }}
        </Set>
      </Column>
      <Column :lg="3" :xs="6">
        <Set icon="dot" name="Rezervacijos ID">
          {{ userStep.reservationId }}
        </Set>
      </Column>
    </Row>
    <Row>
      <Column :lg="3" :xs="6">
        <Set icon="dot" name="Veiksmas">
          {{ eventName }}
        </Set>
      </Column>
      <Column :lg="3" :xs="6">
        <Set icon="tag" name="Pavadinimas">
          {{ userStep.resourceType }}
          <template v-if="resource">
            "{{ resource.name }}""
          </template>
        </Set>
      </Column>
      <Column :lg="3" :xs="6">
        <Set icon="record" name="Statusas">
          {{ status }}
          <template v-if="userStep.failure">
            ({{ userStep.failure }})
          </template>
        </Set>
      </Column>
      <Column :lg="3" :xs="6">
        <Set v-if="userStep.remaining" icon="asterisk" name="Kartoti dar">
          {{ userStep.remaining }}
          {{ (userStep.remaining % 10 || 20 >= userStep.remaining) ? 'kartus' : 'kartų' }}
        </Set>
      </Column>
    </Row>
    <div slot="meta">
      <span v-if="isDevelopment">{{ userStep.id }} |</span>
      {{ userStep.createdAt | dateTime }}
    </div>
  </CardTable>
</template>

<script>
import CardTable from './CardTable'
import Set from '../Set'
import { getters } from 'views/utils'

export default {
  components: { CardTable, Set },
  props: {
    userStep: Object,
  },
  computed: {
    eventName() {
      return this.getEventName(`${this.userStep.type}.${this.userStep.action}`)
    },
    project() {
      if (!this.reservation) return {}

      return this.query('project', this.reservation.projectId)
    },
    user() {
      return this.query('user', this.reservation.userId)
    },
    reservation() {
      return this.query('reservation', this.userStep.reservationId)
    },
    status() {
      if (this.userStep.failure) return 'Nepavyko'
      if (this.userStep.executionDoneAt) return 'Sėkmingai atlikta'
      if (this.userStep.cancelledAt) return 'Atšaukta'
      if (this.userStep.executionStartedAt) return 'Atliekama'

      return 'Laukiama'
    },
    templateType() {
      return ['stepContract', 'stepMessage', 'stepPayment']
        .find(templateType => this.userStep[`${templateType}Id`])
    },
    resource() {
      if (!this.templateType) return

      return this.query(this.templateType, this.userStep[`${this.templateType}Id`])
    },
    ...getters('getEventName', 'isDevelopment'),
  },
}
</script>

<style lang="scss">
</style>
