<script>
import { defaultTo, mergeRight, pick, pipe } from 'rambda'
import { actions } from 'views/utils'
import { buildServerQuery } from 'views/utils/query'

export default {
  data() {
    return {
      isExporting: false,
    }
  },
  computed: {
    exportType() {
      return this.pagination.service
    },
  },
  methods: {
    createSheetExport(options) {
      const data = pipe(
        defaultTo({}),
        pick(['type', 'query']),
        mergeRight({
          type: this.exportType,
          query: buildServerQuery(this.fetchQuery),
        }),
      )(options)

      return this.$wrap(
        async () => {
          const exported = await this.CREATE_SHEET_EXPORT(data)

          this.DOWNLOAD_FILE(exported.file)
        },
        'isExporting',
      )
    },
    ...actions('CREATE_SHEET_EXPORT', 'DOWNLOAD_FILE'),
  },
  render() {
    return ''
  },
}
</script>
