<template>
  <BoardView title="Redaguoti naujieną">
    <FormValidation @submit="create">
      <FormProjectPost :post="post" />
      <Btn class="info" :isLoading="isSaving" type="submit">
        Atnaujinti
      </Btn>
    </FormValidation>
  </BoardView>
</template>

<script>
import { actions, getters } from './utils'
import BoardView from 'components/board/BoardView'
import FormProjectPost from './components/forms/FormProjectPost'

export default {
  components: { BoardView, FormProjectPost },
  data() {
    return {
      isSaving: false,
      post: {},
    }
  },
  computed: getters(),
  methods: {
    async create() {
      try {
        this.isSaving = true
        await this.PATCH_PROJECT_POST(this.post)
        this.$router.push({ name: 'AdminProjectPosts', params: { id: this.post.projectId } })
      } finally {
        this.isSaving = false
      }
    },
    ...actions(
      'PATCH_PROJECT_POST',
    ),
  },
  mounted() {
    this.post = { ...this.query('projectPost', this.$route.params.id) }
  },
}
</script>
