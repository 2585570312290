<template>
  <div class="MaintenanceFees">
    <h5>Priežiūros mokesčiai ({{ payments.length }})</h5>
    <div
      v-for="(year, index) in years"
      :key="index"
      class="year"
      :class="getYearTheme(year)">
      <Icon name="dot" />
      {{ year.year.toString().slice(2) }}
      <div>
        <small>
          <template v-if="year.createdAt">
            {{ year.createdAt | isoDate }}
          </template>
          <template v-else>
            --
          </template>
        </small>
      </div>
    </div>
  </div>
</template>

<script>
import { prop, range, sortBy } from 'rambda'

export default {
  props: {
    payments: Array,
    yearsFreeMaintenance: { type: Number, default: 0 },
    yearsTotal: Number,
  },
  computed: {
    years() {
      const paymentsSorted = sortBy(prop('createdAt'), this.payments) // lowest id first
      const yearFirst = paymentsSorted[0].createdAt.getFullYear()
      const yearMaintenanceStarted = yearFirst - this.yearsFreeMaintenance

      return range(0, this.yearsTotal)
        .map((index) => ({
          isFree: index < this.yearsFreeMaintenance,
          year: yearMaintenanceStarted + index,
        }))
        .map((yearObject, index) => {
          if (yearObject.isFree) return yearObject

          const payment = paymentsSorted[index - this.yearsFreeMaintenance]

          return {
            ...(payment || {}),
            ...yearObject,
          }
        })
    },
  },
  methods: {
    getYearTheme(year) {
      if (year.isFree) return 'info'
      if (year.paidAt) return 'success'
      if (year.isCancelled) return 'danger'
      if (year.id) return 'warning'
    },
  },
}
</script>

<style lang="scss">
.MaintenanceFees {
  > h5 {
    margin: 0.5rem 0 0.75rem 0.5rem;
    text-transform: uppercase;
  }

  > .year {
    color: $text-color-light;
    display: inline-block;
    text-align: center;
    width: 7.5%;
    @include lg {
      width: 3.99%;
    }

    > .Icon {
      display: block;
    }

    &.success {
      color: $success;
    }

    &.info {
      color: $info;
    }

    &.danger {
      color: $danger;
    }

    &.warning {
      color: $warning;
    }
  }
}
</style>
