<template>
  <div>
    <FormSect title="Promo grupė">
      <Field
        label="Projektai"
        :options="projectsOptions"
        type="select"
        :value.sync="promo.projectId" />
      <Field
        label="Promo grupės kodas"
        :maxlength="16"
        :minlength="3"
        required
        rules="allLetters"
        :value="promo.prefix"
        @update:value="promo.prefix = $event ? $event.toUpperCase() : null" />
      <Field
        label="Individualių kodų kiekis"
        :max="1000"
        :min="1"
        required
        type="number"
        :value.sync="promo.quota" />
      <Field
        label="Privalu įvesti unikalų individualų promo kodą"
        type="checkbox"
        :value="!promo.isShared"
        @update:value="promo.isShared = !$event" />
      <DatePicker
        label="Galioja iki"
        required
        :value.sync="promo.expiresAt" />
    </FormSect>
    <FormSect
      sub="Įrašykite bent vieną promo kodo nuolaidą"
      title="Nuolaidos">
      <Row>
        <Column :md="4">
          <Field
            label="Užsakymo mokesčio dydis"
            :max="10000"
            :min="1"
            :placeholder="projectPricePerKw"
            type="number"
            :value.sync="promo.pricePerKw" />
        </Column>
        <Column :md="4">
          <Field
            v-if="promo.yearsFreeMaintenance"
            disabled
            label="Metai be priežiūros mokesčio"
            :max="100"
            :min="1"
            type="number"
            :value.sync="promo.yearsFreeMaintenance" />
        </Column>
        <Column :md="4">
          <Field
            label="Priežūros mokesčio dydis"
            :max="1000"
            :min="0"
            type="number"
            :value.sync="promo.maintenancePerKw" />
        </Column>
      </Row>
    </FormSect>
  </div>
</template>

<script>
import DatePicker from 'components/fields/DatePicker'
import { getters } from 'views/utils'
import { propOr } from 'rambda'

export default {
  components: { DatePicker },
  props: {
    promo: Object,
  },
  computed: {
    projectsOptions() {
      return [
        [null, 'Visi'],
        ...this
          .query('project')
          .map(project => [project.id, project.name]),
      ]
    },
    projectPricePerKw() {
      if (!this.promo.projectId) return null

      const project = this
        .query('project', this.promo.projectId)

      return propOr(null, 'price', project)
    },
    ...getters(),
  },
}
</script>

<style lang="scss">
</style>
