<template>
  <div class="FormStepMessage">
    <Field
      label="Tema"
      required
      :value="stepMessage.name"
      @update:value="$up('stepMessage.name', $event)" />
    <div class="text">
      <label class="Field-label" for="FormStepMessage-text">
        <strong>Tekstas</strong>
        Mielas, Kliente!<br>
        <Field
          inputId="FormStepMessage-text"
          required
          type="textarea"
          :value="stepMessage.text"
          @update:value="$up('stepMessage.text', $event)" />
        Saulėtos dienos linki<br>
        Saulės Bendruomenės komanda
      </label>
    </div>
    <Row>
      <Column :md="6">
        <Field
          label="Persiųsti žinutę į nustatymuose nurodytą el. paštą"
          type="checkbox"
          :value="stepMessage.doNotifyAdmin"
          @update:value="$up('stepMessage.doNotifyAdmin', $event)" />
      </Column>
      <Column :md="6">
        <Field
          v-if="stepMessage.doNotifyAdmin"
          label="Persiųsto laiško tema"
          type="text"
          :value="stepMessage.adminSubject"
          @update:value="$up('stepMessage.adminSubject', $event)" />
      </Column>
    </Row>
  </div>
</template>

<script>
export default {
  props: {
    stepMessage: Object,
  },
}
</script>

<style lang="scss">
.FormStepMessage {
  .text {
    display: block;
    padding-bottom: 1rem;

    .Field-label {
      font-weight: $medium;

      strong {
        display: block;
        margin-bottom: 0.5rem;
      }
    }

    .Field-cont {
      margin-bottom: 0;
    }
  }
}
</style>
