<template>
  <BoardView hasBoardTable :pagination="pagination" title="Vartotojai" :total="total">
    <Btn slot="button" class="success icon-side-left" :isLoading="isExporting" @click="createSheetExport">
      <Icon name="file" /> Eksportuoti
    </Btn>
    <BoardTable :hasResults="!!total" :isFetching="isFetching" :pagination="pagination">
      <div>
        <BulkMessages
          keyUserId="id"
          :records="results"
          :recordsSelectedIds.sync="recordsSelectedIds"
          :recordsSelectedUserIds.sync="recordsSelectedUserIds" />
        <CardUser
          v-for="user in results"
          :key="user.id"
          :isChecked="recordsSelectedIds.includes(user.id)"
          :user="user"
          @update:isChecked="selectRecord($event, { ...user, userId: user.id })" />
      </div>
    </BoardTable>
    <router-view />
  </BoardView>
</template>

<script>
import BulkMessages from './components/admin/BulkMessages'
import CardUser from './components/admin/CardUser'
import SheetExport from './mixins/SheetExport'
import Table from './mixins/Table'

export default {
  mixins: [SheetExport, Table],
  components: { CardUser, BulkMessages },
  data() {
    return {
      paginationOptions: {
        name: 'user',
        service: 'user',
        searchIn: [
          'firstName',
          'lastName',
          'email',
          'phone',
          'role',
        ],
        filters: [
          {
            label: 'Vardas',
            append: ['firstName', '$ilike'],
          },
          {
            label: 'Pavardė',
            append: ['lastName', '$ilike'],
          },
          {
            label: 'El. paštas',
            append: value => ({
              email: {
                $like: value.toLowerCase(),
              },
            }),
          },
          {
            label: 'Asmens kodas',
            append: ['personCode', '$like'],
          },
          {
            label: 'Sąskaitos numeris (be tarpų)',
            append: ['iban', '$like'],
          },
          {
            label: 'Telefono numeris',
            append: ['phone', '$like'],
          },
          {
            label: 'Gatvės adresas',
            append: ['address', '$ilike'],
          },
          {
            label: 'Miestas',
            append: ['city', '$ilike'],
          },
          {
            label: 'Pašto kodas',
            append: ['postCode', '$like'],
          },
          {
            label: 'Registracijos laikas, nuo',
            type: 'date',
            append: ['createdAt', '$gte'],
          },
          {
            label: 'Registracijos laikas, iki',
            type: 'date',
            append: ['createdAt', '$lte'],
          },
          {
            label: 'Registracijos tipas',
            type: 'select',
            options: [
              ['email', 'El. paštas'],
              ['facebook', 'Facebook'],
              ['google', 'Google'],
            ],
            append: (value) => ({
              email: {
                facebookId: null,
                googleId: null,
              },
              facebook: {
                facebookId: {
                  $ne: null,
                },
              },
              google: {
                googleId: {
                  $ne: null,
                },
              },
            })[value],
          },
          {
            label: 'Prenumeravo naujienas',
            type: 'select',
            options: [
              [true, 'Taip'],
              [false, 'Ne'],
            ],
            append: 'hasSubscribed',
          },
          {
            label: 'ID',
            type: 'number',
            append: 'id',
          },
        ],
      },
    }
  },
}
</script>

<style lang="scss">
</style>
