<template>
  <BoardView id="AdminDashboard" title="Apžvalga" wrap="xl">
    <Card v-if="hasRole('>=MANAGER')" class="statSummary">
      <Row>
        <Column :md="4">
          <Set class="lg" icon="user" name="Viso vartotojų">
            {{ userCount }}
          </Set>
        </Column>
        <template v-if="globalStat">
          <Column :md="4">
            <Set class="lg" icon="sun" name="Viso apmokėtų užsakymų">
              {{ reservationReservedCount }}
            </Set>
          </Column>
          <Column :md="4">
            <Set class="lg" icon="bill" name="Viso gautų apmokėjimų">
              {{ paymentAmountTotal | currency }}
            </Set>
          </Column>
        </template>
      </Row>
      <Row>
        <Column :md="4">
          <Set class="lg" icon="user" name="Šiandien užsiregistravo">
            {{ userCountToday }}
          </Set>
        </Column>
        <Column :md="4">
          <Set v-if="reservationStatToday" class="lg" icon="sun" name="Šiandien pradėti užsakymai">
            {{ reservationStatToday.count }}
          </Set>
        </Column>
        <Column :md="4">
          <Set v-if="paymentStatToday" class="lg" icon="bill" name="Šiandien gauti mokėjimai">
            {{ paymentStatToday.amountPaid | currency }}
          </Set>
        </Column>
      </Row>
    </Card>
    <Row>
      <Column :lg="4">
        <SubcardContainer title="Nauji vartotojai">
          <SubcardUser
            v-for="user in users"
            :key="user.id"
            :user="user" />
        </SubcardContainer>
        <Route class="Btn link db" to="AdminUsers">
          Rodyti visus vartotojus
        </Route>
      </Column>
      <Column :lg="8">
        <SubcardContainer title="Neapmokėti užsakymai">
          <SubcardReservation
            v-for="reservation in unpaidReservations"
            :key="reservation.id"
            :reservation="reservation" />
          <Empty v-if="!unpaidReservations.length">
            Viskas apmokėta
          </Empty>
        </SubcardContainer>
        <Route class="Btn link db" to="AdminReservations">
          Rodyti visus užsakymus
        </Route>
      </Column>
    </Row>
  </BoardView>
</template>

<script>
import { actions, getters } from 'views/utils'
import BoardView from 'components/board/BoardView'
import Set from './components/Set'
import SubcardContainer from './components/admin/SubcardContainer'
import SubcardReservation from './components/admin/SubcardReservation'
import SubcardUser from './components/admin/SubcardUser'
import { buildServerQuery } from './utils/query'
import { pluckId } from 'views/utils/collections'
import { prop } from 'rambda'

export default {
  components: { BoardView, Set, SubcardUser, SubcardContainer, SubcardReservation },
  data() {
    return {
      paymentStatToday: null,
      reservationStatToday: null,
      userCount: 0,
    }
  },
  computed: {
    today() {
      const date = new Date()
      date.setHours(0, 0, 0, 0)
      return date
    },
    globalStat() {
      return this.queryOne('projectStat', {
        projectId: null,
      })
    },
    paymentAmountTotal() {
      return this.globalStat.paymentPaidAmountTotal
    },
    reservationReservedCount() {
      return this.globalStat.reservationReservedCount
    },
    unpaidPayments() {
      // TODO: actually sort based by deadline date
      return this.query('payment', {
        paidAt: null,
        cancelledAt: null,
        $sort: { deadlineAt: -1 },
        $limit: 10,
      })
    },
    unpaidReservationsQuery() {
      const reservationIds = this.unpaidPayments.map(payment => payment.reservationId)

      return {
        id: { $in: reservationIds },
        $limit: reservationIds.length,
      }
    },
    unpaidReservations() {
      return this.query('reservation', this.unpaidReservationsQuery)
    },
    users() {
      return this.query('user', {
        $sort: { id: -1 },
        $limit: 10,
      })
    },
    userCountToday() {
      return this
        .query('user', {
          role: 'USER',
          createdAt: {
            $gteDate: this.today,
          },
        })
        .length
    },
    ...getters(),
  },
  methods: {
    async fetchStats() {
      const query = buildServerQuery({
        createdAt: { $gte: this.today },
      })

      const [reservationStats, paymentStats] = await Promise.all([
        this.FIND_RESERVATION_STAT(query),
        this.FIND_PAYMENT_STAT(query),
      ])

      if (reservationStats && reservationStats.length) {
        this.reservationStatToday = reservationStats[0]
      }
      if (paymentStats && paymentStats.length) {
        this.paymentStatToday = paymentStats[0]
      }
    },
    ...actions(
      'FETCH_PAYMENT',
      'FETCH_PROJECT_STAT',
      'FETCH_RESERVATION',
      'FETCH_USER',
      'FIND_RAW_USER',
      'FIND_RESERVATION_STAT',
      'FIND_PAYMENT_STAT',
    ),
  },
  watch: {
    async unpaidReservationsQuery(unpaidReservationsQuery) {
      if (!unpaidReservationsQuery.id.$in.length) return

      const reservations = await this.FETCH_RESERVATION(this.unpaidReservationsQuery)

      if (!prop('data', reservations)) return

      const userIds = pluckId('userId', reservations.data)

      if (!userIds.length) return

      return this.FETCH_USER({
        id: { $in: userIds },
      })
    },
  },
  async created() {
    this.LOAD(async () => {
      const [{ total: userCount }] = await Promise.all([
        this.FIND_RAW_USER({
          role: 'USER',
          $limit: 0,
        }),
        this.FETCH_PAYMENT({ // for unpaid payments
          paidAt: null,
          cancelledAt: null,
          $sort: { deadlineAt: -1 },
          $limit: 20,
        }),
        this.FETCH_PROJECT_STAT({
          projectId: null,
        }),
        this.FETCH_USER({
          role: 'USER',
          createdAt: {
            $gte: this.today,
          },
        }),
        this.fetchStats(),
      ])

      this.userCount = userCount
    })
  },
}
</script>

<style lang="scss">
#AdminDashboard {
  > .title {
    background: none;
    border: none;
    text-align: center;
  }

  .BoardView-inner {
    padding: 1rem;
    @include xxl {
      padding: 2rem 4rem;
    }
  }

  .Card.statSummary {
    background: none;
    border: none;
    box-shadow: none;
    font-weight: $semibold;
    margin-bottom: 2rem;
    padding: 1rem 1rem 0;
  }
}
</style>
