<template>
  <div v-if="totalPagesCount > 1" class="PaginationNav" :class="{ hasPerPageControls }">
    <div v-if="total && total > pageSizes[0]" class="page-meta">
      {{ `${pageStart} - ${pageEnd} iš ${total} ${label || 'įrašų'}` }}
    </div>
    <div class="pageNumbers">
      <template v-for="(page, index) in pagesNumerics">
        <div v-if="!page" :key="`x${index}`" class="dots">
          . . .
        </div>
        <div
          v-else
          :key="page"
          class="one"
          :class="{ active: (page - 1) === pageIndex }"
          @click="setPageIndex(page - 1)"
          @keydown.enter="setPageIndex(page - 1)">
          {{ page }}
        </div>
      </template>
    </div>
    <div v-if="hasPerPageControls">
      <Field
        :options="perPageOptions"
        type="select"
        :value="perPage"
        @update:value="$emit('update:perPage', $event)" />
    </div>
  </div>
</template>

<script>
// An example in procedural style:
// https://gist.github.com/kottenator/9d936eb3e4e3c3e02598#file-simple-pagination-js-L3
export default {
  props: {
    hasPerPageControls: {
      type: Boolean,
      default: true,
    },
    label: String,
    pageIndex: Number,
    perPage: Number,
    pageSizes: {
      type: Array,
      default: () => [6, 12, 20],
    },
    total: Number,
  },
  computed: {
    // @source https://gist.github.com/kottenator/9d936eb3e4e3c3e02598#gistcomment-2899014
    pagesNumerics() {
      const currentPage = this.pageIndex + 1
      const pageCount = this.totalPagesCount
      const separate = (a, b) => ({
        0: [a],
        1: [a, b],
        2: [a, a + 1, b],
      }[b - a] || [a, null, b])

      return new Array(3)
        .fill()
        .map((smth, index) => currentPage - 1 + index)
        .filter(page => page > 0 && page <= pageCount)
        .flatMap((page, index, { length }) => {
          if (!index) return separate(1, page)
          if (index === length - 1) return separate(page, pageCount)
          return [page]
        })
    },
    perPageOptions() {
      return this.pageSizes.map(perPage => [perPage, `${perPage} per puslapį`])
    },
    skipped() {
      return this.perPage * this.pageIndex
    },
    pageStart() {
      return this.skipped + 1
    },
    pageEnd() {
      return Math.min(this.skipped + this.perPage, this.total)
    },
    totalPagesCount() {
      return Math.ceil(this.total / this.perPage)
    },
  },
  methods: {
    setPageIndex(page) {
      this.$emit('update:pageIndex', page)
    },
  },
}
</script>

<style lang="scss">
.PaginationNav {
  display: flex;
  justify-content: space-between;
  margin: 0 0 $grid-unit;
  text-align: right;

  > .page-meta {
    align-items: center;
    display: flex;
  }

  > .pageNumbers {
    flex-grow: 1;

    > .dots {
      display: inline-block;
      font-size: $h3;
      transform: translateY(-0.125rem);
    }

    > .one {
      @include trans(0.3s, 'background, border-color');
      background: $white;
      border: 1px solid $border-color;
      border-radius: $radius-lg;
      cursor: pointer;
      display: inline-block;
      margin: 0 0.5rem;
      padding: 0.5rem 1rem;

      &:hover {
        border-color: $main-light;
        color: $main-dark;
      }

      &.active {
        border-color: $main;
        color: $main;
      }
    }
  }

  &.hasPerPageControls {
    text-align: center;
  }
}
</style>
