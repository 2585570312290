<template>
  <Card class="CardTable" :title="title">
    <div class="body">
      <slot />
    </div>
    <div class="actions">
      <div v-if="$slots.meta || isCheckable" class="meta">
        <Field
          v-if="isCheckable"
          data-cy="receiverSelection"
          type="checkbox"
          :value="isChecked"
          @update:value="$emit('update:isChecked', $event)" />
        <slot name="meta" />
      </div>
      <slot name="actions" />
    </div>
    <div v-if="$slots.appendix" class="appendix">
      <slot name="appendix" />
    </div>
  </Card>
</template>

<script>
export default {
  props: {
    title: String,
    isChecked: { type: Boolean, default: null },
  },
  computed: {
    isCheckable() {
      return typeof this.isChecked === 'boolean'
    },
  },
}
</script>

<style lang="scss">
.Card.CardTable {
  > .body {
    margin: 1rem;

    .Set {
      margin-bottom: 0.5rem;
    }
  }

  > .actions, > .appendix {
    border-top: 1px solid $border-color-light;
    padding: 0.5rem;
  }

  > .actions {
    align-items: center;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    text-align: right;

    > .meta {
      display: flex;
      float: left;
      justify-content: flex-start;
      padding: 0.25rem 0.5rem;

      .Field-cont {
        display: inline-block;
        margin: 0 1rem 0 0;
      }
    }

    > div > .Btn {
      margin-left: 0.5rem;
    }
  }

  &.scale {
    &:hover {
      transform: scale(1.01);
    }
  }
}
</style>
