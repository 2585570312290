<template>
  <form :ref="id" :id="id" :action="url" class="Dropzone dropzone vue-dropzone">
    <slot />
  </form>
</template>

<script>
import { actions, getters } from 'views/utils'
import Dropzone from 'dropzone'

const icon = name => `<i class="i-${name}"></i>`

export default {
  props: {
    id: {
      required: true,
      type: String,
    },
    url: {
      required: true,
      type: String,
    },
    dropzoneOptions: {
      default: () => ({}),
      type: Object,
    },
  },
  data() {
    return {
      dropzone: {},
    }
  },
  computed: {
    languageSettings() {
      const settings = {
        dictDefaultMessage: `<br>${this.dropzoneOptions.dictDefaultMessage || 'Įkelti failus'}`,
        dictCancelUpload: 'Atšaukti įkėlimą',
        dictCancelUploadConfirmation: 'Ar tikrai nori atšaukti?',
        dictFallbackMessage: 'Jūsų naršyklė nepalaiko failų įmetimo.',
        dictFallbackText: 'Naudokitės laukeliu žemiau.',
        dictFileTooBig: 'Failas yra per didelis ({{filesize}}MiB). Maksimalus leidžiamas failo dydis: {{maxFilesize}}MiB.',
        dictInvalidFileType: 'Negalima įkelti tokio tipo failo.',
        dictMaxFilesExceeded: 'Negalite įkelti daugiau failų.',
        dictRemoveFile: 'Panaikinti',
        dictRemoveFileConfirmation: null,
        dictResponseError: 'Serveris gražino {{statusCode}} kodą.',
      }

      if (this.useCustomDropzoneOptions && this.dropzoneOptions.language) {
        Object
          .keys(this.dropzoneOptions.language)
          .forEach((attrname) => {
            settings[attrname] = this.dropzoneOptions.language[attrname]
          })
      }

      return settings
    },
    cloudIcon() {
      return icon('file')
    },
    doneIcon() {
      return icon('check')
    },
    errorIcon() {
      return icon('cross')
    },
    ...getters('authHeaders'),
  },
  methods: {
    removeFile(file) {
      this.dropzone.removeFile(file)
    },
    addFile(file) {
      this.dropzone.addFile(file)
    },
    removeAllFiles(cancelIfNecessary) {
      this.dropzone.removeAllFiles(cancelIfNecessary)
    },
    ...actions(),
  },
  mounted() {
    if (this.$isServer) {
      return
    }

    Dropzone.autoDiscover = false
    if (this.confirm) {
      Dropzone.confirm = this.dropzoneOptions.confirm
    }

    const vm = this
    const element = document.getElementById(this.id)
    this.dropzone = new Dropzone(element, {
      // previewTemplate: this.previewTemplate,
      id: vm.id,
      url: vm.url,
      ...this.dropzoneOptions,
      ...this.languageSettings,
      headers: this.authHeaders,
      dictDefaultMessage: this.cloudIcon + this.languageSettings.dictDefaultMessage,
    })

    this.dropzone.on('error', (file, error, xhr) => {
      if (error !== 'Upload canceled.') {
        this.ERROR(error)
      }
    });

    [
      'addedFile',
      'thumbnail',
      'filesAdded',
      'removedFile',
      'success',
      'successMultiple',
      'error',
      'sending',
      'sendingMultiple',
      'queueComplete',
      'totalUploadProgress',
    ]
      .map(event => [event.toLowerCase(), event.split(/(?=[A-Z])/).join('-').toLowerCase()])
      .forEach((event) => {
        this.dropzone.on(event[0], (...arguments_) => {
          this.$emit(`vdropzone-${event[1]}`, ...arguments_)
        })
      })

    // Handle the dropzone events
    this.dropzone.on('addedfile', (file) => {
      this.$emit('isLoading', true)
      if (this.duplicateCheck && this.files.length) {
        this.files
          .filter(existingFile => existingFile.name === file.name)
          .forEach((existingFile) => {
            this.removeFile(file)
            this.$emit('duplicate-file', file)
          })
      }
    })
    this.dropzone.on('success', () => {
      this.$emit('isLoading', false)
    })
    this.dropzone.on('error', () => {
      this.$emit('isLoading', false)
    })
    this.$emit('vdropzone-mounted')
  },
  beforeDestroy() {
    this.dropzone && this.dropzone.destroy()
  },
}
</script>

<style lang="scss">
@import '~dropzone/dist/dropzone.css';

.vue-dropzone {
  border: 2px solid $grey-lightest;
  color: $grey;
  font-family: $font-body;
  letter-spacing: 0.2px;
  transition: background-color 0.2s linear;

  i {
    color: $grey;
  }

  .dz-message {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    margin: 0;
    min-height: 10rem;
  }

  .dz-preview {
    margin: 0.5rem;
    max-height: 4rem;
    min-height: 4rem;
    width: 96%;

    .dz-image {
      border-radius: 0;
      width: 100%;

      img {
        object-fit: cover;
      }

      &:hover {
        img {
          filter: none;
          transform: none;
        }
      }
    }

    .dz-details {
      background-color: $info;
      bottom: 0;
      color: $white;
      padding: 0.75rem 0.5rem;
      text-align: left;
      top: 0;
      transition: opacity 0.2s linear;

      .dz-filename span,
      .dz-size span {
        background-color: transparent;
        margin-bottom: 0.5rem;
      }

      .dz-filename:not(:hover) span {
        border: none;
      }

      .dz-filename:hover span {
        background-color: transparent;
        border: none;
      }
    }

    .dz-progress .dz-upload {
      background: $grey;
    }

    .dz-remove {
      border: 2px $white solid;
      bottom: 15px;
      color: $white;
      font-size: 0.8rem;
      font-weight: $semibold;
      letter-spacing: 1.1px;
      margin-left: 15px;
      opacity: 0;
      padding: 10px;
      position: absolute;
      text-decoration: none;
      text-transform: uppercase;
      top: inherit;
      z-index: 30;
    }

    .dz-success-mark,
    .dz-error-mark {
      left: 0;
      margin-left: auto !important;
      margin-top: auto !important;
      top: 35% !important;
      width: 100% !important;

      i {
        color: $white !important;
        font-size: 5rem !important;
      }
    }

    .dz-error-message {
      left: calc(50% - 35px);
      top: calc(50% + 25px);
    }

    &:hover {
      .dz-remove {
        opacity: 1;
      }
    }
  }

  &:hover {
    background-color: $white-ter;
  }
}
</style>
