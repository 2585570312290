<template>
  <BoardView hasBoardTable :pagination="pagination" title="Žymos" :total="total">
    <Route slot="button" class="Btn with-icon icon-side-left info" to="AdminSnippetAdd">
      <Icon name="plus" />
      Naujas
    </Route>
    <BoardTable :hasResults="!!total" :isFetching="isFetching" :pagination="pagination">
      <CardSnippet v-for="snippet in results" :key="snippet.id" :snippet="snippet" />
    </BoardTable>
    <router-view />
  </BoardView>
</template>

<script>
import CardSnippet from './components/admin/CardSnippet'
import Table from './mixins/Table'
import { actions } from './utils'

export default {
  mixins: [Table],
  components: { CardSnippet },
  data() {
    return {
      paginationOptions: {
        name: 'Snippet',
        service: 'snippet',
        searchIn: ['key', 'value'],

        sortOptions: [
          {
            label: 'Naujausi',
            sort: {
              id: -1,
            },
          },
          {
            label: 'Seniausi',
            sort: {
              id: 1,
            },
          },
        ],
      },
    }
  },
  methods: actions(),
  created() {
    this.LOAD('FETCH_SNIPPET')
  },
}
</script>
