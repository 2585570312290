<template>
  <div>
    <template v-if="docTemplateId && docTemplate.fileId">
      <File :file="file" @remove="$emit('update:docTemplateId', null)" />
      <Btn class="sm inv info ib" @click="$emit('update:docTemplateId', null)">
        Naudoti kitą šabloną
      </Btn>
    </template>
    <FileDrop
      v-else
      :data="{ type: 'DOC_TEMPLATE' }"
      message="Nėra pasirinktas joks šablonas. Užvilkite naują šabloną čia."
      @success="$emit('update:docTemplateId', $event.docTemplate.id)" />
  </div>
</template>

<script>
import File from '../File'
import { getters } from '../../utils'

const FileDrop = () => import(/* webpackChunkName: "dropzone" */ '../FileDrop')

export default {
  components: { File, FileDrop },
  props: {
    docTemplateId: Number,
  },
  computed: {
    docTemplate() {
      return this.query('docTemplate', this.docTemplateId)
    },
    file() {
      return this.query('file', this.docTemplate.fileId)
    },
    ...getters(),
  },
}
</script>

<style lang="scss">
</style>
