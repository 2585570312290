<template>
  <div class="FormStepEvents">
    <FieldLabel
      :label="label"
      :required="required !== false" />
    <div class="eventList">
      <FormStepEvent
        v-for="(event, index) in events"
        :key="index"
        :canRemove="!required || events.length > 1"
        :eventTypesAllowed="eventTypesAllowed"
        :fieldsAdditional="fieldsAdditional"
        :stepEvent="event"
        @remove="removeEvent(index)"
        @update:stepEvent="setEvent(index, $event)" />
      <Empty v-if="!events.length">
        Nieko
      </Empty>
      <div class="actions">
        <Btn class="success ib inv" icon="plus" @click="addEvent">
          Pridėti
        </Btn>
      </div>
    </div>
  </div>
</template>

<script>
import { append, map, pipe, update } from 'rambda'
import FormStepEvent from './FormStepEvent'
import { getters } from '@/views/utils'
import { removeIndex } from '@/views/utils/collections'

export default {
  components: { FormStepEvent },
  props: {
    eventTypesAllowed: { type: Array, required: true },
    events: { type: Array, required: true },
    fieldsAdditional: { type: Array, default: () => [] },
    label: { type: String, required: true },
    required: { type: Boolean, default: false },
  },
  data() {
    return {
      isEditing: false,
    }
  },
  computed: getters(
    'getEventKind',
  ),
  methods: {
    updateEvents(events) {
      this.$emit('update:events', events)
    },
    addEvent() {
      const overrides = pipe(
        map(key => [key, null]),
        Object.fromEntries,
      )(this.fieldsAdditional)

      const event = stepEventFactory(overrides)
      const events = append(event, this.events)

      this.updateEvents(events)
    },
    setEvent(index, event) {
      this.updateEvents(update(
        index,
        event,
        this.events,
      ))
    },
    removeEvent(index) {
      this.updateEvents(removeIndex(
        index,
        this.events,
      ))
    },
  },
}

const stepEventFactory = (overrides = {}) => ({
  resourceId: null,
  resourceType: null,
  action: null,
  type: null,
  projectQuery: null,
  reservationQuery: null,
  ...overrides,
})
</script>

<style lang="scss">
.FormStepEvents {
  border-bottom: 1px solid $border-color;
  margin-bottom: $grid-unit;

  .eventList {
    .FormStepEvent {
      margin-bottom: $grid-unit;
    }

    > .actions {
      display: flex;
      justify-content: flex-end;
      margin-bottom: $grid-unit;
    }
  }
}
</style>
