<template>
  <BoardView hasBoardTable :pagination="pagination" title="Inverteriai" :total="total">
    <Route slot="button" class="Btn with-icon icon-side-left info" to="AdminSolarInverterAdd">
      <Icon name="plus" />
      Naujas
    </Route>
    <BoardTable :hasResults="!!total" :isFetching="isFetching" :pagination="pagination">
      <CardSolarInverter v-for="solarInverter in results" :key="solarInverter.id" :solarInverter="solarInverter" />
    </BoardTable>
    <router-view />
  </BoardView>
</template>

<script>
import CardSolarInverter from './components/admin/CardSolarInverter'
import Table from './mixins/Table'
import { actions } from './utils'

export default {
  mixins: [Table],
  components: { CardSolarInverter },
  data() {
    return {
      paginationOptions: {
        name: 'solarInverter',
        service: 'solarInverter',
        searchIn: ['manufacturer', 'model'],

        sortOptions: [
          {
            label: 'Naujausi',
            sort: {
              id: -1,
            },
          },
          {
            label: 'Seniausi',
            sort: {
              id: 1,
            },
          },
        ],
      },
    }
  },
  methods: actions(),
  created() {
    this.LOAD('FETCH_SOLAR_INVERTER')
  },
}
</script>
