<template>
  <CardTable
    class="scale"
    :isChecked="isChecked"
    :title="getContractTypeLabel(contract.type)"
    @update:isChecked="$emit('update:isChecked', $event)">
    <Row>
      <Column :lg="3" :sm="6">
        <Set icon="user" name="Vartotojas">
          {{ recipient | name }} ({{ contract.userId }})
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set icon="bolt" name="Projektas">
          {{ project.name }}
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set icon="euro" name="Užsakymo suma">
          {{ reservation.priceTotal | currency }}
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set icon="bolt" name="Užsakymo dydis">
          {{ reservation.capacity | kw }}
        </Set>
      </Column>
    </Row>
    <Row>
      <Column :lg="3" :sm="6">
        <Set icon="file" name="Numeris">
          {{ contract.contractNo || 'Nėra' }}
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set icon="tag" name="Promo kodas">
          {{ (promo && promo.prefix) || 'Nėra' }}
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set icon="tag" name="APVA etapas">
          {{ apvaStageDate || 'Nėra' }}
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set icon="tag" name="Būsena">
          {{ status }}
        </Set>
      </Column>
      <!--
      <Column :sm="6" :lg="3">
        <Set :icon="contract.authorUserId ? 'user' : 'cog'" name="Siuntėjas">
          {{ contract.authorUserId ? (sender | name) : 'Sistema' }}
        </Set>
      </Column>
      -->
      <Column :lg="3" :sm="6">
        <Set :icon="contract.isSeen ? 'eye' : 'eye-slash'" name="Matyta">
          {{ contract.isSeen ? 'Taip' : 'Ne' }}
        </Set>
      </Column>
    </Row>
    <div slot="meta">
      {{ contract.createdAt | dateTime }}
    </div>
    <div slot="actions">
      <Btn
        v-if="!reservation.isCancelled"
        class="ib sm inv no-border danger"
        :isLoading="isCancelling"
        @click="PROMPT({
          confirm: cancel,
          text: 'Ar tikrai norite nutraukti šį užsakymą? Tai nutrauks ir prie šio kontrakto pririštą užsakymą.',
        })">
        Nutraukti
      </Btn>
      <Btn
        v-if="hasRole('>=SUPERADMIN')"
        class="ib sm inv no-border danger"
        icon="danger"
        :isLoading="isDeleting"
        @click="PROMPT({
          confirm: remove,
          text: 'Ar tikrai norite ištrinti šią sutartį? Tai ištrins ir sutarties failus (sąskaitą faktūrą). Kopijos buhalterijoje liks nepaliestos. Jas trinti reikia atskirai.',
          typeConfirm: 'trinti testui',
        })">
        Ištrinti
      </Btn>
      <Btn
        class="ib sm info"
        icon="arrow-down"
        @click="DOWNLOAD_FILE(contract)">
        Atsisiųsti
      </Btn>
    </div>
  </CardTable>
</template>

<script>
import { actions, getters } from '../../utils'
import CardTable from './CardTable'
import Set from '../Set'

export default {
  components: { CardTable, Set },
  props: {
    contract: Object,
    isChecked: Boolean,
  },
  data() {
    return {
      isCancelling: false,
      isDeleting: false,
    }
  },
  computed: {
    project() {
      return this.query('project', this.contract.projectId)
    },
    apvaStageDate() {
      if (!this.reservation.apvaStageId) return null

      const { startDate } = this.query('apvaStage', this.reservation.apvaStageId)
      return startDate
        .toLocaleString('lt-LT', { year: 'numeric', month: 'long', day: 'numeric' })
    },
    promo() {
      if (!this.reservation.promoId) return null

      return this.query('promo', this.reservation.promoId)
    },
    recipient() {
      return this.query('user', this.contract.userId)
    },
    reservation() {
      return this.query('reservation', this.contract.reservationId)
    },
    status() {
      if (this.contract.cancelledAt) return 'Atšaukta'
      if (this.contract.signedAt) return 'Pasirašyta'

      return 'Laukiama pasirašymo'
    },
    ...getters(
      'getContractTypeLabel',
    ),
  },
  methods: {
    async cancel() {
      // TODO: cancel contract only through reservation or vice versa?
      await this.$wrap(
        this.PATCH_CONTRACT({
          id: this.contract.id,
          cancelledAt: new Date(),
        }),
        'isCancelling',
        'Sutartis nutraukta',
      )
    },
    async remove() {
      await this.$wrap(
        this.DELETE_CONTRACT(this.contract),
        'isDeleting',
        'Sutartis ištrinta',
      )
    },
    ...actions(
      'DELETE_CONTRACT',
      'DOWNLOAD_FILE',
      'PATCH_CONTRACT',
      'PROMPT',
    ),
  },
}
</script>

<style lang="scss">
</style>
