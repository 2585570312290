<template>
  <CardTable
    :isChecked="isChecked"
    :title="user | name"
    @update:isChecked="$emit('update:isChecked', $event)">
    <Row>
      <Column :lg="3" :xs="6">
        <Set icon="mail" name="El. paštas">
          {{ user.email }}
        </Set>
      </Column>
      <Column :lg="3" :xs="6">
        <Set icon="phone" name="Telefono nr.">
          {{ user.phone || '-' }}
        </Set>
      </Column>
      <Column :lg="3" :xs="6">
        <Set icon="home" name="Adresas">
          <template v-if="user.address">
            {{ user.address }}, {{ user.city }}, {{ user.postCode }}
          </template>
          <template v-else>
            -
          </template>
        </Set>
      </Column>
      <Column :lg="3" :xs="6">
        <Set :icon="signupType || 'mail'" name="Registracijos tipas">
          {{ (signupType || 'El. paštas') | capitalize }}
        </Set>
      </Column>
      <Column :lg="3" :xs="6">
        <Set icon="star" name="Naujienlaiškis">
          <span>
            {{ user.hasSubscribed ? 'Taip' : 'Ne' }}
          </span>
        </Set>
      </Column>
      <Column :lg="3" :xs="6">
        <Set icon="lock" name="Rolė">
          {{ (getRole(user.role) || {}).label }}
        </Set>
      </Column>
      <Column :lg="3" :xs="6">
        <Set icon="clock" name="Registracijos data">
          {{ user.createdAt | date }}
        </Set>
      </Column>
    </Row>
    <div v-if="!doHideActions" slot="actions">
      <span v-if="user.note" class="CardUser-note" :title="user.note">
        <Icon name="note" />
      </span>
      <Btn
        v-if="hasRole('>=ADMIN') || hasRole(`>${user.role}`)"
        class="ib sm inv no-border danger"
        @click="PROMPT({
          confirm: remove,
          text: 'Ar tikrai norite ištrinti šį vartotoją?',
          typeConfirm: 'trinti',
        })">
        Ištrinti
      </Btn>
      <Route
        :id="user.id"
        class="Btn ib sm info inv no-border"
        to="AdminUser">
        Redaguoti
      </Route>
      <Route
        class="Btn ib sm info"
        :to="{ name: 'AdminMessagesAdd', params: { userId: user.id } }">
        Siųsti pranešimą
      </Route>
    </div>
  </CardTable>
</template>

<script>
import { actions, getters } from '../../utils'
import CardTable from './CardTable'
import Set from '../Set'

export default {
  components: { CardTable, Set },
  props: {
    doHideActions: Boolean,
    user: Object,
    isChecked: Boolean,
  },
  computed: {
    signupType() {
      if (this.user.facebookId) return 'facebook'
      if (this.user.googleId) return 'google'
      return null
    },
    ...getters('getRole', 'getUserStatus', 'hasRoleHigherThan'),
  },
  methods: {
    remove() {
      this.DELETE_USER(this.user)
      this.SUCCESS(`Vartotojas (${this.user.email}) ištrintas`)
    },
    ...actions('DELETE_USER', 'PROMPT'),
  },
}
</script>

<style lang="scss">
.CardUser-note {
  float: left;
}
</style>
