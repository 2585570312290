<template>
  <CardTable>
    <Row>
      <Column :md="6" :xl="4">
        <Set icon="clock" name="Etapo pradžia">
          {{ dateFormated }}
        </Set>
      </Column>
      <Column :md="3" :xl="2">
        <Set icon="tag" name="Rodomas">
          {{ apvaStage.isHidden ? 'Ne' : 'Taip' }}
        </Set>
      </Column>
      <Column :md="3" :xl="2">
        <Set icon="clock" name="Pridėtas">
          {{ apvaStage.createdAt | date }}
        </Set>
      </Column>
    </Row>
    <div slot="actions">
      <Btn
        class="ib sm inv no-border danger"
        @click="PROMPT({ text: promptText, confirm: toggleVisibility })">
        {{ apvaStage.isHidden ? 'Rodyti' : 'Slėpti' }}
      </Btn>
    </div>
  </CardTable>
</template>

<script>
import CardTable from './CardTable'
import Set from '../Set'
import { actions } from '../../utils'

export default {
  components: { CardTable, Set },
  props: {
    apvaStage: Object,
  },
  computed: {
    dateFormated() {
      return this.apvaStage.startDate
        .toLocaleString('lt-LT', { year: 'numeric', month: 'long', day: 'numeric' })
    },
    promptText() {
      return this.apvaStage.isHidden
        ? 'Ar tikrai norite rodyti šį etapą?'
        : 'Ar tikrai norite nerodyti šio etapo?'
    },
  },
  methods: {
    toggleVisibility() {
      this.PATCH_APVA_STAGE({
        id: this.apvaStage.id,
        isHidden: !this.apvaStage.isHidden,
      })
    },
    ...actions('PATCH_APVA_STAGE', 'PROMPT'),
  },
}
</script>
