<template>
  <CardTable class="scale" :title="post.title">
    <Row>
      <Column :lg="3" :sm="6">
        <Set icon="newspaper" name="Naujienos data">
          {{ post.dateAt | date }}
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set icon="clock" name="Paskelbta">
          {{ post.createdAt | date }}
        </Set>
      </Column>
    </Row>
    <div slot="actions">
      <Btn
        class="ib sm inv no-border danger"
        @click="PROMPT({ text: 'Ar tikrai norite ištrinti šią naujieną?', confirm: remove })">
        Ištrinti
      </Btn>
      <Route
        class="Btn ib sm info"
        :to="{ name: 'AdminProjectPost', params: { projectId, id: post.id } }">
        Redaguoti
      </Route>
    </div>
  </CardTable>
</template>

<script>
import CardTable from './CardTable'
import Set from '../Set'
import { actions } from '../../utils'

export default {
  components: { CardTable, Set },
  props: {
    post: Object,
  },
  computed: {
    projectId() {
      return Number(this.$route.params.id)
    },
  },
  methods: {
    remove() {
      this.DELETE_PROJECT_POST(this.post)
    },
    ...actions('DELETE_PROJECT_POST', 'PROMPT'),
  },
}
</script>

<style lang="scss">
</style>
