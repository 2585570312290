<template functional>
  <div
    :class="['MenuLinkSidebar', data.class, data.staticClass]"
    v-on="listeners">
    <Route
      v-if="props.link.to"
      :key="props.link.label"
      :exact="$options.isExact(props.link)"
      :title="props.link.label"
      :to="props.link.to">
      <Icon v-if="props.link.icon" :name="props.link.icon" />
      <span class="label">
        {{ props.link.label }}
      </span>
    </Route>
    <a
      v-if="props.link.href"
      :key="props.link.label"
      :href="props.link.href"
      :tabindex="props.index + 2">
      {{ props.link.label }}
    </a>
  </div>
</template>

<script>
export default {
  props: {
    index: Number,
    link: Object,
  },
  isExact({ isExact, children = [] }) {
    return typeof isExact === 'undefined'
      ? !!children.length
      : isExact
  },
}
</script>

<style lang="scss">
.MenuLinkSidebar {
  border-right: 1px solid $main;
  display: inline-block;
  font-size: 1.125rem;
  line-height: $lh;
  padding: 0 1rem;

  a {
    border-bottom: 0;
    color: $text-color-lighter;

    &.router-link-active {
      color: $white;
      font-weight: $semibold;
      text-shadow: none;
    }
  }

  &:hover {
    cursor: pointer;

    a {
      color: $white;
    }
  }

  &.last {
    border-right: 0;
  }
}
</style>
