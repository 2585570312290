<template>
  <Notice
    v-if="projectsMissingAdjustments.length && !isClosed"
    class="AlertBarInflationAdjustments danger"
    :isClosable="true"
    @close="isClosed = true">
    Reikia užpildyti priežiūros mokėjimų indeksaciją šiems projektams:
    <span v-for="project in projectsMissingAdjustments" :key="project.id">
      {{ project.name }}
    </span>
  </Notice>
</template>

<script>
import { getters } from 'views/utils'

export default {
  data() {
    return {
      isClosed: false,
    }
  },
  computed: getters('projectsMissingAdjustments'),
}
</script>

<style lang="scss">
.AlertBarInflationAdjustments {
  margin: 0;
}
</style>
