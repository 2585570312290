<template functional>
  <Icon :class="['SetIcon', data.class, data.staticClass]" :name="props.icon" />
</template>

<script>
export default {
  props: {
    icon: String,
  },
}
</script>

<style lang="scss">
$_size: 2.5rem;

.SetIcon {
  border-radius: $radius-round;
  color: $info;
  display: inline-block;
  font-size: $h3;
  height: $_size;
  line-height: $_size;
  margin-right: 0.25rem;
  text-align: center;
  width: $_size;

  // size
  &.lg {
    height: 2.5rem;
    line-height: 2.5rem;
    margin-top: 0.25rem;
    width: 2.5rem;
  }

  &.xl {
    font-size: $h2;
    height: 3rem;
    line-height: 3rem;
    width: 3rem;
  }

  // theme
  &.success {
    color: $success;
  }

  &.main {
    color: $main;
  }

  &.danger {
    color: $danger;
  }

  &.warning {
    color: $warning;
  }

  &.info {
    color: $info;
  }
}
</style>
