<template>
  <BoardView id="AdminProject" :title="project.name">
    <div>
      <Tab
        :isActive="tabActive === 'INFO'"
        @click="tabActive = 'INFO'">
        Informacija
      </Tab>
      <Tab
        :isActive="tabActive === 'MAINTENANCE'"
        @click="tabActive = 'MAINTENANCE'">
        Priežiūros mokesčiai
      </Tab>
    </div>
    <FormValidation v-if="project && tabActive === 'INFO'" @submit="save">
      <FormProject
        :documentForms.sync="documents"
        :project="project" />
      <Btn class="info" :isLoading="isSaving" type="submit">
        Išsaugoti
      </Btn>
    </FormValidation>
    <FormPaymentAdjustments
      v-else-if="tabActive === 'MAINTENANCE'"
      :project.sync="project" />
  </BoardView>
</template>

<script>
import { actions, detach, getters } from './utils'
import BoardView from 'components/board/BoardView'
import FormPaymentAdjustments from './components/forms/FormPaymentAdjustments'
import FormProject from './components/forms/FormProject'
import Tab from './components/Tab'
import { omit } from 'rambda'

export default {
  components: {
    BoardView,
    FormPaymentAdjustments,
    FormProject,
    Tab,
  },
  data() {
    return {
      documents: [],
      isSaving: false,
      project: {},
      tabActive: 'INFO',
    }
  },
  computed: {
    projectId() {
      return Number(this.$route.params.id)
    },
    ...getters(),
  },
  methods: {
    async save() {
      await this.$wrap(
        async () => {
          await Promise.all(this
            .documents
            .filter(document => document.title && document.fileId)
            .map(document => this.CREATE_PROJECT_DOCUMENT(document)))

          await this.PATCH_PROJECT(omit(['inflationAdjustments'], this.project))
        },
        'isSaving',
        'Projektas atnaujintas',
      )

      this.$router.push({ name: 'AdminProjects' })
    },
    ...actions(
      'CREATE_PROJECT_DOCUMENT',
      'LOAD_PROJECT_FILES',
      'PATCH_PROJECT',
    ),
  },
  created() {
    this.LOAD_PROJECT_FILES(this.projectId)
    this.POST_LOAD(() => {
      const project = this.query('project', this.projectId)

      if (!project) return

      this.project = detach(this.query('project', this.projectId))

      this.project.description = this.project.description || ''
    })
  },
}
</script>

<style lang="scss">
#AdminProject {
  .BoardView-inner {
    padding-bottom: 2rem;
  }
}
</style>
