<template>
  <div class="Sidebar">
    <SidebarSide
      v-if="screenSizes.includes('sm')"
      v-bind="$attrs"
      :activeIndex.sync="activeIndex"
      :links="links"
      v-on="$listeners" />

    <SidebarBurger
      v-else
      v-bind="$attrs"
      :activeIndex.sync="activeIndex"
      :links="links"
      v-on="$listeners" />
  </div>
</template>

<script>

import SidebarBurger from './SidebarBurger'
import SidebarSide from './SidebarSide'
import { getters } from '../utils'

export default {
  components: {
    SidebarBurger,
    SidebarSide,
  },
  data() {
    const { hasRole } = this.$store.getters

    return {
      activeIndex: null,
      links: [
        {
          to: 'AdminDashboard',
          icon: 'eye',
          label: 'Apžvalga',
          isExact: true,
        },
        {
          to: 'AdminProjects',
          icon: 'bolt',
          label: 'Projektai',
          isExact: false,
        },
        {
          to: 'AdminSolarInverters',
          icon: 'bolt',
          label: 'Inverteriai',
          isExact: false,
        },
        {
          to: 'AdminSolarModules',
          icon: 'bolt',
          label: 'Saulės moduliai',
          isExact: false,
        },
        {
          to: 'AdminSnippets',
          icon: 'bolt',
          label: 'Žymos',
          isExact: false,
        },
        {
          to: 'AdminReservations',
          icon: 'sun',
          label: 'Užsakymai',
          isExact: false,
        },
        {
          to: 'AdminContracts',
          icon: 'file',
          label: 'Sutartys',
          isExact: false,
        },
        {
          to: 'AdminPayments',
          icon: 'bill',
          label: 'Mokėjimai',
          isExact: false,
        },
        {
          to: 'AdminUsers',
          icon: 'user',
          label: 'Vartotojai',
          isExact: false,
        },
        {
          to: 'AdminMessages',
          icon: 'message',
          label: 'Pranešimai',
          isExact: true,
        },
        {
          to: 'AdminPromos',
          icon: 'tag',
          label: 'Promos',
          isExact: true,
        },
        {
          to: 'AdminEsoSnapshots',
          icon: 'note',
          label: 'ESO',
          isExact: true,
        },
        {
          to: 'AdminMail',
          icon: 'mail',
          label: 'Laiškai',
          isExact: true,
        },
        {
          to: 'AdminSteps',
          icon: 'process',
          label: 'Procesas',
          isExact: true,
        },
        {
          to: 'AdminStepsTimeline',
          icon: 'process',
          label: 'Klientų procesas',
          isExact: true,
        },
        {
          to: 'AdminLogs',
          icon: 'newspaper',
          label: 'Žurnalas',
          isExact: true,
        },
        {
          to: 'AdminSettings',
          icon: 'cog',
          label: 'Nustatymai',
          isExact: false,
        },
        ...hasRole('SUPERADMIN')
          ? [
            {
              to: 'AdminFeatureFlags',
              icon: 'cog',
              label: 'Galimybės',
              isExact: true,
            },
            {
              to: 'AdminPaymentGenerate',
              icon: 'payment',
              label: 'Mokėjimų generavimas',
              isExact: true,
            },
          ]
          : [],
      ],
    }
  },
  computed: getters('screenSizes'),
  methods: {
    determineActiveIndex() {
      this.activeIndex = this.links.findIndex(({ to, children = [] }) => {
        const routeName = typeof to === 'object' ? to.name : to
        const matchedRouteNames = [...children.map(({ to }) => to), routeName]
        return this.$route.matched.find(({ name }, matchedIndex) => matchedRouteNames.includes(name))
      })
    },
  },
  created() {
    this.determineActiveIndex()
  },
}
</script>

<style lang="scss">
$_background: $main;
$_background-children: $white-ter;
$_inactive-opacity: 1;

.Sidebar {
  .SidebarSide,
  .SidebarBurger,
  .SidebarBurger nav {
    background: $_background;

    a:hover {
      // color: $white;
      opacity: 0.9;
    }
  }

  .MenuLinkSidebar {
    background-color: $_background;
    opacity: $_inactive-opacity;

    a {
      color: $white;
    }
  }

  .children .MenuLinkSidebar {
    background-color: $_background-children;
    border-left: 3px solid rgba($white, $_inactive-opacity);
  }
}
</style>
