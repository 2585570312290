<template>
  <div class="StepEvent" :class="eventKind.theme">
    <Row>
      <Column :lg="2" :xs="3">
        <SetIcon
          class="lg"
          :class="eventKind.theme"
          :icon="eventKind.icon" />
      </Column>
      <Column :lg="10" :xs="9">
        <strong class="label">
          {{ labelPrefix }}
        </strong>
        <Tag v-if="resourceTypeName || resource" class="angular" :class="tagTheme">
          <template v-if="resourceTypeName">
            {{ resourceTypeName }}
          </template>
          <template v-if="resource">
            "{{ resource.name }}"
          </template>
        </Tag>
      </Column>
    </Row>
  </div>
</template>

<script>
import { capitalize, getters } from 'views/utils'
import SetIcon from 'components/SetIcon'
import { duration } from '@/views/utils/filters'

export default {
  components: { SetIcon },
  props: {
    stepEvent: Object,
  },
  computed: {
    resource() {
      const { type, resourceId } = this.stepEvent

      if (!resourceId) return

      const serviceName = `step${capitalize(type)}`

      return this
        .query(serviceName, resourceId)
    },
    resourceTypeName() {
      const { resourceType, type } = this.stepEvent

      const labelGetter = {
        contract: this.getContractTypeLabel,
        payment: this.getPaymentTypeLabel,
      }[type]

      if (!resourceType || !labelGetter) return null

      return labelGetter(resourceType)
    },
    concatName() {
      return `${this.stepEvent.type}.${this.stepEvent.action}`
    },
    eventKind() {
      return this.getEventKind(this.stepEvent.type)
    },
    labelPrefix() {
      const delayPrefix = this.stepEvent.delay
        ? `Po ${duration(this.stepEvent.delay)}`
        : ''

      return [delayPrefix, this.typeName]
        .filter(Boolean)
        .map((phrase, index) => index
          ? phrase
          : capitalize(phrase))
        .join(' ')
    },
    typeName() {
      return (this
        .getEventName(this.concatName) || '')
        .toLowerCase()
    },
    tagTheme() {
      return [
        this.eventKind.theme,
        ...(this.resource && this.resource.amendmentType)
          ? ['inv']
          : [],
      ]
    },
    ...getters(
      'getContractTypeLabel',
      'getEventKind',
      'getEventName',
      'getPaymentTypeLabel',
    ),
  },
}
</script>

<style lang="scss">
.StepEvent {
  @include trans;
  border: 1px solid $border-color;
  border-radius: $radius-md;
  padding: $grid-unit;

  .label {
    color: $text-color;
    display: block;
    line-height: $lh;
  }

  &:hover,
  &:focus {
    border-color: transparent;

    &.info {
      background: $info-light;
      box-shadow: 0 1px 20px $info-light;
    }

    &.success {
      background: $success-light;
      box-shadow: 0 1px 20px $success-light;
    }

    &.main {
      background: $main-light;
      box-shadow: 0 1px 20px $main-light;
    }

    &.danger {
      background: $danger-light;
      box-shadow: 0 1px 20px $danger-light;
    }
  }
}
</style>
