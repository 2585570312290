export const randomElementId = (() => {
  const beginningDigits = /^\d+/

  // http://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid-in-javascript
  return () => Math
    .random()
    .toString(36)
    .slice(2, 15)
    .replace(beginningDigits, '')
})()
