<template>
  <div v-if="stepEvents.length" class="StepEvents">
    <FieldLabel :label="label" />
    <div
      v-for="(branch, serialized) in branches"
      :key="serialized"
      class="branch"
      :class="{ single: hasSingleGlobal(branch, serialized) }">
      <div v-if="!isGlobal" class="serialized">
        <Icon name="code-branch" /> {{ serialized }}
      </div>
      <StepEventBranch
        :stepEvents="branch" />
    </div>
  </div>
</template>

<script>
import StepEventBranch from './StepEventBranch'
import { getters } from '@/views/utils'
import { groupBy } from 'rambda'

export default {
  components: { StepEventBranch },
  props: {
    label: String,
    stepEvents: Array,
  },
  computed: {
    branches() {
      return groupBy(
        this.serializeStepEventConditions,
        this.stepEvents,
      )
    },
    isGlobal() {
      const [first, ...rest] = Object.keys(this.branches)

      return !rest.length && first === this.globalSerialized
    },
    globalSerialized() {
      return this.serializeStepEventConditions(null)
    },
    ...getters('serializeStepEventConditions'),
  },
  methods: {
    hasSingleGlobal(branch, serialized) {
      return serialized === this.globalSerialized &&
        Object.keys(branch).length === 1
    },
  },
}
</script>

<style lang="scss">
.StepEvents {
  .branch {
    @include trans;
    border: 1px solid $border-color;
    border-radius: $radius-md;
    margin-bottom: $grid-unit;
    padding: $grid-unit;

    > .serialized {
      font-weight: $semibold;
      line-height: $lh;
      margin-bottom: $grid-unit-half;
    }

    &.single {
      border: 0;
      padding: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &:hover {
      background: $neutral-lighter;
      border-color: $info;
    }
  }
}
</style>
