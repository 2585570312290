<template>
  <div>
    <Field
      label="Pavadinimas"
      required
      :value.sync="step.name" />
    <FormStepEvents
      :eventTypesAllowed="triggers"
      :events.sync="step.triggers"
      :fieldsAdditional="['isExternal']"
      label="Kai"
      required />
    <FormStepEvents
      :eventTypesAllowed="outcomes"
      :events.sync="step.outcomes"
      :fieldsAdditional="['delay', 'limitType']"
      label="Tada"
      required />
    <FormStepEvents
      :eventTypesAllowed="triggers"
      :events.sync="step.cancellations"
      label="Nebent" />
    <Field
      label="Komentaras"
      :value.sync="step.comment" />
    <!--
    TODO: add warning message
    <div v-if="step.causeType === step.outcomeType && step.causeId === step.outcomeId">
      <Notice v-if="!step.limitType" class="warning">
        Įvykis yra pats su savimi surištas. Gali prireikti nenustatyti kartojimosi limitą. Jeigu įvykis gali pats save pradėti, tada be limito šis įvykis gali kartotis neribotą kiekį kartų.
      </Notice>
      <Field
        label="Kartojimosi limitas"
        :options="[['MAINTENANCE_PAID', 'Apmokama priežiūros trukmė']]"
        type="select"
        :value.sync="step.limitType" />
    </div>
    -->
  </div>
</template>

<script>
import FormStepEvents from './FormStepEvents'
import { getters } from '@/views/utils'

export default {
  components: { FormStepEvents },
  props: {
    step: Object,
  },
  data() {
    return {
      triggers: [
        'contract.cancelled',
        'contract.cancelledUnilateral',
        'contract.created',
        'contract.deleted',
        'contract.signed',
        'contract.visible',
        'payment.cancelled',
        'payment.created',
        'payment.deleted',
        'payment.paid',
        // 'project.adjustedInflation',
        'project.built',
        'reservation.amendedReservationSigned',
        'reservation.created',
      ],
      outcomes: [
        'contract.cancelled',
        'contract.created',
        'contract.amended',
        'contract.signed',
        // 'email.created',
        'message.created',
        'payment.cancelled',
        'payment.created',
        'payment.deleted',
      ],
    }
  },
  computed: getters(),
}
</script>

<style lang="scss">
// .FormStep {}
</style>
