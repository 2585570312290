<template>
  <BoardView title="Paskelbti naujieną">
    <FormValidation @submit="create">
      <FormProjectPost :post="post" />
      <Btn class="info" :isLoading="isSaving" type="submit">
        Skelbti
      </Btn>
    </FormValidation>
  </BoardView>
</template>

<script>
import BoardView from 'components/board/BoardView'
import FormProjectPost from './components/forms/FormProjectPost'
import { actions } from './utils'

export default {
  components: { BoardView, FormProjectPost },
  data() {
    return {
      isSaving: false,
      post: this.$store.getters.projectPostFactory({
        projectId: Number(this.$route.params.id),
      }),
    }
  },
  methods: {
    async create() {
      try {
        this.isSaving = true
        await this.CREATE_PROJECT_POST(this.post)
        this.$router.push({
          name: 'AdminProjectPosts',
          params: { id: Number(this.$route.params.id) },
          query: { refetch: 1 },
        })
      } finally {
        this.isSaving = false
      }
    },
    ...actions('CREATE_PROJECT_POST'),
  },
}
</script>
