<template>
  <Btn alt="Kopijuoti" class="BtnCopy ib xs" @click="copy(text)">
    <Icon name="file" />
  </Btn>
</template>

<script>
import { actions } from 'views/utils'

export default {
  props: {
    text: [String, Object],
  },
  methods: {
    copy(text) {
      if (typeof text !== 'string') {
        text = JSON.stringify(text, null, 2)
      }

      navigator.clipboard.writeText(text)

      this.SUCCESS('Nukopijuota')
    },
    ...actions('SUCCESS'),
  },
}
</script>

<style lang="scss">
.BtnCopy {
}
</style>
