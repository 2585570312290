<template>
  <BoardView v-if="isLoaded" :sub="project.name" :title="user | name">
    <section>
      <CardReservation :reservation="reservation" />
    </section>
  </BoardView>
</template>

<script>
import { actions, getters } from 'views/utils'
import BoardView from 'components/board/BoardView'
import CardReservation from './components/admin/CardReservation'

export default {
  components: { BoardView, CardReservation },
  computed: {
    project() {
      return this.query('project', this.reservation.projectId)
    },
    reservation() {
      return this.query('reservation', this.$route.params.id)
    },
    user() {
      return this.query('user', this.reservation.userId)
    },
    ...getters(),
  },
  methods: actions(
    'FETCH_RESERVATION',
    'LOAD_RESERVATIONS_CHILDREN',
    'LOAD_STEP_TREE',
    'FETCH_APVA_STAGE',
  ),
  async mounted() {
    await Promise.all([
      this.LOAD_STEP_TREE(),
      this.FETCH_RESERVATION(Number(this.$route.params.id)),
      this.FETCH_APVA_STAGE(),
    ])

    await this.LOAD_RESERVATIONS_CHILDREN([this.reservation])
  },
}
</script>

<style lang="scss">
</style>
