<template>
  <div class="BoardTable">
    <TableHead :pagination="pagination" />
    <div class="results" :class="{ isTable: !!pagination.dataColumns.length }">
      <slot v-if="isLoaded" />
    </div>
    <Loader v-if="isFetching" class="lg info" />
    <slot v-else-if="!hasResults" name="noResults">
      <Empty>
        Nėra rezultatų.
      </Empty>
    </slot>
  </div>
</template>

<script>
import PaginationAccess from 'mixins/PaginationAccess'
import TableHead from './TableHead'
import { getters } from '../../utils'

export default {
  mixins: [PaginationAccess],
  components: { TableHead },
  props: {
    hasResults: Boolean,
    isFetching: Boolean,
  },
  computed: getters(),
}
</script>

<style lang="scss">
.BoardTable {
  > .results {
    padding: 0.5rem;
    @include xl {
      padding: 1rem;
    }

    &.isTable {
      padding: 0.5rem 0;
      @include xl {
        padding: 1rem 0;
      }
    }
  }
}
</style>
