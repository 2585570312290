<template>
  <BoardView hasBoardTable :pagination="pagination" title="Projektai" :total="total">
    <Route slot="button" class="Btn with-icon icon-side-left info" to="AdminProjectsAdd">
      <Icon name="plus" />
      <span v-if="isDesktop">Naujas</span> projektas
    </Route>
    <BoardTable :hasResults="!!total" :isFetching="isFetching" :pagination="pagination">
      <CardProjectAdmin v-for="project in results" :key="project.id" :project="project" />
    </BoardTable>
    <router-view />
  </BoardView>
</template>

<script>
import { actions, getters } from './utils'
import CardProjectAdmin from './components/admin/CardProjectAdmin'
import Table from './mixins/Table'

export default {
  mixins: [Table],
  components: { CardProjectAdmin },
  data() {
    return {
      paginationOptions: {
        name: 'project',
        service: 'project',
        searchIn: ['name'],

        sortOptions: [
          {
            label: 'Tituline tvarka',
            sort: {
              order: 1,
            },
          },
          {
            label: 'Naujausi',
            sort: {
              id: -1,
            },
          },
          {
            label: 'Seniausi',
            sort: {
              id: 1,
            },
          },
        ],
      },
    }
  },
  computed: getters('isDesktop'),
  methods: actions(),
  created() {
    this.LOAD('FETCH_PROJECT_STAT')
  },
}
</script>
