<template>
  <BoardView title="Pridėti proceso etapą">
    <FormValidation @submit="submit">
      <FormStep
        :cancellation="cancellation"
        :cause="cause"
        :outcome="outcome"
        :step="step" />
      <Btn type="submit">
        Sukurti
      </Btn>
    </FormValidation>
  </BoardView>
</template>

<script>
import Step from './mixins/Step'
import { actions } from './utils'

export default {
  mixins: [Step],
  methods: {
    submit() {
      return this.$wrap(async () => {
        const step = await this.presubmit(this.step)

        await this.CREATE_STEP(step)

        this.$router.push({
          name: 'AdminSteps',
        })
      }, 'isLoading')
    },
    ...actions('CREATE_STEP'),
  },
  created() {
    this.step = this.$store.getters.stepFactory()
    this.hasFinishedInit = true
  },
}
</script>
