<template>
  <CardTable
    class="CardReservation"
    :isChecked="isChecked"
    :title="title"
    @update:isChecked="$emit('update:isChecked', $event)">
    <EsoObjectModal
      :isOpen.sync="isEsoModalOpen"
      :reservation="reservation"
      @close="isEsoModalOpen = false" />
    <Row>
      <Column :lg="3" :sm="6">
        <Set icon="euro" name="Suma">
          <span data-cy="priceTotal">
            {{ reservation.priceTotal | currency }} [{{ reservation.id }}]
          </span>
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set icon="bolt" name="Dydis">
          <span data-cy="capacity">
            {{ reservation.capacity | kw }}
          </span>
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set icon="bolt" name="Projektas">
          {{ project.name }}
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set icon="tag" name="Būsena">
          <Tag :class="reservation.isCancelled ? 'danger' : 'success'">
            {{ status }}
          </Tag>
        </Set>
      </Column>
    </Row>
    <Row>
      <Column :lg="3" :sm="6">
        <Set icon="clock" name="Sukurtas">
          {{ reservation.createdAt | dateTime }}
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set icon="tag" name="Promo kodas">
          <span data-cy="promo">
            {{ (promo && promo.prefix) || 'Nėra' }}
          </span>
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set icon="hard-hat" name="Priežiūros mokestis">
          <span data-cy="maintenanceAnnualTotal">
            {{ reservation.maintenanceAnnualTotal | currency }} ({{ reservation.maintenancePerKw | currency }}/kW)
          </span>
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set icon="hard-hat" name="Metai be mokesčio">
          <span data-cy="yearsFreeMaintenance">
            {{ reservation.yearsFreeMaintenance || 0 }}
          </span>
        </Set>
      </Column>
    </Row>
    <Row>
      <Column :lg="3" :sm="6">
        <Set icon="bill" name="APVA pasirinkimas">
          <span>
            {{ apvaChoice }}
          </span>
          <ReservationApvaChange :reservation="reservation" />
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set icon="bill" name="APVA etapas">
          <span>
            {{ apvaStage }}
          </span>
          <ReservationApvaChange :reservation="reservation" />
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set icon="star" name="Naujienlaiškis">
          <span>
            {{ user.hasSubscribed ? 'Taip' : 'Ne' }}
          </span>
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set icon="buildings" name="Vartojimo objektas">
          <Btn
            class="xs"
            @click="showEsoModal">
            Koreguoti
          </Btn>
        </Set>
      </Column>
    </Row>
    <CardReservationProgress
      :paymentsMaintenance="paymentsMaintenance"
      :reservation="reservation" />
    <div slot="actions">
      <Btn class="ib sm no-border" @click="doShowMessages = !doShowMessages">
        Užsakymo žinutės
      </Btn>
      <template v-if="hasRole('>=MANAGER')">
        <!--
      <Btn
        v-if="reservation.isCancelled"
        class="ib sm inv no-border warning"
        @click="PROMPT({
          confirm: uncancel,
          text: 'Ar tikrai norite aktyvuoti šį užsakymą? Tai aktyvuos ir atšauktas sutartis, bei mokėjimus. Šio užsaskymo mokėjimai nebus automatiškai atšaukti.',
        })">
        Aktyvuoti atšauktą užsakymą
      </Btn>
      -->
        <Btn
          v-if="!reservation.isCancelled"
          class="ib sm inv no-border danger"
          @click="doShowCancelPrompt = true">
          Atšaukti
        </Btn>
        <PromptReservationCancel
          v-if="doShowCancelPrompt"
          :reservation="reservation"
          @close="doShowCancelPrompt = false" />
        <Btn
          class="ib sm inv no-border danger"
          @click="PROMPT({
            confirm: () => DELETE_RESERVATION(reservation),
            text: 'Ar tikrai norite ištrinti šį užsakymą? Tai ištrins visas jos sutartis ir mokėjimus',
          })">
          Ištrinti
        </Btn>
      </template>
    </div>
    <div v-if="doShowMessages || paymentsMaintenance.length" slot="appendix">
      <MaintenanceFees
        :payments="paymentsMaintenance"
        :yearsFreeMaintenance="reservation.yearsFreeMaintenance"
        :yearsTotal="project.lifetimeYears" />
      <ReservationMessages
        v-if="doShowMessages"
        :reservation="reservation" />
    </div>
  </CardTable>
</template>

<script>
import { APVA_CHOICES, PAYMENT_TYPES } from '@/store/consts'
import { actions, getters } from 'views/utils'
import CardReservationProgress from './CardReservationProgress'
import CardTable from './CardTable'
import EsoObjectModal from '../../components/EsoObjectModal'
import MaintenanceFees from './MaintenanceFees'
import PromptReservationCancel from 'components/PromptReservationCancel'
import ReservationApvaChange from './ReservationApvaChange'
import ReservationMessages from './ReservationMessages'
import Set from '../Set'
import { name } from 'views/utils/filters'
import { prop } from 'rambda'

export default {
  components: {
    CardReservationProgress,
    CardTable,
    EsoObjectModal,
    MaintenanceFees,
    PromptReservationCancel,
    ReservationApvaChange,
    ReservationMessages,
    Set,
  },
  props: {
    isChecked: Boolean,
    reservation: Object,
  },
  data() {
    return {
      doShowCancelPrompt: false,
      doShowMessages: false,
      isEsoModalOpen: false,
    }
  },
  computed: {
    apvaChoice() {
      return APVA_CHOICES[this.reservation.apvaChoice] || 'Neparinkta'
    },
    apvaStage() {
      if (!this.reservation.apvaStageId) return 'Neparinkta'

      const { startDate } = this.query('apvaStage', this.reservation.apvaStageId)
      return startDate
        .toLocaleString('lt-LT', { year: 'numeric', month: 'long', day: 'numeric' })
    },
    promo() {
      if (!this.reservation.promoId) return null

      return this.query('promo', this.reservation.promoId)
    },
    paymentsMaintenance() {
      return this.query('payment', {
        reservationId: this.reservation.id,
        type: PAYMENT_TYPES.MAINTENANCE,
        $sort: { id: 1 },
      })
    },
    project() {
      return this.query('project', this.reservation.projectId)
    },
    user() {
      return this.query('user', this.reservation.userId)
    },
    status() {
      return this.getReservationStatus(this.reservation)
    },
    title() {
      const userName = name(this.user || {})
      const userId = prop('id', this.user)

      return `${userName} (${userId})`
    },
    ...getters('getReservationStatus'),
  },
  methods: {
    // async uncancel() {
    //   await this.PATCH_RESERVATION({
    //     id: this.reservation.id,
    //     isCancelled: false,
    //   })
    //   this.SUCCESS('Užsakymas vėl aktyvus')
    // },
    showEsoModal() {
      this.isEsoModalOpen = true
    },
    ...actions(
      // 'FETCH_APVA_STAGE',
      'DELETE_RESERVATION',
      'PROMPT',
    ),
  },
  // mounted() {
  //   if (this.reservation.apvaStageId) {
  //     this.FETCH_APVA_STAGE(this.reservation.apvaStageId)
  //   }
  // },
}
</script>

<style lang="scss">
</style>
