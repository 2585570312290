<template>
  <CardTable>
    <div>
      <Row>
        <Column :xl="3" :xs="6">
          <Set icon="mail" name="Gavėjas">
            {{ mail.to }}
          </Set>
        </Column>
        <Column :xl="3" :xs="6">
          <Set icon="lock" name="Tema">
            {{ mail.subject }}
          </Set>
        </Column>
        <Column :xl="3" :xs="6">
          <Set icon="mail" name="Būsena">
            {{ mail.isSent ? 'Išsiųtas' : 'Neišsiųstas' }}
          </Set>
        </Column>
        <Column :xl="3" :xs="6">
          <Set icon="clock" name="Data">
            {{ mail.createdAt | dateTime }}
          </Set>
        </Column>
      </Row>
      <div v-if="isOpen">
        <pre>{{ mail.text }}</pre>
      </div>
    </div>
    <div slot="actions">
      <Btn
        class="ib sm inv"
        @click="isOpen = !isOpen">
        {{ isOpen ? 'Uždaryti' : 'Peržiūrėti' }}
      </Btn>
    </div>
  </CardTable>
</template>

<script>
import CardTable from './CardTable'
import Set from '../Set'
import { getters } from '../../utils'

export default {
  components: { CardTable, Set },
  props: {
    mail: Object,
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: getters('getUserStatus'),
}
</script>

<style lang="scss">
</style>
