<template>
  <BoardView :pagination="pagination" title="Sistemos galimybės" :total="total">
    <BoardTable
      :hasResults="!!total"
      :isFetching="isFetching"
      :pagination="pagination">
      <RecordFeatureFlag
        v-for="featureFlag in results"
        :key="featureFlag.id"
        :featureFlag="featureFlag" />
    </BoardTable>
    <router-view />
  </BoardView>
</template>

<script>
import RecordFeatureFlag from './components/RecordFeatureFlag'
import Table from 'views/mixins/Table'

export default {
  mixins: [Table],
  components: { RecordFeatureFlag },
  data() {
    return {
      paginationOptions: {
        name: 'featureFlag',
        service: 'featureFlag',
        searchIn: [
          'key',
        ],
      },
    }
  },
}
</script>

<style lang="scss">
</style>
