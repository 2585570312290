<template>
  <BoardView :title="title">
    <FormValidation @submit="save">
      <FormSolarModule :solarModule="solarModule" />
      <Btn class="info" :isLoading="isSaving" type="submit">
        Išsaugoti
      </Btn>
    </FormValidation>
  </BoardView>
</template>

<script>
import { actions, getters } from './utils'
import BoardView from 'components/board/BoardView'
import FormSolarModule from './components/forms/FormSolarModule'

export default {
  components: { BoardView, FormSolarModule },
  data() {
    return {
      isSaving: false,
      solarModule: {},
    }
  },
  computed: {
    title() {
      return `${this.solarModule.manufacturer} ${this.solarModule.model}`
    },
    ...getters(),
  },
  methods: {
    async save() {
      try {
        this.isSaving = true
        await this.PATCH_SOLAR_MODULE(this.solarModule)
        this.$router.push({ name: 'AdminSolarModules' })
      } finally {
        this.isSaving = false
      }
    },
    ...actions('PATCH_SOLAR_MODULE'),
  },
  created() {
    this.POST_LOAD(() => {
      this.solarModule = { ...this.query('solarModule', Number(this.$route.params.id)) }
    })
  },
}
</script>

<style lang="scss">
</style>
