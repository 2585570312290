<template>
  <div class="SidebarSide" :class="{ open: isOpen }">
    <MainLogo v-if="isOpen" class="db" />
    <Route v-else class="MainLogo db" :exact="true" to="Index">
      <Pic alt="Saules Bendruomene" src="../../assets/logo-icon.svg" />
    </Route>

    <nav :class="{ 'margin-top': hasRole('>=ADMIN') }">
      <template v-for="(link, index) in links">
        <MenuLinkSidebar
          :key="index"
          :index="index"
          :link="link"
          @click="$emit('update:activeIndex', index)" />
        <transition-group :key="`${index}_transition`" appear name="slide-down">
          <div
            v-if="activeIndex === index && link.children && link.children.length"
            :key="`${index}_children`"
            class="children">
            <MenuLinkSidebar
              v-for="(child, childIndex) in link.children"
              :key="childIndex"
              :index="childIndex"
              :link="child">
              <Component
                :is="child.component.name"
                v-if="child.component"
                slot="icon"
                v-bind="child.component.attrs" />
            </MenuLinkSidebar>
          </div>
        </transition-group>
      </template>
    </nav>
    <div class="bottom">
      <Route data-cy="logout" to="Logout">
        <Icon name="lock" />
        {{ isOpen ? 'Atsijungti' : '' }}
      </Route>
      <div
        class="collapse"
        @click="toggle"
        @keydown.enter="toggle">
        <Icon :name="isOpen ? 'left' : 'right'" />
        {{ isOpen ? 'Suskleisti' : '' }}
      </div>
    </div>
  </div>
</template>

<script>
// import AvatarProfile from 'components/AvatarProfile'
import MainLogo from './TopBar/MainLogo'
import MenuLinkSidebar from './MenuLinkSidebar'
import { getters } from '../utils'

export default {
  components: {
    // AvatarProfile,
    MainLogo,
    MenuLinkSidebar,
  },
  props: {
    isOpen: Boolean,
    links: { type: Array, default: () => [] },
    activeIndex: { type: Number, required: false },
  },
  computed: getters(
    'isDesktop',
    'screenSizes',
    'primaryUserProfile',
    'hasRole',
  ),
  methods: {
    toggle() {
      this.$emit('update:isOpen', !this.isOpen)
    },
  },
}
</script>

<style lang="scss">
$_background: $white;
$_background-children: $white-ter;
$_inactive-opacity: 0.8;

.SidebarSide {
  @include trans(0.1s);
  background: $_background;
  border-right: 1px solid $main;
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  @include md {
    width: $sidebar-width;
  }

  > .MainLogo {
    align-self: center;
    display: block;
    height: 3rem;
    margin: 0.5rem;
    padding: 0.5rem;
    text-align: center;
  }

  .user-info {
    align-items: center;
    display: flex;
    margin: 1rem 0;
    padding: 1rem;

    .AvatarProfile {
      margin-right: 0.5rem;
    }
  }

  > nav {
    @include customScroll;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
    width: 100%;

    .MenuLinkSidebar {
      border-right: none;
      padding: unset;
      position: relative;
      z-index: 1;
    }

    .children {
      border-bottom: 1px solid $border-color;
      border-top: 1px solid $border-color;
      position: relative;

      .MenuLinkSidebar {
        background-color: $_background-children;
        border-left: 3px solid rgba($white, $_inactive-opacity);
        width: 100%;

        > a {
          padding-left: calc(1.5rem - 3px);
        }
      }
    }

    a {
      align-items: center;
      border-bottom: 0;
      color: inherit;
      display: flex;
      font-weight: $semibold;
      height: 3rem;
      line-height: $lh * 1.125;
      opacity: $_inactive-opacity;
      padding: 1rem 1rem 1rem 1.5rem;
      position: relative;
      text-align: left;
      white-space: nowrap;
      width: 100%;

      &::after {
        @include trans;
        background: transparent;
        bottom: 0;
        content: ' ';
        display: block;
        height: 1px;
        position: absolute;
        right: 0;
        width: 1px;
      }

      > span {
        @include trans;

        &.Icon {
          float: left;
          font-size: $h3;
          line-height: $lh;
        }

        &.label {
          display: inline-block;
          padding-left: 0.75rem;

          &.no-icon {
            padding-left: 2.5rem;
          }
        }
      }

      &:hover, &:focus {
        opacity: 1;
      }

      &.router-link-active {
        color: $white;
        opacity: 1;

        &::after {
          background: $white;
          border-radius: $radius 0 0 $radius;
          height: 100%;
          left: auto;
          top: 0;
          width: 4px;
        }
      }
    }

    &.margin-top {
      margin-top: 3rem;
    }
  }

  > .bottom {
    margin-top: auto;
    text-align: center;
    width: 100%;

    > a {
      border: 0;
      color: $grey-lightest;
      display: block;
      padding: 1rem;

      &:hover {
        background: $grey-lightest;
        color: $text-color-mid;
      }
    }

    > .collapse {
      color: $grey-lightest;
      cursor: pointer;
      padding: 1rem;

      &:hover {
        background: $grey-lightest;
        color: $text-color-mid;
      }
    }
  }

  &:not(.open) {
    width: $sidebar-width-compact;

    .user-info {
      justify-content: center;

      .AvatarProfile {
        margin-right: 0;
      }

      .email {
        display: none;
      }
    }

    > nav {
      a {
        text-align: center;

        > span {
          &.Icon {
            float: none;
          }

          &.label {
            display: none;
          }
        }
      }
    }
  }

  &.open {
    > .MainLogo {
      margin: 2rem 1.5rem;
    }
  }
}
</style>
