<template>
  <div class="ProjectQuery">
    <div class="fields">
      <div v-for="(field, key) in fieldsTemplates" :key="key">
        <FieldLabel
          v-if="field.type === 'autosuggest' || field.type === 'multiselect'"
          :label="field.label" />
        <AutosuggestProjects
          v-if="field.type === 'autosuggest'"
          v-bind="field"
          @update:value="field.setValue(key, $event)" />
        <MultiSelect
          v-if="field.type === 'multiselect'"
          v-bind="field"
          label="label"
          :multiple="true"
          trackBy="value"
          @update:value="field.setValue(key, $event)" />
      </div>
    </div>
  </div>
</template>

<script>
import { assocPath, find, isEmpty, map, path, pipe, pluck, propEq } from 'rambda'
import AutosuggestProjects from './AutosuggestProjects'
import MultiSelect from 'components/fields/MultiSelect'
import { cleanQuery } from './utils'
import { dissocPath } from '@/views/utils/collections'
import { getters } from '@/views/utils'

export default {
  components: {
    AutosuggestProjects,
    MultiSelect,
  },
  props: {
    value: Object,
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    fieldsTemplates() {
      const getValue = (key, defaultImplicit) => {
        const value = path(key, this.value)

        return value === undefined
          ? defaultImplicit
          : value
      }

      const toStepBranchOptions = map(stepBranch => find(
        propEq('value', stepBranch),
        this.projectStepBranchOptions,
      ))

      const queryProjects = ids => this.query('project', ids)

      return {
        'stepBranch.$in': {
          type: 'multiselect',
          label: 'Proceso šakos',
          options: this.projectStepBranchOptions,
          placeholder: 'Šakos',
          value: pipe(
            getValue,
            toStepBranchOptions,
          )('stepBranch.$in', []),
          required: true,
          setValue: (key, stepBranchOptions) =>
            this.setArrayValue(key, pluck('value', stepBranchOptions)),
        },

        'id.$in': {
          type: 'autosuggest',
          label: 'Projektai',
          options: this.projectOptions,
          value: pipe(
            getValue,
            queryProjects,
          )('id.$in', []),
          required: true,
          setValue: this.setFieldIds,
        },
        'id.$nin': {
          type: 'autosuggest',
          label: 'Išskyrus',
          options: this.projectOptions,
          value: pipe(
            getValue,
            queryProjects,
          )('id.$nin', []),
          required: true,
          setValue: this.setFieldIds,
        },
      }
    },
    projectOptions() {
      return this
        .query('project')
        .map(({ name, id }) => ({
          label: name,
          value: id,
        }))
    },
    ...getters(
      'projectStepBranchOptions',
    ),
  },
  methods: {
    setValue(value) {
      this.$emit('update:value', cleanQuery(value))
    },
    setArrayValue(fieldKey, value) {
      const valueUpdated = setQueryArray(fieldKey, value, this.value)

      this.setValue(valueUpdated)
    },
    setFieldIds(fieldKey, records) {
      const ids = pluck('id', records)

      this.setArrayValue(fieldKey, ids)
    },
  },
}

const setQueryArray = (key, value, array) => (isEmpty(value)
  ? dissocPath(key)
  : assocPath(key, value))(array)
</script>

<style lang="scss">
.ProjectQuery {
}
</style>
