<template>
  <CardTable class="scale" :title="promo.subject">
    <Row>
      <Column :lg="3" :sm="6">
        <Set icon="tag" name="Kodų grupė">
          {{ promo.prefix }}
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set icon="bolt" name="Projektas">
          {{ promo.projectId ? project.name : 'Visi' }}
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set icon="user" name="Panaudota">
          {{ promo.uses }} / {{ promo.quota }}
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set icon="clock" name="Galioja iki">
          {{ promo.expiresAt | dateTime }}
        </Set>
      </Column>
    </Row>
    <Row>
      <Column :lg="3" :sm="6">
        <Set v-if="promo.yearsFreeMaintenance" icon="bill" name="Metai be priežiūros mokesčio">
          {{ promo.yearsFreeMaintenance || 'Standartinis' }}
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set icon="bill" name="Priežiūros mokestis">
          {{ maintenancePerKw }}
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set icon="asterisk" name="Promo kodo tipas">
          {{ promo.isShared ? 'Bendras kodas' : 'Unikalūs kodai' }}
        </Set>
      </Column>
    </Row>
    <div slot="meta">
      {{ promo.createdAt | dateTime }}
    </div>
    <div slot="actions">
      <Btn
        v-if="!promo.uses && hasRole('>=ADMIN')"
        class="danger ib sm inv no-border"
        @click="PROMPT({ text: 'Ar tikrai norite ištrinti šią promo grupę?', confirm: remove })">
        Ištrinti
      </Btn>
      <Btn
        v-if="hasRole('>MANAGER')"
        class="success ib sm inv no-border"
        :isLoading="isTogglingShared"
        @click="toggleIsShared">
        {{ promo.isShared ? 'Reikalauti unikalaus kodo' : 'Leisti naudoti bendrą kodą' }}
      </Btn>
      <Route
        v-if="!promo.isShared"
        :id="promo.id"
        class="Btn info ib sm"
        to="AdminPromo">
        Peržiūrėti <span v-if="isDesktop">individualius</span> kodus
      </Route>
    </div>
  </CardTable>
</template>

<script>
import { actions, getters } from '../../utils'
import CardTable from './CardTable'
import Set from '../Set'

export default {
  components: { CardTable, Set },
  props: {
    promo: Object,
  },
  data() {
    return {
      isTogglingShared: false,
    }
  },
  computed: {
    maintenancePerKw() {
      if (this.promo.maintenancePerKw === null) return 'Standartinis'

      return this.$options.filters.currency(this.promo.maintenancePerKw)
    },
    project() {
      return this.query('project', this.promo.projectId)
    },
    ...getters('isDesktop'),
  },
  methods: {
    async toggleIsShared() {
      try {
        this.isTogglingShared = true
        await this.PATCH_PROMO({
          id: this.promo.id,
          isShared: !this.promo.isShared,
        })
        this.SUCCESS('Promo kodas atnaujintas')
      } finally {
        this.isTogglingShared = false
      }
    },
    remove() {
      this.DELETE_PROMO(this.promo)
    },
    ...actions(
      'DELETE_PROMO',
      'PATCH_PROMO',
      'PROMPT',
    ),
  },
}
</script>

<style lang="scss">
</style>
