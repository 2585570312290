<template>
  <BoardView id="AdminEsoSnapshot" title="ESO dokumentas">
    <CardFancy data-cy="esoSnapshot">
      <ProgressBar
        v-if="isLoaded"
        :steps="progressionSteps">
        <div slot="step-1">
          <strong>
            {{ project.name }}
          </strong>
          <strong v-if="user">
            : {{ user | name }}
          </strong>
        </div>
        <div slot="step-2">
          <Btn
            class="ib sm info"
            data-cy="download"
            icon="arrow-down"
            :isLoading="isDownloading"
            @click="download">
            Atsisiųsti
            <template v-if="fileIds.length !== 1">
              ({{ fileIds.length }} failai)
            </template>
          </Btn>
        </div>
        <div v-if="hasDownloaded" slot="step-3">
          <Btn
            class="ib sm success"
            data-cy="markAsSent"
            :isLoading="isMarking"
            @click="markAsSent">
            Pateikiau dokumentą į ESO sistemą
          </Btn>
        </div>
      </ProgressBar>
    </CardFancy>
  </BoardView>
</template>

<script>
import { actions, getters } from 'views/utils'
import BoardView from 'components/board/BoardView'
import CardFancy from './components/CardFancy'
import ProgressBar from 'components/admin/ProgressBar'

export default {
  components: {
    BoardView,
    CardFancy,
    ProgressBar,
  },
  data() {
    return {
      hasDownloaded: false,
      hasSubmitted: false,
      isDownloading: false,
      isMarking: false,
    }
  },
  computed: {
    esoSnapshot() {
      return this.query('esoSnapshot', this.esoSnapshotId)
    },
    esoSnapshotId() {
      return Number(this.$route.params.id)
    },
    fileIds() {
      return this.esoSnapshot.fileIds || [this.esoSnapshot.fileId]
    },
    project() {
      return this.query('project', this.esoSnapshot.projectId)
    },
    progressionSteps() {
      return [
        {
          name: 'Sukurti dokumentą',
          theme: 'success',
        },
        {
          name: 'Atsisiųsti',
          theme: this.hasDownloaded
            ? 'success'
            : null,
        },
        {
          name: 'Pateikti ESO',
          theme: this.hasSubmitted
            ? 'success'
            : null,
        },
      ]
    },
    user() {
      if (!this.esoSnapshot.userId) return null

      return this.query('user', this.esoSnapshot.userId)
    },
    ...getters(),
  },
  methods: {
    async download() {
      await this.$wrap(
        this.DOWNLOAD_ALL(this.esoSnapshot),
        'isDownloading',
      )

      this.hasDownloaded = true
    },
    async markAsSent() {
      await this.$wrap(
        this.PATCH_ESO_SNAPSHOT({
          id: this.esoSnapshot.id,
          sentAt: new Date(),
        }),
        'isMarking',
        'ESO dokumentas pažymėtas kaip pateiktas',
      )

      this.hasSubmitted = true
    },
    ...actions(
      'DOWNLOAD_ALL',
      'FETCH_ESO_SNAPSHOT',
      'FETCH_PROJECT',
      'FETCH_USER',
      'PATCH_ESO_SNAPSHOT',
    ),
  },
  created() {
    this.LOAD(async () => {
      await this.FETCH_ESO_SNAPSHOT(this.esoSnapshotId)

      await Promise.all([
        this.FETCH_PROJECT(this.esoSnapshot.projectId),
        this.esoSnapshot.userId
          ? [this.FETCH_USER(this.esoSnapshot.userId)]
          : [],
      ])
    })
  },
}
</script>

<style lang="scss">
#AdminEsoSnapshot {
  .ProgressBarStep {
    width: 33%;
  }
}
</style>
