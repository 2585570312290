<script>
export default {
  props: {
    disabled: { type: Boolean, default: false },
    label: String,
    labelClass: String,
    name: String,
    required: { type: Boolean, default: false },
    rules: [String, Object],
    value: { type: null },
  },
  computed: {
    isRequired() {
      return this.required !== false
    },
    rulesDerived() {
      // TODO: add minlength, maxlength, etc
      const rulesDerived = [
        ['required', this.isRequired],
      ].filter(([_, condition]) => condition)
        .map(([ruleName]) => ruleName)

      // TODO: support this.rules in Object format
      return [
        this.rules,
        rulesDerived.join('|'),
      ].filter(Boolean).join('|')
    },
  },
}
</script>
