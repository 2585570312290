<template>
  <div class="EquipmentSelect">
    <MultiSelect
      v-bind="$attrs"
      :customLabel="getLabel"
      :internalSearch="true"
      :multiple="true"
      :options="options"
      trackBy="id"
      :value.sync="selected"
      @update:value="onSelectedChange($event)" />

    <div v-for="(_, id) in counts" :key="id">
      <Field
        :label="getCounterLabelById(id)"
        :max="10000"
        :min="1"
        type="number"
        :value.sync="counts[id]"
        @update:value="onCountChange(id, $event)" />
    </div>

    <!-- selected={{ selected }}<br><br>
    value={{ value }}<br>
    counts={{ counts }} -->
  </div>
</template>

<script>
import { actions, getters } from '@/views/utils'
import MultiSelect from 'components/fields/MultiSelect'

export default {
  components: {
    MultiSelect,
  },
  props: {
    options: Array,
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: [],
      counts: {},
    }
  },
  computed: {
    ...getters(),
  },
  methods: {
    getCounterLabelById(itemId) {
      const item = this.selected.find(item => item.id === Number(itemId))
      if (!item) return

      return `${this.getLabel(item)} kiekis:`
    },
    getLabel({ manufacturer, model, capacity }) {
      return `${manufacturer} ${model} (${capacity} kW)`
    },
    onSelectedChange(change) {
      this.counts = Object.fromEntries(this.selected.map(item => ([
        item.id,
        this.counts[item.id] || 0,
      ])))

      this.value = change.map(item => ({
        id: item.id,
        count: this.counts[item.id] || 0,
      }))
    },
    onCountChange() {
      this.value = this.selected.map(item => ({
        id: item.id,
        count: this.counts[item.id] || 0,
      }))
    },
    ...actions(),
  },
  watch: {
    value() {
      this.$emit('update:value', this.value)
    },
  },
  mounted() {
    this.selected = this.value.map(item => this.options
      .find(option => option.id === item.id)) || []

    this.counts = Object.fromEntries(this.value.map(item => ([
      item.id,
      item.count,
    ])))
  },
}
</script>

<style lang="scss">
.EquipmentSelect {
  > .tabs {
    margin-bottom: $grid-unit;
  }
}
</style>
