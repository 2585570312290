<template>
  <BoardView title="Redaguoti proceso etapą">
    <FormValidation v-if="step" @submit="submit">
      <FormStep :step="step" />
      <Btn type="submit">
        Išsaugoti
      </Btn>
    </FormValidation>
  </BoardView>
</template>

<script>
import { actions, getters } from 'views/utils'
import Step from './mixins/Step'
import { pluckId } from 'views/utils/collections'

export default {
  mixins: [Step],
  computed: getters(),
  methods: {
    submit() {
      return this.$wrap(async () => {
        const step = await this.presubmit(this.step)

        await this.PATCH_STEP(step)

        this.$router.push({
          name: 'AdminSteps',
        })
      }, 'isLoading')
    },
    ...actions(
      'FETCH_DOC_TEMPLATE',
      'FETCH_FILE',
      'FETCH_STEP',
      'FETCH_STEP_CONTRACT',
      'FETCH_STEP_MESSAGE',
      'FETCH_STEP_PAYMENT',
      'PATCH_STEP',
    ),
  },
  created() {
    this.LOAD(async () => {
      await Promise.all([
        this.FETCH_STEP(Number(this.$route.params.id)),
        this.FETCH_STEP_CONTRACT(),
        this.FETCH_STEP_PAYMENT(),
        this.FETCH_STEP_MESSAGE(),
      ])

      const documentTemplateIds = pluckId(
        'docTemplateId',
        [
          ...this.query('stepContract'),
          ...this.query('stepPayment'),
        ],
      )
      await this.FETCH_DOC_TEMPLATE(documentTemplateIds)

      const fileIds = pluckId('fileId', this.query('docTemplate'))
      await this.FETCH_FILE(fileIds)
    })

    this.POST_LOAD(() => {
      this.step = {
        ...this.query('step', this.$route.params.id),
      }
    })
  },
}
</script>
