<template>
  <div class="ReservationMessages">
    <Loader v-if="isFetching" />
    <div v-else>
      <RowGrid
        v-for="message in messages"
        :key="message.id"
        class="message"
        :columns="['2rem', '12rem', 1]">
        <Icon :name="message.isSeen ? 'eye' : 'eye-slash'" />
        <div>
          {{ message.createdAt | dateTime }}
        </div>
        <div class="subject">
          {{ message.subject }}
        </div>
      </RowGrid>
    </div>
  </div>
</template>

<script>
import { actions, getters } from 'views/utils'
import { pipe, prop, sortBy } from 'rambda'

export default {
  props: {
    reservation: { type: Object, required: true },
  },
  data() {
    return {
      isFetching: false,
    }
  },
  computed: {
    messages() {
      const query = {
        reservationId: this.reservation.id,
      }

      return pipe(
        query => this.query('message', query),
        sortBy(prop('id')),
      )(query)
    },
    ...getters(),
  },
  methods: actions('FETCH_MESSAGE'),
  async mounted() {
    await this.$wrap(
      () => this.FETCH_MESSAGE({
        reservationId: this.reservation.id,
      }),
      'isFetching',
    )
  },
}
</script>

<style lang="scss">
.ReservationMessages {
  .message {
    padding: $grid-unit-half 0;

    > .subject {
      font-weight: $semibold;
    }
  }
}
</style>
