<template>
  <div
    class="SidebarBurger"
    @click.self="showBurger(false)"
    @keypress.enter.self="showBurger(false)">
    <MainLogo
      format="svg"
      type="small" />

    <div class="menu-toggle">
      <Icon
        :name="isToggled ? 'cross' : 'menu'"
        @click="showBurger(!isToggled)" />
    </div>

    <transition name="slide-down">
      <div
        v-if="isToggled"
        class="menu-content"
        @click.self="showBurger(false)"
        @keypress.enter.self="showBurger(false)">
        <nav>
          <template v-for="(link, index) in links">
            <MenuLinkSidebar
              :key="index"
              :index="index"
              :link="link"
              @click="$emit('update:activeIndex', index);showBurger(link.children && link.children.length > 0)" />
            <transition-group :key="`${index}_transition`" appear name="slide-down">
              <div
                v-if="activeIndex === index && link.children && link.children.length"
                :key="`${index}_children`"
                class="children">
                <MenuLinkSidebar
                  v-for="(child, childIndex) in link.children"
                  :key="`${index}_${childIndex}`"
                  :index="childIndex"
                  :link="child"
                  @click="$emit('update:activeIndex', index);showBurger(false)" />
              </div>
            </transition-group>
          </template>

          <MenuLinkSidebar :link="{ label: 'Atsijungti', to: 'Logout', icon: 'lock' }" />
        </nav>
      </div>
    </transition>
  </div>
</template>

<script>
// import AvatarProfile from 'components/AvatarProfile'
import MainLogo from './TopBar/MainLogo'
import MenuLinkSidebar from './MenuLinkSidebar'
import { getters } from '../utils'

export default {
  components: {
    // AvatarProfile,
    MainLogo,
    MenuLinkSidebar,
  },
  props: {
    links: { type: Array, default: () => [] },
    activeIndex: { type: Number, required: false },
  },
  data() {
    return {
      isToggled: false,
    }
  },
  computed: getters(
    'authUser',
    'primaryUserProfile',
    'hasRole',
  ),
  methods: {
    showBurger(value) {
      this.isToggled = value
    },
  },
}
</script>

<style lang="scss">
$_menu-height: $sidebar-burger-height;

.SidebarBurger {
  align-items: center;
  border-bottom: 1px solid $border-color;
  display: flex;
  height: $_menu-height;
  justify-content: space-between;
  left: 0;
  line-height: 2rem;
  padding: 1rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;

  .MainLogo {
    padding: 0.5rem 0 0;
  }

  .menu-toggle {
    background-color: $white-bis;
    border: 1px solid $border-color-dark;
    border-color: $main;
    border-radius: $radius-md;
    color: $main;
    cursor: pointer;
    font-size: 1.5rem;
    height: 2rem;
    line-height: 1.25;
    opacity: 0.85;
    text-align: center;
    width: 2rem;
  }

  .menu-content {
    @include customScroll;
    height: calc(100% - #{$_menu-height});
    left: 0;
    overflow-y: scroll;
    position: fixed;
    top: $_menu-height;
    width: 100%;

    .user-info {
      align-items: center;
      display: flex;
      padding: 0.5rem 1rem;

      .AvatarProfile {
        margin-right: 0.5rem;
      }
    }

    nav {
      box-shadow: $shadow-lg;

      .MenuLinkSidebar {
        padding: 0;
        position: relative;
        width: 100%;

        a {
          align-items: center;
          border-bottom: 0;
          color: $text-color-lighter;
          display: flex;
          font-weight: $semibold;
          height: 4rem;
          line-height: $lh * 1.125;
          padding: 1rem 1rem 1rem 1.5rem;
          position: relative;
          text-align: left;
          white-space: nowrap;
          width: 100%;

          &::after {
            @include trans;
            background: transparent;
            bottom: 0;
            content: ' ';
            display: block;
            height: 1px;
            position: absolute;
            right: 0;
            width: 1px;
          }

          .Icon {
            margin-right: 0.5rem;
          }

          &.router-link-active {
            color: $white;
            opacity: 1;

            &::after {
              background: $white;
              border-radius: $radius 0 0 $radius;
              height: 100%;
              left: auto;
              top: 0;
              width: 4px;
            }
          }
        }
      }
    }
  }
}
</style>
