<template>
  <CardTable class="CardSnippet">
    <Row>
      <Column :lg="6">
        <Set icon="mail" name="Žyma">
          {{ snippet.key }}
        </Set>
      </Column>
      <Column :lg="6">
        <Set icon="asterisk" name="Reikšmė">
          {{ snippet.value }}
        </Set>
      </Column>
    </Row>
    <div slot="actions">
      <Route
        :id="snippet.id"
        class="Btn ib sm info"
        to="AdminSnippet">
        Redaguoti
      </Route>
    </div>
  </CardTable>
</template>

<script>
import CardTable from './CardTable'
import Set from '../Set'
import { getters } from '../../utils'

export default {
  components: { CardTable, Set },
  props: {
    snippet: Object,
  },
  computed: {
    ...getters(),
  },
}
</script>

<style lang="scss">
</style>
