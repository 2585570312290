<template>
  <BoardView hasBoardTable :pagination="pagination" title="Pranešimai" :total="total">
    <BoardTable :hasResults="!!total" :isFetching="isFetching" :pagination="pagination">
      <CardMessage
        v-for="message in results"
        :key="message.id"
        :message="message" />
    </BoardTable>
    <router-view />
  </BoardView>
</template>

<script>
import CardMessage from './components/admin/CardMessage'
import Table from './mixins/Table'
import { actions } from 'views/utils'
import { pluckIds } from 'views/utils/collections'

export default {
  mixins: [Table],
  components: { CardMessage },
  data() {
    return {
      paginationOptions: {
        name: 'message',
        service: 'message',
        searchIn: ['subject'],
        filters: [
          {
            label: 'Žinutės tema',
            append: 'subject',
          },
          {
            label: 'Kliento ID',
            type: 'number',
            append: 'userId',
          },
          {
            label: 'Data, nuo',
            type: 'date',
            append: ['createdAt', '$gte'],
          },
          {
            label: 'Data, iki',
            type: 'date',
            append: ['createdAt', '$lte'],
          },
        ],
      },
    }
  },
  methods: actions(
    'FETCH_RESERVATION',
    'FETCH_PROJECT',
    'FETCH_USER',
  ),
  watch: {
    async results(messages) {
      if (!messages.length) return

      const [authorUserIds, projectIds, reservationIds, userIds] = pluckIds([
        'authorUserId',
        'projectId',
        'reservationId',
        'userId',
      ], messages)

      await this.LOAD(() => Promise.all([
        this.FETCH_PROJECT(projectIds),
        this.FETCH_RESERVATION(reservationIds),
        this.FETCH_USER([...authorUserIds, ...userIds]),
      ]))
    },
  },
}
</script>
