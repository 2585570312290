<template>
  <div class="FormStepPayment">
    <div class="nameType">
      <Field
        label="Mokėjimo pavadinimas"
        required
        :value="stepPayment.name"
        @update:value="$up('stepPayment.name', $event)" />
      <Field
        disabled
        label="Mokėjimo tipas"
        name="payment-type"
        :options="paymentTypeOptions"
        required
        type="select"
        :value="stepPayment.type"
        @update:value="$up('stepPayment.type', $event)" />
    </div>
    <Row>
      <Column :lg="3" :md="6">
        <Field
          label="Mokėjimo terminas, kalendorinės dienos"
          required
          type="number"
          :value="expiresInDays"
          @update:value="setExpiresInIn" />
      </Column>
      <Column :lg="3" :md="6">
        <Field
          label="Mokėjimo suma, €"
          type="number"
          :value="stepPayment.amountFixed"
          @update:value="$up('stepPayment.amountFixed', $event)" />
      </Column>
      <Column :lg="3" :md="6">
        <Field
          label="Mokėjimo suma, % nuo pirkimo kainos"
          type="number"
          :value="stepPayment.amountPercent"
          @update:value="$up('stepPayment.amountPercent', $event)" />
      </Column>
      <Column :lg="3" :md="6">
        <Field
          label="Mokėjimo suma, % nuo palaikymo metinės kainos"
          type="number"
          :value="stepPayment.amountMaintenancePercent"
          @update:value="$up('stepPayment.amountMaintenancePercent', $event)" />
      </Column>
    </Row>
    <FileDropTemplate
      :docTemplateId="stepPayment.docTemplateId"
      @update:docTemplateId="$up('stepPayment.docTemplateId', $event)" />
  </div>
</template>

<script>
import FileDropTemplate from 'components/admin/FileDropTemplate'
import { getters } from '@/views/utils'

const DAY_IN_SECONDS = 24 * 60 * 60

export default {
  components: { FileDropTemplate },
  props: {
    stepPayment: Object,
  },
  computed: {
    expiresInDays() {
      if (!this.stepPayment.expiresIn) return null

      return this.stepPayment.expiresIn / DAY_IN_SECONDS
    },
    ...getters('paymentTypeOptions'),
  },
  methods: {
    setExpiresInIn(days) {
      this.stepPayment.expiresIn = days
        ? days * DAY_IN_SECONDS
        : null
    },
  },
}
</script>

<style lang="scss">
.FormStepPayment {
  .nameType {
    display: grid;
    gap: $grid-unit;
    grid-template-columns: 1fr 1fr;
  }
}
</style>
