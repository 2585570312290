<template functional>
  <div v-bind="data.attrs" :class="['BarSet', data.class, data.staticClass]" :style="data.style">
    <SetIcon
      v-if="props.icon"
      :class="$options.passedClasses(data.staticClass)"
      :icon="props.icon" />
    <div class="body">
      <div class="label">
        {{ props.label }}
      </div>
      <div class="value">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import SetIcon from './SetIcon'
import Vue from 'vue'

const sizeClasses = [
  'xs',
  'sm',
  'md',
  'lg',
  'xl',
  'xxl',
  'xxxl',
]

const themeClasses = [
  'danger',
  'fancy',
  'info',
  'main',
  'neutral',
  'secondary',
  'success',
  'warning',
]

const passedClasses = [...sizeClasses, ...themeClasses]

Vue.component('SetIcon', SetIcon)

export default {
  props: {
    icon: String,
    label: String,
    size: String,
    theme: String,
  },
  passedClasses(staticClass) {
    if (!staticClass) return

    return passedClasses
      .filter(passedClass => staticClass.includes(passedClass))
      .join(' ')
  },
}
</script>

<style lang="scss">
.BarSet {
  display: flex;

  > .body {
    overflow: hidden;
    width: 100%;

    > .label {
      color: $text-color;
      font-size: $h6;
      line-height: 1rem;
    }

    > .value {
      @include ellipsis;
      font-weight: $semibold;
    }
  }

  // size
  &.lg {
    > .body {
      > .label {
        font-size: $h5;
      }

      > .value {
        font-size: $h5;
      }
    }
  }

  &.xl {
    > .body {
      .SetIcon {
        margin-right: 1rem;
      }

      > .label {
        font-size: $h5;
      }

      > .value {
        font-size: $h4;
      }
    }
  }
}
</style>
