import Decimal from 'decimal.js-light'
import { isNil } from 'rambda'

export default (years, inflationAdjustments) => Object
  .fromEntries(years
    .map((year) => {
      const adjustmentRow = inflationAdjustments[year]

      if (!adjustmentRow) {
        return [year, { date: null, percent: null }]
      }

      const { adjustment, date } = adjustmentRow

      const percent = isNil(adjustment)
        ? null
        : new Decimal(adjustment).minus(100).toString()

      return [year, { date: date || null, percent }]
    }))
