<template>
  <div>
    <Row>
      <Column :md="6">
        <Field
          label="Pavadinimas"
          required
          :value.sync="post.title" />
      </Column>
      <Column :md="2">
        <Field
          label="Tekstas prieš datą"
          :value.sync="post.prefix" />
      </Column>
      <Column :md="4">
        <DatePicker
          label="Naujienos data"
          :value.sync="post.dateAt" />
      </Column>
    </Row>
    <Field
      label="Tekstas"
      type="textarea"
      :value.sync="post.text" />
    <div class="form-project-post-icons">
      <div class="Field-label">
        Iliustracija
      </div>
      <Icon
        v-for="icon in icons"
        :key="icon"
        class="form-project-post-icon"
        :class="{ active: icon === post.icon }"
        :name="icon || 'newspaper'"
        @click="post.icon = icon" />
    </div>
  </div>
</template>

<script>
import DatePicker from 'components/fields/DatePicker'
import { getters } from 'views/utils'

export default {
  components: { DatePicker },
  props: {
    post: Object,
  },
  data() {
    return {
      icons: [
        null,
        'bolt',
        'cog',
        'euro',
        'eye',
        'hard-hat',
        'map-marker-info',
        'note',
        'tag',
        'screw',
        'star',
        'sun',
        'user',
      ],
    }
  },
  computed: getters(),
}
</script>

<style lang="scss">
.form-project-post-icons {
  margin-bottom: 2rem;
}

.form-project-post-icon {
  border-radius: $radius;
  cursor: pointer;
  display: inline-block;
  font-size: $h3;
  padding: 0.5rem;

  &.active {
    background: $main;
    color: $white;
  }
}
</style>
