<template>
  <BoardView title="Pridėti inverterį">
    <FormValidation @submit="create">
      <FormSolarInverter :solarInverter="solarInverter" />
      <Btn class="info" :isLoading="isSaving" type="submit">
        Sukurti inverterį
      </Btn>
    </FormValidation>
  </BoardView>
</template>

<script>
import { actions, getters } from './utils'
import BoardView from 'components/board/BoardView'
import FormSolarInverter from './components/forms/FormSolarInverter'

export default {
  components: { BoardView, FormSolarInverter },
  data() {
    return {
      isSaving: false,
      solarInverter: this.$store.getters.solarInverterFactory(),
    }
  },
  computed: getters(),
  methods: {
    async create() {
      try {
        this.isSaving = true
        await this.CREATE_SOLAR_INVERTER(this.solarInverter)
        this.$router.push({ name: 'AdminSolarInverters' })
      } finally {
        this.isSaving = false
      }
    },
    ...actions('CREATE_SOLAR_INVERTER'),
  },
}
</script>
