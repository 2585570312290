<template>
  <BoardView hasBoardTable :pagination="pagination" title="ESO dokumentai" :total="total">
    <Route slot="button" class="Btn success" to="AdminEsoSnapshotAdd">
      <Icon name="file" /> Generuoti
    </Route>
    <BoardTable :hasResults="!!total" :isFetching="isFetching" :pagination="pagination">
      <CardEsoSnapshot
        v-for="esoSnapshot in results"
        :key="esoSnapshot.id"
        :esoSnapshot="esoSnapshot" />
    </BoardTable>
    <router-view />
  </BoardView>
</template>

<script>
import CardEsoSnapshot from './components/admin/CardEsoSnapshot'
import Table from './mixins/Table'
import { actions } from './utils'
import { pluckIds } from 'views/utils/collections'

export default {
  mixins: [Table],
  components: { CardEsoSnapshot },
  data() {
    return {
      paginationOptions: {
        name: 'esoSnapshot',
        service: 'esoSnapshot',
        searchIn: [],
        filters: [
          {
            label: 'Projektas',
            type: 'select',
            options: () => this.$store.getters.projectOptions,
            append: 'projectId',
          },
        ],
      },
    }
  },
  methods: actions(
    'FETCH_PROJECT',
    'FETCH_USER',
  ),
  watch: {
    async results(esoSnapshots) {
      if (!esoSnapshots.length) return

      const [projectIds, userIds] = pluckIds([
        'projectId',
        'authorUserId',
      ], esoSnapshots)

      await this.LOAD(() => Promise.all([
        this.FETCH_PROJECT(projectIds),
        this.FETCH_USER(userIds),
      ]))
    },
  },
}
</script>
