<template>
  <BoardView sub="Puslapio nustatymai" title="Nustatymai">
    <Route slot="button" class="Btn danger inv" icon="lock" to="Logout">
      Atsijungti
    </Route>
    <Wrap class="no-pad">
      <Block>
        <FormSect
          sub="Kokie el. pašto adresai gaus laiškus iš kontaktų formos ir pranešimus apie naujus vartotojus, bei vėluojančius mokėjimus"
          title="Pranešimai">
          <CardContactEmail
            v-for="emailRecord in emails"
            :key="emailRecord.id"
            :contactEmail="emailRecord" />
          <Notice v-if="!emails.length" class="danger">
            Dabar niekas negauna užklausų iš kontaktų formos.
          </Notice>
          <FormValidation @submit="addEmail">
            <Field
              placeholder="vardas@puslapis.lt"
              required
              rules="email"
              type="email"
              :value.sync="email" />
            <Btn class="success" :isLoading="isSaving" type="submit">
              Pridėti el. pašto adresą
            </Btn>
          </FormValidation>
        </FormSect>
      </Block>
      <Block>
        <FormSect
          sub="APVA paramos etapai"
          title="APVA">
          <CardApvaStage
            v-for="apvaStage in apvaStages"
            :key="apvaStage.id"
            :apvaStage="apvaStage" />
          <Notice v-if="!apvaStages.length" class="info">
            Nėra nustatytų etapų
          </Notice>
          <FormValidation @submit="addApvaStage">
            <DatePicker
              label="Data"
              required
              :value.sync="apvaStageStartDate" />
            <Btn
              class="success"
              :disabled="!apvaStageStartDate"
              :isLoading="isSaving"
              type="submit">
              Pridėti APVA etapą
            </Btn>
          </FormValidation>
        </FormSect>
      </Block>
    </Wrap>
  </BoardView>
</template>

<script>
import { actions, getters } from './utils'
import BoardView from 'components/board/BoardView'
import CardApvaStage from './components/admin/CardApvaStage'
import CardContactEmail from './components/admin/CardContactEmail'
import DatePicker from 'components/fields/DatePicker'

export default {
  components: { BoardView, CardApvaStage, CardContactEmail, DatePicker },
  data() {
    return {
      email: null,
      apvaStageStartDate: null,
      isSaving: false,
    }
  },
  computed: {
    apvaStages() {
      return this.query('apvaStage')
        .sort((a, b) => b.startDate - a.startDate)
    },
    emails() {
      return this.query('contactEmail')
    },
    ...getters(),
  },
  methods: {
    async addApvaStage() {
      // TODO: rewrite in $wrap
      try {
        this.isSaving = true
        await this.CREATE_APVA_STAGE({
          startDate: this.apvaStageStartDate,
        })
        this.apvaStageStartDate = null
      } finally {
        this.isSaving = false
      }
    },
    async addEmail() {
      // TODO: rewrite in $wrap
      try {
        this.isSaving = true
        await this.CREATE_CONTACT_EMAIL({
          email: this.email,
        })
        this.email = null
      } finally {
        this.isSaving = false
      }
    },
    ...actions('CREATE_APVA_STAGE', 'CREATE_CONTACT_EMAIL'),
  },
  created() {
    this.user = { ...this.authUser }
    this.LOAD('FETCH_APVA_STAGE')
    this.LOAD('FETCH_CONTACT_EMAIL')
  },
}
</script>

<style lang="scss">
</style>
