<template>
  <div class="BulkMessages">
    <div class="selections">
      <Btn
        class="info ib"
        data-cy="selectAll"
        @click="selectAll">
        <Icon name="check" /> Pasirinkti visus puslapyje
      </Btn>
      <Btn
        v-if="numberOfSelectedItems"
        class="danger ib"
        data-cy="deselectAll"
        @click="deselectAll">
        <Icon name="cancel" /> Atžymėti visus ({{ numberOfSelectedItems }})
      </Btn>
    </div>
    <Btn
      class="success ib sendEmails"
      data-cy="sendEmails"
      :disabled="!numberOfSelectedItems"
      @click="sendEmail">
      <Icon name="mail" /> Siųsti pranešimą pažymėtiems
    </Btn>
  </div>
</template>

<script>
import { getUniqueArray } from '@/views/utils'

export default {
  props: {
    recordsSelectedIds: Array,
    recordsSelectedUserIds: Array,
    records: Array,
    keyUserId: { type: String, default: 'userId' },
  },
  computed: {
    numberOfSelectedItems() {
      return this.recordsSelectedIds.length
    },
  },
  methods: {
    async updateRouteQuery() {
      const recordIdsQuery = this.recordsSelectedIds.join(',')
      const userIdsQuery = this.recordsSelectedUserIds.join(',')

      const { recordIds, userIds } = this.$route.query

      if (recordIdsQuery !== recordIds || userIdsQuery !== userIds) {
        await this.$router
          .replace({
            path: this.$route.path,
            query: {
              recordIds: recordIdsQuery,
              userIds: userIdsQuery,
            },
          })
      }
    },
    async sendEmail() {
      const userIdsUnique = getUniqueArray(this.recordsSelectedUserIds).join(',')
      await this.updateRouteQuery()

      await this.$router.push({
        name: 'AdminMessageAddMultiple',
        query: { userIds: userIdsUnique },
      })
    },
    selectAll() {
      const recordsIds = this.records
        .map(({ id }) => id)

      const userIds = this.records
        .map(record => record[this.keyUserId])

      this.$emit('update:recordsSelectedIds', getUniqueArray([
        ...this.recordsSelectedIds,
        ...recordsIds]))

      this.$emit('update:recordsSelectedUserIds', getUniqueArray([
        ...this.recordsSelectedUserIds,
        ...userIds]))
    },
    deselectAll() {
      this.$emit('update:recordsSelectedIds', [])
      this.$emit('update:recordsSelectedUserIds', [])
    },
  },
}
</script>

<style lang="scss">
.BulkMessages {
  text-align: right;

  .selections {
    float: left;

    .Btn {
      margin: 0 1rem 1rem 0;
    }
  }

  .Btn.sendEmails {
    margin-bottom: 1rem;
    min-width: 15rem;
  }
}
</style>
