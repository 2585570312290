<template>
  <BoardView hasBoardTable :pagination="pagination" title="Veiksmų žurnalas" :total="total">
    <BoardTable :hasResults="!!total" :isFetching="isFetching" :pagination="pagination">
      <CardLog
        v-for="log in results"
        :key="log.id"
        :log="log" />
    </BoardTable>
    <router-view />
  </BoardView>
</template>

<script>
import CardLog from './components/admin/CardLog'
import Table from './mixins/Table'
import { actions } from 'views/utils'
import { pluckId } from 'views/utils/collections'

export default {
  mixins: [Table],
  components: { CardLog },
  data() {
    return {
      paginationOptions: {
        name: 'userLog',
        service: 'userLog',
        searchIn: [],
        filters: [
          {
            label: 'Resursas',
            append: ['entity', '$ilike'],
          },
          {
            label: 'Veiksmas',
            append: ['action', '$ilike'],
          },
          {
            label: 'Resurso ID',
            append: 'entityId',
          },
          {
            label: 'Vartotojo ID',
            append: 'userId',
          },
          {
            label: 'Data, nuo',
            type: 'date',
            append: ['createdAt', '$gte'],
          },
          {
            label: 'Data, iki',
            type: 'date',
            append: ['createdAt', '$lte'],
          },
        ],
      },
    }
  },
  methods: actions(
    'FETCH_USER',
  ),
  watch: {
    async results(logs) {
      if (!logs.length) return

      const userIds = pluckId('userId', logs)

      await this.LOAD(() => Promise.all([
        this.FETCH_USER(userIds),
      ]))
    },
  },
}
</script>

<style lang="scss">
</style>
