<template>
  <CardTable class="CardLog">
    <Row>
      <Column :xl="3" :xs="6">
        <Set icon="user" name="Veiksmą atliko">
          <template v-if="user">
            {{ user | name }}
          </template>
          ({{ log.userId }})
        </Set>
      </Column>
      <Column :xl="3" :xs="6">
        <Set icon="mail" name="Resursas.Veiksmas:ID">
          {{ log.entity }}.{{ log.action }}:{{ log.entityId }}
        </Set>
      </Column>
      <Column :xl="3" :xs="6">
        <Set icon="asterisk" name="IP adresas">
          {{ log.ip || '-' }}
        </Set>
      </Column>
      <Column :xl="3" :xs="6">
        <Set icon="clock" name="Laikas">
          {{ log.createdAt | dateTime }}
        </Set>
      </Column>
    </Row>
    <div v-if="isOpen" class="log-data">
      <pre>{{ log.data }}</pre>
    </div>
    <div slot="actions">
      <Btn
        class="ib sm inv"
        @click="isOpen = !isOpen">
        {{ isOpen ? 'Uždaryti' : 'Peržiūrėti' }}
      </Btn>
    </div>
  </CardTable>
</template>

<script>
import CardTable from './CardTable'
import Set from '../Set'
import { getters } from '../../utils'

export default {
  components: { CardTable, Set },
  props: {
    log: Object,
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    user() {
      return this.query('user', this.log.userId)
    },
    ...getters(),
  },
}
</script>

<style lang="scss">
.CardLog {
  .log-data {
    background: $grey-lightest;
  }
}
</style>
