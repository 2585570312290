<template>
  <BoardView title="Pridėti saulės modulį">
    <FormValidation @submit="create">
      <FormSolarModule :solarModule="solarModule" />
      <Btn class="info" :isLoading="isSaving" type="submit">
        Sukurti saulės modulį
      </Btn>
    </FormValidation>
  </BoardView>
</template>

<script>
import { actions, getters } from './utils'
import BoardView from 'components/board/BoardView'
import FormSolarModule from './components/forms/FormSolarModule'

export default {
  components: { BoardView, FormSolarModule },
  data() {
    return {
      isSaving: false,
      solarModule: this.$store.getters.solarModuleFactory(),
    }
  },
  computed: getters(),
  methods: {
    async create() {
      try {
        this.isSaving = true
        await this.CREATE_SOLAR_MODULE(this.solarModule)
        this.$router.push({ name: 'AdminSolarModules' })
      } finally {
        this.isSaving = false
      }
    },
    ...actions('CREATE_SOLAR_MODULE'),
  },
}
</script>
