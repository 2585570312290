<template>
  <BoardView :title="user.email">
    <FormValidation @submit="save">
      <FormUser type="admin" :user="user" />
      <Btn class="info" :isLoading="isSaving" type="submit">
        Išsaugoti
      </Btn>
    </FormValidation>
  </BoardView>
</template>

<script>
import { actions, getters } from './utils'
import BoardView from 'components/board/BoardView'
import FormUser from './components/forms/FormUser'

export default {
  components: { BoardView, FormUser },
  data() {
    return {
      isSaving: false,
      user: {},
    }
  },
  computed: getters(),
  methods: {
    async save() {
      try {
        this.isSaving = true
        await this.PATCH_USER(this.user)
        this.$router.push({ name: 'AdminUsers' })
      } finally {
        this.isSaving = false
      }
    },
    ...actions('PATCH_USER'),
  },
  created() {
    this.POST_LOAD(() => {
      this.user = { ...this.query('user', Number(this.$route.params.id)) }
    })
  },
}
</script>

<style lang="scss">
</style>
