<template>
  <div
    class="TableColumns"
    :class="{ isMobile: !screenSizes.includes(pagination.tableBreakpoint) }">
    <div
      v-for="({ label, style }, index) in pagination.dataColumns"
      :key="index"
      class="cell"
      :style="style">
      <h4 class="columnLabel">
        {{ label }}
      </h4>
    </div>
  </div>
</template>

<script>
import { getters } from 'views/utils'

export default {
  props: {
    pagination: Object,
  },
  computed: getters('screenSizes'),
}
</script>

<style lang="scss">
.TableColumns {
  align-items: center;
  background-color: $main-light;
  border: 1px solid $border-color-light;
  border-radius: $radius;
  display: flex;
  margin-bottom: -1.75rem;
  margin-top: 1rem;
  padding: 0.5rem 0;

  .cell {
    padding: 0.5rem;

    h4.columnLabel {
      color: $white;
      font-weight: $semibold;
      line-height: 1;
      margin-bottom: 0;
    }
  }

  &.isMobile {
    display: none;
  }
}
</style>
