<template>
  <CardTable class="CardSolarModule">
    <Row>
      <Column :lg="6">
        <Set icon="sun" name="Gamintojas">
          {{ solarModule.manufacturer }}
        </Set>
      </Column>
      <Column :lg="6">
        <Set icon="bolt" name="Modelis">
          {{ solarModule.model }}
        </Set>
      </Column>
    </Row>
    <div slot="actions">
      <Route
        :id="solarModule.id"
        class="Btn ib sm info"
        to="AdminSolarModule">
        Redaguoti
      </Route>
    </div>
  </CardTable>
</template>

<script>
import CardTable from './CardTable'
import Set from '../Set'
import { getters } from '../../utils'

export default {
  components: { CardTable, Set },
  props: {
    solarModule: Object,
  },
  // data() {
  //   return {
  //     isOpen: false,
  //   }
  // },
  computed: {
    ...getters(),
  },
}
</script>

<style lang="scss">
</style>
