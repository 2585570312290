<template>
  <StepField class="StepDelay" :label="serialized">
    <Field
      label="Po"
      :min="1"
      :step="1"
      type="number"
      :value="delayReadable"
      @update:value="setValue" />
    <Field
      label="_"
      :options="[['d', 'dienų'], ['h', 'valandų'], ['m', 'minučių']]"
      type="select"
      :value.sync="delayTimeframe"
      @update:value="setValue(delayReadable)" />
    <Field
      v-if="hasLimitType"
      label="Kartojimosi apribojimas"
      :options="limitTypeOptions"
      type="select"
      :value="limitType"
      @update:value="$emit('update:limitType', $event)" />
  </StepField>
</template>

<script>
import StepField from './StepField'

export default {
  components: { StepField },
  props: {
    hasLimitType: Boolean,
    limitType: String,
    value: Number,
  },
  data() {
    return {
      delayTimeframe: 'd',
      limitTypeOptions: [
        {
          label: 'Nėra apribojimo',
          value: null,
        },
        {
          label: 'Apmokama priežiūros trukmė',
          value: 'MAINTENANCE_PAID',
        },
      ],
    }
  },
  computed: {
    serialized() {
      if (!this.delayReadable) {
        return 'Iškart'
      }

      return `Po ${this.delayReadable} ${this.delayTimeframe}`
    },
    timeframe() {
      return timeframes[this.delayTimeframe]
    },
    delayReadable() {
      if (!this.value) return null

      return this.value / this.timeframe
    },
  },
  methods: {
    setValue(amount) {
      this.$emit('update:value', amount
        ? amount * this.timeframe
        : null)
    },
  },
  mounted() {
    if (!this.value) return

    this.delayTimeframe = (Object
      .entries(timeframes)
      .find(([_, value]) => value <= this.value) || [])[0] || 'd'
  },
}

const MINUTE_IN_SECONDS = 60
const HOUR_IN_SECONDS = 60 * MINUTE_IN_SECONDS
const DAY_IN_SECONDS = 24 * HOUR_IN_SECONDS

const timeframes = {
  d: DAY_IN_SECONDS,
  h: HOUR_IN_SECONDS,
  m: MINUTE_IN_SECONDS,
  s: 1,
}
</script>

<style lang="scss">
.StepDelay {
}
</style>
