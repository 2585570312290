<template>
  <div class="FormProjectDocument">
    <Row>
      <Column :xs="6">
        <Field
          label="Dokumento pavadinimas"
          :value.sync="projectDocument.title" />
      </Column>
      <Column :xs="6">
        <FieldLabel label="Failas" />
        <FileDrop
          v-if="!projectDocument.fileId"
          :id="`document-${id}`"
          :data="{ type: 'PROJECT_DOCUMENT' }"
          message="Įkelti dokumentą"
          :options="{ acceptedFiles }"
          @success="setFile" />
        <Row v-else-if="file">
          <Column :xs="8">
            <div class="fileName">
              {{ file.name }}
            </div>
          </Column>
          <Column :xs="4">
            <Btn class="danger inv" @click="$emit('remove')">
              <Icon name="cross" /> Pašalinti
            </Btn>
          </Column>
        </Row>
      </Column>
    </Row>
  </div>
</template>

<script>
import { actions, getters, randomElementId } from 'views/utils'
import FileDrop from 'components/FileDrop'

export default {
  components: {
    FileDrop,
  },
  props: {
    projectDocument: Object,
  },
  data() {
    return {
      acceptedFiles: [
        'csv',
        'doc',
        'docx',
        'jpeg',
        'jpg',
        'pdf',
        'png',
        'tsv',
        'xls',
        'xlsx',
      ].map(type => `.${type}`).join(', '),
      id: randomElementId(),
    }
  },
  computed: {
    file() {
      return this.query('file', this.projectDocument.fileId)
    },
    ...getters(),
  },
  methods: {
    setFile(upload) {
      this.projectDocument.fileId = upload.file.id
      this.FETCH_FILE(upload.file.id)
      this.$emit('upload')
    },
    ...actions('FETCH_FILE'),
  },
}
</script>

<style lang="scss">
.FormProjectDocument {
  margin-top: 1rem;

  .FileDrop .Dropzone {
    .dz-message {
      max-height: 2.5rem;
      min-height: 2.5rem;
    }
  }

  .fileName {
    @include ellipsis;
    font-weight: $semibold;
    line-height: $lh * 2;
  }
}
</style>
