<template>
  <div class="FormStepContract">
    <div class="nameType">
      <Field
        label="Sutarties pavadinimas"
        required
        :value="stepContract.name"
        @update:value="$up('stepContract.name', $event)" />
      <Field
        disabled
        label="Sutarties tipas"
        :options="contractTypesOptions"
        required
        type="select"
        :value="stepContract.type"
        @update:value="$up('stepContract.type', $event)" />
      <Field
        v-if="isAmendment"
        label="Priedas"
        :options="amendmentTypesOptions"
        required
        type="select"
        :value="stepContract.amendmentType"
        @update:value="$up('stepContract.amendmentType', $event)" />
    </div>
    <FileDropTemplate
      :docTemplateId="stepContract.docTemplateId"
      @update:docTemplateId="$up('stepContract.docTemplateId', $event)" />
  </div>
</template>

<script>
import FileDropTemplate from 'components/admin/FileDropTemplate'
import { getters } from '@/views/utils'

export default {
  components: { FileDropTemplate },
  props: {
    isAmendment: Boolean,
    stepContract: Object,
  },
  computed: getters(
    'amendmentTypesOptions',
    'contractTypesOptions',
  ),
}
</script>

<style lang="scss">
.FormStepContract {
  .nameType {
    display: grid;
    gap: $grid-unit;
    grid-template-columns: 1fr 1fr;
  }
}
</style>
