export default (payments, userSteps, years) => Object
  .fromEntries(years
    .map(year => [
      year,
      (() => {
        if (!userSteps) return nilDate

        const payment = payments
          .find(({ createdAt }) => createdAt.getUTCFullYear() === year)

        if (payment) {
          return {
            date: toISODate(payment.createdAt),
            isCreated: true,
            isSoon: false,
          }
        }

        const userStep = userSteps
          .find(({ executesAt }) => executesAt.getUTCFullYear() === year)

        if (userStep) {
          return {
            date: toISODate(userStep.executesAt),
            isCreated: false,
            isSoon: isSoon(userStep.executesAt),
          }
        }

        return nilDate
      })(),
    ]))

const toISODate = date => date.toISOString().slice(0, 10)

const MONTH_MS = Math.round(30.416 * 24 * 60 * 60 * 1000)
const soonHorizon = Date.now() + MONTH_MS
const isSoon = date => date.getTime() < soonHorizon

const nilDate = Object.freeze({
  date: null,
  isCreated: false,
  isSoon: false,
})
