<template>
  <div class="TableFilters">
    <Row v-for="(filters, index) in filtersRows" :key="index">
      <Column
        v-for="filter in filters"
        :key="filter.label"
        :lg="3"
        :md="4"
        :sm="6">
        <QueryFilter :filter="filter" :pagination="pagination" />
      </Column>
    </Row>
  </div>
</template>

<script>
import QueryFilter from './QueryFilter'
import { getters } from 'views/utils'

export default {
  components: {
    QueryFilter,
  },
  props: {
    pagination: Object,
  },
  data() {
    return {
      filtersValues: [],
    }
  },
  computed: {
    filtersRows() {
      return this.splitIntoRows(this.pagination.filters, {
        lg: 4,
        md: 3,
        sm: 2,
        xs: 1,
      })
    },
    ...getters('splitIntoRows'),
  },
}
</script>
