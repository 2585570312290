<template>
  <div class="File">
    <div class="icon" :class="extension.theme">
      <Icon :name="extension.icon" />
      <span class="ext">
        {{ file.extension }}
      </span>
    </div>
    <div class="name">
      <a
        @click.prevent="download"
        @keypress.enter.prevent="download">
        {{ file.name }}
      </a>
    </div>
    <div class="date">
      <div class="status">
        {{ file.size | metricNumber }} | {{ file.createdAt | date }}
      </div>
    </div>
    <div class="actions single">
      <Btn class="danger xs inv" icon="delete" @click="remove">
        <template v-if="isDesktop">
          Ištrinti
        </template>
      </Btn>
    </div>
  </div>
</template>

<script>
import { actions, getters } from '../utils'

export default {
  props: {
    file: Object,
  },
  computed: {
    extension() {
      return this.getFileExtension(this.file)
    },
    url() {
      return `${this.file.host}/${this.file.url}`
    },
    ...getters('getFileExtension', 'isDesktop'),
  },
  methods: {
    download() {
      this.DOWNLOAD_FILE(this.file)
    },
    async remove() {
      this.$emit('remove', await this.DELETE_FILE(this.file))
    },
    ...actions('DELETE_FILE', 'DOWNLOAD_FILE'),
  },
}
</script>

<style lang="scss">
// $_status-icon-size: 21px;

.File {
  padding: 1rem 3.5rem;
  position: relative;
  @include md {
    padding-right: 6rem;
  }

  > .icon {
    color: $neutral;
    font-size: $h2;
    left: 0.5rem;
    position: absolute;
    top: 1rem;

    > .ext {
      background: $info;
      border-radius: $radius;
      bottom: 0.5rem;
      color: $white;
      font-size: $h7;
      font-weight: $medium;
      padding: 0 0.125rem;
      position: absolute;
      right: 0;
    }
  }

  > .name {
    @include ellipsis;
    display: block;

    > a {
      @include trans;
      color: $text-color-mid;
      cursor: pointer;
      font-weight: $semibold;

      &:hover {
        color: $link-color-hover;
      }
    }
  }

  > .date {
    > .status {
      color: $text-color-lightish;
    }
  }

  > .actions {
    position: absolute;
    right: 1rem;
    top: 2rem;

    &.single {
      top: 1rem;
    }
  }
}
</style>
