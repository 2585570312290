<template>
  <BoardView title="Pridėti projektą">
    <FormValidation @submit="create">
      <FormProject :project="project" />
      <Btn class="info" :isLoading="isSaving" type="submit">
        Sukurti projektą
      </Btn>
    </FormValidation>
  </BoardView>
</template>

<script>
import { actions, getters } from './utils'
import BoardView from 'components/board/BoardView'
import FormProject from './components/forms/FormProject'

export default {
  components: { BoardView, FormProject },
  data() {
    return {
      isSaving: false,
      project: this.$store.getters.projectFactory(),
    }
  },
  computed: getters(),
  methods: {
    async create() {
      try {
        this.isSaving = true
        await this.CREATE_PROJECT(this.project)
        this.$router.push({ name: 'AdminProjects' })
      } finally {
        this.isSaving = false
      }
    },
    ...actions('CREATE_PROJECT'),
  },
}
</script>
