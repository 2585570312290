<template>
  <div>
    <FormSect>
      <Row>
        <Column :md="6">
          <Field
            label="Žyma"
            :value.sync="snippet.key" />
        </Column>
        <Column :md="6">
          <Field
            label="Reikšmė"
            :value.sync="snippet.value" />
        </Column>
      </Row>
    </FormSect>
  </div>
</template>

<script>
import { getters } from 'views/utils'

export default {
  props: {
    snippet: Object,
  },
  computed: {
    ...getters(),
  },
}
</script>

<style lang="scss">
</style>
