<template>
  <BoardView hasBoardTable :pagination="pagination" title="Laiškai" :total="total">
    <Btn slot="button" class="success icon-side-left" :isLoading="isExporting" @click="createSheetExport">
      <Icon name="file" /> Eksportuoti
    </Btn>
    <BoardTable :hasResults="!!total" :isFetching="isFetching" :pagination="pagination">
      <CardMail
        v-for="mail in results"
        :key="mail.id"
        :mail="mail" />
    </BoardTable>
    <router-view />
  </BoardView>
</template>

<script>
import CardMail from './components/admin/CardMail'
import SheetExport from './mixins/SheetExport'
import Table from './mixins/Table'
import { actions } from './utils'

export default {
  mixins: [SheetExport, Table],
  components: { CardMail },
  data() {
    return {
      paginationOptions: {
        name: 'mail',
        service: 'mail',
        searchIn: ['from', 'subject', 'to'],
        filters: [
          {
            label: 'Gavėjo el. paštas',
            append: ['to', '$ilike'],
          },
          {
            label: 'Išsiuntimo data, nuo',
            type: 'date',
            append: value => ({
              createdAt: {
                $gte: value,
              },
            }),
          },
          {
            label: 'Išsiuntimo data, iki',
            type: 'date',
            append: value => ({
              createdAt: {
                $lte: value,
              },
            }),
          },
        ],
      },
    }
  },
  methods: actions('FETCH_MAIL'),
  created() {
    this.LOAD(() => this.FETCH_MAIL({ $limit: 30 }))
  },
}
</script>

<style lang="scss">
</style>
