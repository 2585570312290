<template>
  <Bar
    class="BarCapacity"
    :hasLabels="true"
    :max="project.capacity"
    :parts="parts" />
</template>

<script>
import Bar from './Bar'

export default {
  components: { Bar },
  props: {
    project: Object,
  },
  computed: {
    parts() {
      const capacityAvailable = Number((
        this.project.capacity -
        this.project.capacityUsed
      ).toFixed(1))

      return [
        {
          label: 'Užsakymai',
          theme: 'danger',
          value: this.project.capacityReserved,
        },
        {
          label: 'Nerezervuojama',
          theme: 'warning',
          value: Number((this.project.capacityUnreservable || 0).toFixed(1)),
        },
        {
          label: 'Laisva',
          theme: 'success',
          value: capacityAvailable,
        },
      ].filter(part => part.value !== 0)
        .map(part => ({
          ...part,
          valueDisplayed: `${part.value} kW`,
        }))
    },
  },
}
</script>

<style lang="scss">
</style>
