<template>
  <CardTable class="scale" :title="message.subject">
    <Row>
      <Column :lg="3" :sm="6">
        <Set icon="bolt" name="Projektas">
          {{ reservation ? project.name : 'Nėra' }}
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set icon="user" name="Gavėjas">
          <template v-if="recipient">
            {{ recipient | name }}
          </template>
          <template v-else>
            Ištrintas vartotojas
          </template>
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set :icon="message.authorUserId ? 'user' : 'cog'" name="Siuntėjas">
          {{ message.authorUserId ? $options.filters.name(sender) : 'Sistema' }}
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set :icon="message.isSeen ? 'eye' : 'eye-slash'" name="Perskaitytas">
          {{ message.isSeen ? 'Taip' : 'Ne' }}
        </Set>
      </Column>
    </Row>
    <div slot="meta">
      {{ message.createdAt | dateTime }}
    </div>
    <div slot="actions">
      <!--
      <Btn
        class="ib sm inv no-border danger"
        @click="PROMPT({ text: 'Ar tikrai norite ištrinti šią žinutę?', confirm: remove })">
        Ištrinti
      </Btn>
      -->
    </div>
  </CardTable>
</template>

<script>
import { actions, getters } from 'views/utils'
import CardTable from './CardTable'
import Set from '../Set'

export default {
  components: { CardTable, Set },
  props: {
    message: Object,
  },
  computed: {
    project() {
      return this.query('project', this.reservation.projectId)
    },
    recipient() {
      return this.query('user', this.message.userId)
    },
    reservation() {
      if (!this.message.reservationId) return

      return this.query('reservation', this.message.reservationId)
    },
    sender() {
      return this.query('user', this.message.authorUserId)
    },
    ...getters(),
  },
  methods: {
    // remove() {
    //   this.DELETE_MESSAGE(this.message)
    // },
    ...actions('DELETE_MESSAGE', 'PROMPT'),
  },
}
</script>

<style lang="scss">
</style>
