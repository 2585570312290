<template>
  <div class="FormEsoSnapshot">
    <template v-if="projectOptions.length">
      <Notice class="info">
        Rodomi projektai turintys nustatymuose nurodytą jėgainės objekto numerį ir savininką.
      </Notice>
      <Field
        data-cy="projectName"
        label="Projektas"
        :options="projectOptions"
        type="select"
        :value.sync="esoSnapshot.projectId" />
      <RadioTabs
        data-cy="exportType"
        label="Eksporto tipas"
        :value.sync="esoSnapshot.isCombined">
        <div
          slot="false"
          label="Atskirai"
          :value="false">
          Kiekvieno kliento pakitimai atskirame faile.
        </div>
        <div
          slot="true"
          label="Kartu"
          :value="true">
          Visų klientų pakitimai viename faile.
        </div>
      </RadioTabs>
    </template>
    <Notice v-else class="warning">
      Nėra projektų su įrašytu jėgainės objekto numeriu ir savininku. Prašome projekto redagavimo puslapyje prie norimo projekto pridėti jėgainės objekto numerį ir savininką.
    </Notice>
  </div>
</template>

<script>
import { actions, getters } from 'views/utils'
import RadioTabs from 'components/fields/RadioTabs'

export default {
  components: {
    RadioTabs,
  },
  props: {
    esoSnapshot: Object,
  },
  computed: {
    projectOptions() {
      const projects = this.query('project', {
        objectNo: { $ne: null },
        objectOwner: { $ne: null },
      })

      return projects
        .map(project => [
          project.id,
          project.name,
        ])
    },
    projectReservations() {
      const { projectId } = this.esoSnapshot

      if (!projectId) return []

      return this.query('reservation', { projectId })
    },
    ...getters(),
  },
  methods: actions(
    'FETCH_USER',
  ),
}
</script>

<style lang="scss">
.FormEsoSnapshot {
  .RadioTabs {
    margin-bottom: $grid-unit;
  }
}
</style>
