<template>
  <StepField class="StepEventQueries" :label="serialized">
    <ProjectQuery
      :value="stepEvent.projectQuery"
      @update:value="setField('projectQuery', $event)" />
    <ReservationQuery
      :value="stepEvent.reservationQuery"
      @update:value="setField('reservationQuery', $event)" />
  </StepField>
</template>

<script>
import ProjectQuery from './ProjectQuery'
import ReservationQuery from './ReservationQuery'
import StepField from '../StepField'
import { getters } from '@/views/utils'

export default {
  components: {
    ProjectQuery,
    ReservationQuery,
    StepField,
  },
  props: {
    stepEvent: Object,
  },
  computed: {
    serialized() {
      return this.serializeStepEventConditions(this.stepEvent)
    },
    ...getters('serializeStepEventConditions'),
  },
  methods: {
    updateEvent(stepEvent) {
      this.$emit('update:stepEvent', stepEvent)
    },
    setField(key, value) {
      this.updateEvent({
        ...this.stepEvent,
        [key]: value,
      })
    },
  },
}
</script>

<style lang="scss">
.StepEventQueries {
}
</style>
