<template>
  <div class="SubcardContainer">
    <h3>{{ title }}</h3>
    <Card>
      <slot />
    </Card>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
}
</script>

<style lang="scss">
.SubcardContainer {
  > h3 {
    font-weight: $semibold;
    margin-bottom: 1.5rem;
  }

  > .Card {
    padding: 1rem;
  }
}
</style>
