<template>
  <Box class="Record" data-cy="record" :title="title">
    <slot
      v-if="$slots.header"
      name="header" />
    <slot
      v-if="$slots.title"
      class="title"
      name="title" />
    <div class="body">
      <slot />
    </div>
    <slot name="between" />
    <div v-if="$slots.actions || $slots.meta" class="actions">
      <div v-if="$slots.meta" class="meta">
        <slot name="meta" />
      </div>
      <slot name="actions" />
    </div>
  </Box>
</template>

<script>
import Box from './Box.vue'

export default {
  components: { Box },
  props: {
    title: String,
  },
}
</script>

<style lang="scss">
.Box.Record {
  > .body {
    margin: 1rem;

    .Set {
      margin-bottom: 0.5rem;
    }
  }

  > .actions {
    border-top: 1px solid $border-color-light;
    padding: 0.5rem;
    text-align: right;

    > .meta {
      float: left;
      padding: 0.25rem 0.5rem;
    }

    > div > .Btn {
      margin-left: 0.5rem;
    }
  }

  &.scale {
    &:hover {
      transform: scale(1.01);
    }
  }
}
</style>
