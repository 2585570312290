<template>
  <BoardView title="Projekto užsakymo procesas">
    <div class="steps">
      <template v-if="steps.length">
        <Step
          v-for="step in steps"
          :key="step.id"
          :step="step" />
      </template>
      <Empty v-else>
        Nėra jokio proceso.
      </Empty>
      <StepAdd />
    </div>
  </BoardView>
</template>

<script>
import { actions, getters } from './utils'
import BoardView from 'components/board/BoardView'
import Step from './components/admin/Step'
import StepAdd from './components/admin/StepAdd'

export default {
  components: { BoardView, Step, StepAdd },
  computed: {
    steps() {
      return this.query('step', {
        $sort: { order: 1 },
      })
    },
    ...getters(),
  },
  methods: actions(),
  created() {
    this.LOAD([
      'FETCH_STEP',
      'FETCH_STEP_CONTRACT',
      'FETCH_STEP_MESSAGE',
      'FETCH_STEP_PAYMENT',
    ])
  },
}
</script>

<style lang="scss">
</style>
