<template>
  <div class="ReservationCapacityChange">
    <!--
    <Btn
      class="sm ib"
      data-cy="capacityChange"
      @click="isOpen = true">
      Keisti galingumą
    </Btn>
    -->
    <ModalCont v-if="isOpen" @close="close">
      <Notice class="fancy">
        <h3>
          Keisti rezervacijos galingumą
        </h3>
        <div class="explanation">
          <p>
            Galingumą galima keisti tik kol avansas yra nesumokėtas.
          </p>
          <p>
            Pakeitus galingumą:
          </p>
          <ul>
            <li>
              sukuriamas rezervacijos sutarties priedas, kuriame nurodomas pakeistas galingumas;
            </li>
            <li>
              pagrindinė sutartis ir avansinis mokėjimas bus pakeisti pagal naują galingumą.
            </li>
            <li>
              klientas gaus pranešimą apie naują pagrindinę sutartį;
            </li>
          </ul>
        </div>
      </Notice>
      <FormValidation @submit="updateCapacity">
        <Field
          :disabled="true"
          label="Dabartinis galingumas"
          type="number"
          :value="reservation.capacity" />
        <Field
          data-cy="capacityWanted"
          label="Norimas galingumas"
          step="0.1"
          type="number"
          :value.sync="capacityWanted" />
        <Row class="actionsRow">
          <Column
            :xs="6">
            <Btn
              class="neutral"
              :disabled="isSaving"
              @click="close">
              Atšaukti
            </Btn>
          </Column>
          <Column
            :xs="6">
            <Btn
              class="success"
              :disabled="isSaving || !isChanged"
              :isLoading="isSaving"
              type="submit">
              Pakeisti
            </Btn>
          </Column>
        </Row>
      </FormValidation>
    </ModalCont>
  </div>
</template>

<script>
import { actions } from '@/views/utils'

export default {
  props: {
    reservation: Object,
  },
  data() {
    return {
      isOpen: false,
      isSaving: false,
      capacityWanted: this.reservation.capacity,
    }
  },
  computed: {
    isChanged() {
      return this.reservation.capacity !== this.capacityWanted
    },
  },
  methods: {
    close() {
      this.isOpen = false
    },
    async updateCapacity() {
      await this.$wrap(
        () => this.PATCH_RESERVATION({
          id: this.reservation.id,
          capacity: this.capacityWanted,
        }),
        'isSaving',
        'Galingumas pakeistas sėkmingai',
      )

      this.close()
    },
    ...actions('PATCH_RESERVATION'),
  },
}
</script>

<style lang="scss">
.ReservationCapacityChange {
  .Modal {
    display: grid;
    grid-template-columns: 1fr;
    overflow-x: hidden;
    @include md {
      grid-template-columns: 1fr 1fr;
    }

    > .Notice {
      background: $success;
      color: $white;
      margin: 0;

      > .text {
        display: grid;
        grid-template-rows: 1fr auto;
        height: 100%;

        > h3 {
          color: inherit;
        }

        > .explanation {
          font-weight: $regular;
          text-align: left;

          p {
            margin-top: $grid-unit;
          }

          li {
            list-style-position: inside;
            list-style-type: decimal;
          }
        }
      }
    }

    form {
      padding: $grid-unit-half $grid-unit $grid-unit;
    }

    .actionsRow {
      margin-top: $grid-unit;
    }
  }
}
</style>
