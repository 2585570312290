<template>
  <div>
    <FormSect title="Parametrai">
      <Row>
        <Column :md="6">
          <Field
            label="Gamintojas"
            :value.sync="solarInverter.manufacturer" />
        </Column>
        <Column :md="6">
          <Field
            label="Modelis"
            :maxlength="50"
            :minlength="1"
            :value.sync="solarInverter.model" />
        </Column>
        <Column :md="6">
          <Field
            label="Galia (kW)"
            :max="10000"
            :min="1"
            type="number"
            :value.sync="solarInverter.capacity" />
        </Column>
        <Column :md="6">
          <Field
            label="Garantija (metai)"
            :max="100"
            :min="1"
            type="number"
            :value.sync="solarInverter.warranty" />
        </Column>
        <Column>
          <Field
            label="Nuoroda į aprašymą"
            :maxlength="1000"
            required
            :value.sync="solarInverter.moreInfoSrc" />
        </Column>
      </Row>
    </FormSect>
    <FormSect title="Aprašymas">
      <VMdEditor v-model="description" />
    </FormSect>
  </div>
</template>

<script>

import '@kangc/v-md-editor/lib/style/base-editor.css'
import '@kangc/v-md-editor/lib/theme/style/github.css'
import VMdEditor from '@kangc/v-md-editor'
import enUS from '@kangc/v-md-editor/lib/lang/en-US'
import { getters } from 'views/utils'
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js'
import hljs from 'highlight.js'

VMdEditor.xss.extend({
  // extend white list
  whiteList: {
    source: [],
  },
})

VMdEditor.use(githubTheme, {
  Hljs: hljs,
})
VMdEditor.lang.use('en-US', enUS)

export default {
  components: {
    VMdEditor,
  },
  props: {
    solarInverter: Object,
  },
  data() {
    return {
      description: this.solarInverter.description || '',
    }
  },
  computed: {
    ...getters(),
  },
  watch: {
    description: {
      immediate: true,
      handler(description) {
        this.solarInverter.description = description
      },
    },
  },
}
</script>

<style lang="scss">
</style>
