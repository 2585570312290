<template>
  <div class="BoardView">
    <Wrap :class="wrap">
      <div v-if="title" class="BoardView-title">
        <Row>
          <Column :sm="hasButton ? 8 : 12" :xs="hasButton ? 6 : 12">
            <h2>
              {{ title }}
            </h2>
            <div>
              {{ sub }}
            </div>
          </Column>
          <Column v-if="hasButton" :sm="4" :xs="6">
            <slot name="button" />
          </Column>
        </Row>
        <TableSearch
          v-if="pagination"
          :pagination="pagination"
          @update:search="setSearch"
          @update:sortIndex="setSort" />
      </div>
      <div class="BoardView-inner">
        <slot v-if="isLoaded || hasBoardTable" />
        <Empty v-if="!isLoaded">
          <Loader class="lg info" />
          Ieškoma
        </Empty>
      </div>
      <TableNav
        v-if="pagination"
        :pagination="pagination"
        :total="total" />
    </Wrap>
  </div>
</template>

<script>
import PaginationAccess from 'mixins/PaginationAccess'
import TableNav from 'components/board/TableNav'
import TableSearch from 'components/board/TableSearch'
import { getters } from 'views/utils'

export default {
  mixins: [PaginationAccess],
  components: {
    TableNav,
    TableSearch,
  },
  props: {
    title: String,
    sub: String,
    total: Number,
    hasBoardTable: {
      default: false,
      type: Boolean,
    },
    wrap: {
      default: 'lg',
      type: String,
    },
  },
  computed: {
    hasButton() {
      return this.$slots.button
    },
    ...getters(),
  },
}
</script>

<style lang="scss">
.BoardView {
  .BoardView-title {
    border-bottom: 1px solid $border-color;
    margin-bottom: $grid-unit;
    padding: 2rem 0;
    @include md {
      .Btn span.add {
        display: inline-block;
      }
    }

    h2 {
      @include ellipsis;
      line-height: 3rem;
      text-transform: none;
    }

    .Btn span.add {
      display: none;
    }
  }

  > .FormSect {
    box-shadow: $shadow-md;
    padding: 0.5rem 0;
  }

  h3, h4 {
    color: $text-color-mid;
  }

  > .list, .TableSearch {
    border-top: 1px solid $border-color;
    margin-top: 1rem;
    padding-top: 1rem;
    @include md {
      padding-top: 1.5rem;
    }
  }
}
</style>
