<template>
  <Tag class="TagUser sm">
    <div class="name">
      {{ user | name }}
    </div>
    <div class="email">
      {{ user.email }}
    </div>
    <div class="remove" @click="$emit('remove')">
      <Icon name="cross" />
    </div>
  </Tag>
</template>

<script>

export default {
  props: {
    user: Object,
  },
}
</script>

<style lang="scss">
.TagUser {
  background-color: $grey-lighter;
  color: $black;
  margin: 0 0.5rem 0.5rem 0;
  position: relative;
  text-align: left;

  .name {
    font-weight: $semibold;
  }

  .email {
    font-weight: $regular;
  }

  .remove {
    align-items: center;
    background-color: $grey-lightest;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0.1s linear;
    width: 1.5rem;
  }

  &:hover {
    .remove {
      opacity: 1;
    }
  }
}
</style>
