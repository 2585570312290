<template>
  <div class="StepEventBranch">
    <StepEvent
      v-for="stepEvent in stepEvents"
      :key="stepEvent.id"
      :stepEvent="stepEvent" />
  </div>
</template>

<script>
import StepEvent from './StepEvent'

export default {
  components: { StepEvent },
  props: {
    stepEvents: Array,
  },
}
</script>

<style lang="scss">
.StepEventBranch {
  .StepEvent {
    margin-bottom: $grid-unit;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
</style>
