<template>
  <Field
    v-bind="$attrs"
    :options="options"
    type="select"
    :value="value"
    @update:value="$emit('update:value', $event)" />
</template>

<script>
import { getters } from '@/views/utils'

export default {
  props: {
    triggers: Array,
    value: String,
  },
  computed: {
    options() {
      return this
        .triggers
        .map(triggerConcat => [triggerConcat, this.getEventName(triggerConcat)])
    },
    ...getters('getEventName'),
  },
}
</script>
