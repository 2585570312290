<template>
  <BoardView hasBoardTable :pagination="pagination" title="Sutartys" :total="total">
    <Btn
      slot="button"
      class="success icon-side-left"
      iconSide="left"
      :isLoading="isExporting"
      @click="createSheetExport">
      <Icon name="file" /> Eksportuoti
    </Btn>
    <BoardTable :hasResults="!!total" :isFetching="isFetching" :pagination="pagination">
      <div>
        <BulkMessages
          :records="results"
          :recordsSelectedIds.sync="recordsSelectedIds"
          :recordsSelectedUserIds.sync="recordsSelectedUserIds" />
        <CardContract
          v-for="contract in results"
          :key="contract.id"
          :contract="contract"
          :isChecked="recordsSelectedIds.includes(contract.id)"
          @update:isChecked="selectRecord($event, contract)" />
      </div>
    </BoardTable>
    <router-view />
  </BoardView>
</template>

<script>
import BulkMessages from './components/admin/BulkMessages'
import CardContract from './components/admin/CardContract'
import SheetExport from './mixins/SheetExport'
import Table from './mixins/Table'
import { actions } from 'views/utils'
import { pluckIds } from './utils/collections'

export default {
  mixins: [SheetExport, Table],
  components: { CardContract, BulkMessages },
  data() {
    return {
      paginationOptions: {
        name: 'contract',
        service: 'contract',
        searchIn: [
          'contractNo',
          'name',
          'user.firstName',
          'user.lastName',
        ],
        filters: [
          {
            label: 'Projektas',
            type: 'select',
            options: () => this.$store.getters.projectOptions,
            append: 'projectId',
          },
          {
            label: 'Sutarties tipas',
            type: 'select',
            options: this.$store.getters.contractTypesOptions,
            append: 'type',
          },
          {
            label: 'Galia nuo (KW)',
            type: 'number',
            append: ['reservation.capacity', '$gte'],
          },
          {
            label: 'Galia iki (KW)',
            type: 'number',
            append: ['reservation.capacity', '$lte'],
          },
          {
            label: 'Kliento ID',
            type: 'number',
            append: 'userId',
          },
          {
            label: 'Vardas',
            append: ['user.firstName', '$ilike'],
          },
          {
            label: 'Pavardė',
            append: ['user.lastName', '$ilike'],
          },
          {
            label: 'Sutarties numeris',
            append: ['contractNo', '$ilike'],
          },
          {
            label: 'Užsakymo suma nuo',
            type: 'number',
            append: ['reservation.priceTotal', '$gte'],
          },
          {
            label: 'Užsakymo suma iki',
            type: 'number',
            append: ['reservation.priceTotal', '$lte'],
          },
          {
            label: 'Sukurta nuo (data)',
            type: 'date',
            append: ['createdAt', '$gte'],
          },
          {
            label: 'Sukurta iki (data)',
            type: 'date',
            append: ['createdAt', '$lte'],
          },
        ],
      },
    }
  },
  methods: actions(
    'FETCH_RESERVATION',
    'FETCH_PROJECT',
    'FETCH_PROMO',
    'FETCH_USER',
    'FETCH_APVA_STAGE',
  ),
  watch: {
    async results(contracts) {
      if (!contracts.length) return

      const [projectIds, promoIds, reservationIds, userIds] = pluckIds([
        'projectId',
        'promoId',
        'reservationId',
        'userId',
      ], contracts)

      await this.LOAD(() => Promise.all([
        this.FETCH_PROJECT(projectIds),
        this.FETCH_PROMO(promoIds),
        this.FETCH_RESERVATION(reservationIds),
        this.FETCH_USER(userIds),
        this.FETCH_APVA_STAGE(),
      ]))
    },
  },
}
</script>
