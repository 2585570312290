<template>
  <div class="PinMap">
    <div class="map">
      <Pic
        alt="Lithuania"
        :formats="['webp']"
        src="../../../assets/lithuania-grey.png" />
      <Pic
        v-if="position[0]"
        alt="Lithuania"
        class="pin"
        :class="{ 'animate-bouncing': hasAnimation }"
        :formats="['webp']"
        src="../../../assets/landing/pin.png"
        :style="styling" />
      <div v-if="name && position[0]" class="name">
        {{ name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hasAnimation: Boolean,
    name: String,
    position: Array,
  },
  computed: {
    styling() {
      const [left, top] = this.position
      const animationDelay = `${((Math.random() * 3) + 0.5).toFixed(1)}s`

      return {
        animationDelay,
        top: `${top}%`,
        left: `${left}%`,
      }
    },
  },
}
</script>

<style lang="scss">
$_res-xsp: 350px;
$_res-xsm: 405px;
$_res-xsmp: 406px;
$_res-xsl: 485px;

@keyframes bouncing {
  0% { transform: scale(1) translateY(0); }
  6% { transform: scale(1.05, 0.95) translateY(0); }
  18% { transform: scale(0.95, 1.05) translateY(-20px); }
  30% { transform: scale(1.03, 0.97) translateY(0); }
  33% { transform: scale(0.98, 1.02) translateY(-4px); }
  38% { transform: scale(1) translateY(0); }
  100% { transform: scale(1) translateY(0); }
}

.PinMap {
  display: flex;
  justify-content: center;
  max-width: 100%;

  > .map {
    position: relative;
    z-index: 2;

    img {
      max-width: 100%;
    }

    > .pin {
      align-self: flex-end;
      position: absolute;
      transform-origin: bottom;
      width: 1.5rem;
      @include lg {
        width: 2rem;
      }
    }

    > .name {
      left: 0;
      position: absolute;
      text-align: right;
      text-transform: uppercase;
      top: 58%;
      width: 9rem;
      @include maxXs {
        font-size: $h6;
        left: -35%;
      }
      @media screen and (min-width: $_res-xsp) and (max-width: $_res-xsm) {
        left: -20%;
      }
      @media screen and (min-width: $_res-xsmp) and (max-width: $_res-xsl) {
        left: -10%;
      }
      @include sm {
        font-size: $h5;
        left: 0;
      }
      @include lg {
        left: -40% !important;
        top: 25%;
        width: 12rem;
      }
    }
  }

  &.sm {
    > .map > .pin {
      width: 0.755rem;
      @include lg {
        width: 1rem;
      }
    }
  }
}

.animate-bouncing {
  animation-delay: 1s;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-name: bouncing;
  animation-timing-function: cubic-bezier(0.3, 0.85, 0.4, 1);
}
</style>
