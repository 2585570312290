<template>
  <ModalCont class="PromptReservationCancel">
    <h3 class="name">
      Ar tikrai norite atšaukti šį užsakymą?
    </h3>
    <div class="PromptReservationCancel-body">
      <Notice v-if="hasPaidPayments" class="warning">
        Šis klientas turi apmokėtų sąskaitų. Tai nutrauks visas jo sutartis ir nebeleis priimti daugiau mokėjimų.
      </Notice>
      <Notice v-if="!canCancelDueToLatePayment" class="info">
        Šis klientas yra apmokėjęs visas sąskaitas. Dėl to galima nutraukti sutartį tik vienašališkai.
      </Notice>
      <RadioTabs
        label="Atšaukimo priežastis"
        :value.sync="isUnilateral">
        <div
          slot="false"
          data-cy="cancelPaymentLate"
          :disabled="!canCancelDueToLatePayment"
          label="Vėluojantis mokėjimas"
          :value="false">
          Atšaukti dėl vėluojančio mokėjimo.
        </div>
        <div
          slot="true"
          data-cy="cancelUnilateral"
          label="Atšaukti vienašališkai"
          :value="true">
          Atšaukti ne dėl vėluojančio mokėjimo, o dėl kitų priežasčių.
        </div>
      </RadioTabs>
      <div v-if="messages.length" class="clientMessages">
        <strong>
          Klientas gaus šias žinutes:
        </strong>
        <Card
          v-for="message in messages"
          :key="message.id"
          :title="message.name">
          <pre class="text">{{ message.text }}</pre>
        </Card>
      </div>
      <Empty v-else-if="isUnilateral !== null">
        Vartotojas negaus jokios žinučių.
      </Empty>
    </div>
    <div class="actionsRow">
      <Btn class="ib" @click="close">
        Ne, neatšaukti
      </Btn>
      <Btn
        class="danger ib"
        data-cy="cancelSubmit"
        :disabled="isUnilateral === null"
        @click="cancelReservation">
        Taip, atšaukti užsakymą
      </Btn>
    </div>
  </ModalCont>
</template>

<script>
import { CONTRACT_CANCEL_REASONS, CONTRACT_TYPES } from '@/store/consts'
import { actions, getters } from 'views/utils'
import RadioTabs from 'components/fields/RadioTabs'
import { prop } from 'rambda'
import sift from 'sift'

export default {
  components: { RadioTabs },
  props: {
    reservation: Object,
  },
  data() {
    return {
      isCancelling: false,
      isUnilateral: null,
    }
  },
  computed: {
    canCancelDueToLatePayment() {
      return !!this.paymentsUnpaid.length
    },
    hasPaidPayments() {
      return !!this
        .query('payment', {
          reservationId: this.reservation.id,
          paidAt: { $ne: null },
        })
        .length
    },
    paymentsUnpaid() {
      return this
        .query('payment', {
          reservationId: this.reservation.id,
          cancelledAt: null,
          paidAt: null,
        })
    },
    messages() {
      if (this.isUnilateral === null) return []

      const triggerMatch = (() => {
        if (this.isUnilateral) {
          const typesCancellable = [
            CONTRACT_TYPES.RESERVATION,
            CONTRACT_TYPES.MAIN,
          ]

          const contractLast = this
            .queryOne('contract', {
              reservationId: this.reservation.id,
              type: { $in: typesCancellable },
              $sort: { id: -1 },
            })

          return {
            action: 'cancelledUnilateral',
            type: 'contract',
            resourceType: contractLast.type,
          }
        }

        return {
          $or: this.paymentsUnpaid
            .map(({ type }) => ({
              action: 'cancelled',
              type: 'payment',
              resourceType: type,
            })),
        }
      })()

      const project = this
        .queryOne('project', this.reservation.projectId)

      const steps = this
        .getMatchingStepsByTrigger(triggerMatch, project, this.reservation)

      return steps
        .flatMap(prop('outcomes'))
        .filter(sift({
          type: 'message',
          action: 'created',
          resourceId: { $ne: null },
        }))
        .map(prop('resourceId'))
        .map(resourceId => this
          .queryOne('stepMessage', resourceId))
    },
    ...getters(
      'getMatchingStepsByTrigger',
    ),
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async cancelReservation() {
      await this.$wrap(() => this
        .PATCH_RESERVATION({
          id: this.reservation.id,
          isCancelled: true,
          cancelledReason: this.isUnilateral
            ? CONTRACT_CANCEL_REASONS.UNILATERAL_PROVIDER
            : null,
        }), 'isCancelling')

      this.close()
    },
    ...actions(
      'FETCH_CONTRACT',
      'FETCH_PAYMENT',
      'PATCH_RESERVATION',
    ),
  },
  watch: {
    canCancelDueToLatePayment: {
      immediate: true,
      handler(canCancelDueToLatePayment) {
        if (canCancelDueToLatePayment) return

        this.isUnilateral = true
      },
    },
  },
  async mounted() {
    const reservationQuery = {
      reservationId: this.reservation.id,
    }

    await Promise.all([
      this.FETCH_CONTRACT(reservationQuery),
      this.FETCH_PAYMENT(reservationQuery),
    ])
  },
}
</script>

<style lang="scss">
.PromptReservationCancel {
  .PromptReservationCancel-body {
    padding: $grid-unit;
    text-align: left;
  }

  .clientMessages {
    margin-top: $grid-unit;

    > strong {
      display: block;
      margin-bottom: $grid-unit;
    }

    > .Card {
      padding: $grid-unit;

      > .text {
        font-family: $font-body;
        padding: $grid-unit;
      }

      &:last-of-type {
        margin: 0;
      }
    }
  }
}
</style>
