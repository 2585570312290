<template>
  <div class="Box">
    <h5 v-if="title" class="title" :title="title">
      {{ title }}
    </h5>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
}
</script>

<style lang="scss">
.Box {
  @include trans;
  background: $white;
  border: 1px solid $border-color-light;
  border-bottom: 2px solid $border-color-light;
  border-radius: $radius;
  margin-bottom: $grid-unit;
  overflow: hidden;
  position: relative;

  > h5.title {
    // @include ellipsis;
    font-size: $h4;
    font-weight: $semibold;
    padding: 1rem 1rem 0;
  }

  .Empty {
    margin: 0;
  }
}
</style>
