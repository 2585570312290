import { isNil, map, pipe, reject } from 'rambda'
import Decimal from 'decimal.js-light'

export const toAdjustment = percent => (percent === '' || percent === null)
  ? null
  : new Decimal(percent).plus(100).toString()

export const toAdjustmentRow = row => ({
  adjustment: toAdjustment(row.percent),
  date: row.date,
})

export const toAdjustmentRows = pipe(
  reject(({ date, percent }) => isNil(date) || isNil(percent)),
  map(toAdjustmentRow),
)
