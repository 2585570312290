<script>
import { actions, getters } from 'views/utils'
import { assoc, evolve, map, pipe } from 'rambda'
import BoardView from 'components/board/BoardView'
import FormStep from 'components/forms/FormStep'
import { unwrapObjectPromisesDeep } from 'views/utils/async'

export default {
  components: { BoardView, FormStep },
  data() {
    return {
      hasFinishedInit: false,
      isLoading: false,
      step: null,
    }
  },
  computed: getters(),
  methods: {
    async presubmit(step) {
      const toServerResult = (() => {
        const request = (action, type, resource) =>
          this[`${action}_STEP_${type.toUpperCase()}`](resource)

        return map(async ({ resource, ...stepEvent }) => {
          if (!resource) return stepEvent

          if (!stepEvent.resourceId) {
            const { id } = await request('CREATE', stepEvent.type, resource)

            return assoc('resourceId', id, stepEvent)
          }

          await request('PATCH', stepEvent.type, resource)

          return stepEvent
        })
      })()

      return pipe(
        evolve({
          cancellations: toServerResult,
          triggers: toServerResult,
          outcomes: toServerResult,
        }),
        unwrapObjectPromisesDeep,
      )(step)
    },
    ...actions(
      'CREATE_STEP_CONTRACT',
      'CREATE_STEP_MESSAGE',
      'CREATE_STEP_PAYMENT',
      'PATCH_STEP_CONTRACT',
      'PATCH_STEP_MESSAGE',
      'PATCH_STEP_PAYMENT',
    ),
  },
}
</script>
