<template>
  <div class="FileDrop">
    <Dropzone
      :id="id"
      :dropzoneOptions="dzOptions"
      :url="`${apiUrl}/upload`"
      :useCustomDropzoneOptions="true"
      @vdropzone-sending="onSending"
      @vdropzone-success="onSuccess" />
  </div>
</template>

<script>
import Dropzone from './Dropzone'
import { apiUrl } from '../../config'

const optional = (key, value) => value ? ({ [key]: value }) : {}

export default {
  components: { Dropzone },
  props: {
    id: {
      default: 'dropzone',
      type: String,
    },
    init: {
      default() {
        return function init() {
          // this.on('uploadprogress', (file, progress) => {})
          this.on('complete', (file) => {
            this.removeFile(file)
          })
        }
      },
      type: Function,
    },
    options: {
      default: () => ({}),
      type: Object,
    },
    data: {
      default: () => ({}),
      type: Object,
    },
    message: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      apiUrl,
      defaultOptions: {
        acceptedFiles: '.docx',
        init: this.init,
        maxFilesize: 10,
        paramName: 'uri',
        uploadMultiple: false,
      },
    }
  },
  computed: {
    dzOptions() {
      return {
        ...this.defaultOptions,
        ...this.options,
        ...optional('dictDefaultMessage', this.message),
      }
    },
  },
  methods: {
    onSuccess({ xhr }) {
      try {
        this.$emit('success', JSON.parse(xhr.response))
      } catch (error) {
        this.$emit('success')
      }
    },
    onSending(file, xhr, formData) {
      Object.keys(this.data).forEach((key) => {
        const parameter = this.data[key]
        formData.append(key, parameter)
      })
    },
  },
}
</script>

<style lang="scss">
.dropzone {
  min-height: 50px !important;
  padding: 0 !important;
}

.dz-upload {
  background-color: $success;
  display: block;
  height: 10px;
  width: 0%;
}
</style>
