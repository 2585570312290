<template>
  <div class="Bar">
    <div class="Bar-parts">
      <div
        v-for="({ color, theme, width }, index) in partsVisible"
        :key="index"
        class="Bar-part"
        :style="{ width }">
        <div
          class="colored"
          :class="theme"
          :style="{ background: color ? `#${color}` : undefined }" />
      </div>
      <div v-if="hasRemaining" class="part remaining">
        <div class="colored" />
      </div>
    </div>
    <div v-if="hasLabels" class="Bar-labels">
      <BarSet
        v-for="({ label, valueDisplayed, width }, index) in partsVisible"
        :key="index"
        :alt="`${label}: ${valueDisplayed}`"
        class="Bar-label"
        :label="label"
        :style="{ width }">
        {{ valueDisplayed }}
      </BarSet>
    </div>
  </div>
</template>

<script>
import BarSet from './BarSet'

export default {
  components: {
    BarSet,
  },
  props: {
    hasLabels: Boolean,
    parts: Array,
    max: Number,
  },
  computed: {
    hasRemaining() {
      return this.total > this.parts.reduce(({ value }, sum) => sum + value, 0)
    },
    partsVisible() {
      return this.parts
        .map(part => ({
          ...part,
          valueDisplayed: part.valueDisplayed || part.value,
          width: `${((part.value / this.max) * 100).toFixed(2)}%`,
        }))
        .filter(({ value }) => value)
    },
  },
}
</script>

<style lang="scss">
.Bar {
  border-radius: ($radius-lg * 2);
  display: flex;
  flex-direction: column;
  width: 100%;

  // ----------- size --------------
  &.xs {
    .Bar-parts {
      height: 0.25rem;
    }
  }

  &.sm {
    .Bar-parts {
      height: 0.5rem;
    }
  }

  // ----------- theme --------------
  &.danger {
    background: $danger-light;
  }

  &.info {
    background: $info-light;
  }

  &.success {
    background: $success-light;
  }

  &.warning {
    background: $warning-light;
  }
}

.Bar-parts {
  display: flex;
  height: 1rem;
  width: 100%;
}

.Bar-labels {
  display: flex;
  width: 100%;

  > .BarSet {
    margin: 0.25rem 0.5rem 0 0.25rem;
    min-width: min-content;

    .label {
      white-space: nowrap;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.Bar-part {
  float: left;
  height: 100%;

  > .colored {
    background: $main-light;
    height: 100%;

    &.danger {
      background: $danger;
    }

    &.info {
      background: $info;
    }

    &.success {
      background: $success;
    }

    &.warning {
      background: $warning;
    }
  }

  &:first-of-type > .colored {
    border-bottom-left-radius: $radius-lg;
    border-top-left-radius: $radius-lg;
  }

  &:last-of-type > .colored {
    border-bottom-right-radius: $radius-lg;
    border-top-right-radius: $radius-lg;
  }

  &.remaining {
    flex-grow: 1;
  }
}
</style>
