<template>
  <div id="Admin" :class="{ open: isOpen }">
    <Sidebar
      :isOpen="isOpen"
      @update:isOpen="isOpenOnDesktop = $event" />
    <div class="content">
      <div class="inner" :class="[isLoaded || 'loading']">
        <router-view keepAlive />
      </div>
      <div class="alertBars">
        <AlertBarEso />
        <AlertBarInflationAdjustments />
      </div>
    </div>
  </div>
</template>

<script>
import AlertBarEso from 'components/admin/AlertBarEso'
import AlertBarInflationAdjustments from 'components/admin/AlertBarInflationAdjustments'
import Meta from '../mixins/Meta'
import Sidebar from '../components/Sidebar'
import SidebarOpen from '../mixins/SidebarOpen'
import { actions } from '../utils'

export default {
  mixins: [
    Meta('Administracija'),
    SidebarOpen,
  ],
  components: {
    AlertBarEso,
    AlertBarInflationAdjustments,
    Sidebar,
  },
  methods: actions(),
  created() {
    this.LOAD('FETCH_PROJECT')
  },
}
</script>

<style lang="scss">
#Admin {
  background: $admin-background;
  min-height: 100%;
  padding-top: calc(#{$sidebar-burger-height} + 0.5rem);
  @include sm {
    padding-left: $sidebar-width-compact;
    padding-top: 0;
  }

  > .content {
    min-height: 100vh;

    > .inner {
      height: 100%;
    }

    > .alertBars {
      bottom: 0;
      left: 0;
      position: fixed;
      right: 0;
      width: 100%;
      z-index: 31;
    }
  }

  &.open {
    padding-left: $sidebar-width;
  }

  &.loading {
    height: 100%;
  }
}
</style>
