<template>
  <div class="AdminMessageAddMultiple">
    <BoardView title="Siųsti žinutes">
      <FormValidation @submit="create">
        <FormMessage
          :message.sync="message"
          :userIdsFailed="userIdsFailed" />
        <Btn
          class="info"
          data-cy="sendMessage"
          :isLoading="isSaving"
          type="submit">
          Išsiųsti žinutę
        </Btn>
      </FormValidation>
    </BoardView>
  </div>
</template>

<script>
import { actions, getters } from './utils'
import BoardView from 'components/board/BoardView'
import FormMessage from './components/forms/FormMessage'

export default {
  components: { BoardView, FormMessage },
  data() {
    return {
      isSaving: false,
      userIdsFailed: [],
      message: this.$store.getters.messageFactory({
        authorUserId: this.$store.getters.authUser.id,
        userId: this.$route.query.userIds.split(',').map(Number),
      }),
    }
  },
  computed: {
    userIds() {
      return this.$route.query.userIds.split(',').map(Number)
    },
    messagesToSend() {
      return this.message.userId
        .map((userId) => ({
          ...this.message,
          userId,
        }))
    },
    ...getters(),
  },
  methods: {
    async create() {
      const messagesResponses = await this.$wrap(
        Promise.allSettled(this.messagesToSend
          .map(this.CREATE_MESSAGE)),
        'isSaving',
        'Žinutė išsiųsta',
      )

      this.userIdsFailed = messagesResponses
        .map(({ status }, index) => status === 'rejected' && index)
        .filter(result => typeof result === 'number')
        .map(index => this.messagesToSend[index].userId)

      if (this.userIdsFailed.length) {
        this.ERROR(`Nepavyko išsiųsti ${this.userIdsFailed.length} žinučių`)
        return
      }

      this.$router
        .push({ name: 'AdminUsers' })
    },
    ...actions(
      'CREATE_MESSAGE',
      'FIND_CONTRACT',
    ),
  },
}
</script>

<style lang="scss">
.AdminMessageAddMultiple {
  padding-bottom: 2rem;
}
</style>
