<template>
  <Record :title="featureFlag.key">
    <Set name="Įjungtas">
      <Field
        :isSwitch="true"
        type="checkbox"
        :value="featureFlag.isOn"
        @update:value="setIsOn" />
    </Set>
  </Record>
</template>

<script>
import Record from './Record'
import Set from 'components/Set'
import { actions } from 'views/utils'

export default {
  components: {
    Record,
    Set,
  },
  props: {
    featureFlag: Object,
  },
  data() {
    return {
      isToggling: false,
    }
  },
  methods: {
    setIsOn(isOn) {
      return this.$wrap(
        () => this.PATCH_FEATURE_FLAG({
          id: this.featureFlag.id,
          isOn,
        }),
        'isToggling',
      )
    },
    ...actions('PATCH_FEATURE_FLAG'),
  },
}
</script>

<style lang="scss">
</style>
