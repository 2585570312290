<template>
  <MultiSelect
    :internalSearch="false"
    label="name"
    :loading="isLoading"
    :multiple="true"
    :options="options"
    placeholder="Projektai"
    :searchable="true"
    trackBy="id"
    :value="value"
    @search-change="setFilter"
    @update:value="$up('value', $event)" />
</template>

<script>
import { actions, getters } from 'views/utils'
import MultiSelect from 'components/fields/MultiSelect'
import { debounce } from 'views/utils/async'

export default {
  components: {
    MultiSelect,
  },
  props: {
    value: Array,
  },
  data() {
    return {
      isLoading: false,
      filter: '',
    }
  },
  computed: {
    fetchProjectsDebounced() {
      return debounce(1000, this.FETCH_PROJECT)
    },
    options() {
      if (!this.filter) {
        return this.query('project', {
          $sort: { id: -1 },
          $limit: 10,
        })
      }

      return this.query('project', this.projectQuery)
    },
    projectQuery() {
      return {
        $or: [
          { name: { $ilike: this.filter } },
          { nameShort: { $ilike: this.filter } },
        ],
      }
    },
    ...getters('money'),
  },
  methods: {
    setFilter(filter) {
      this.filter = filter
    },
    fetchProjects(projectQuery) {
      this.$wrap(
        () => this.fetchProjectsDebounced(projectQuery),
        'isLoading',
      )
    },
    ...actions('FETCH_PROJECT'),
  },
  watch: {
    filter() {
      if (!this.filter || this.filter.length < 2) return

      return this.fetchProjects(this.projectQuery)
    },
  },
}
</script>

<style lang="scss">
.AutosuggestProject {
}
</style>
