<script>
import { actions, getters } from '../utils'
import Sidebar from '../components/Sidebar'

export default {
  components: { Sidebar },
  data() {
    return {
      isOpenOnDesktop: true,
    }
  },
  computed: {
    isOpen() {
      return this.isOpenOnDesktop && this.screenSizes.includes('md')
    },
    ...getters('localStore', 'screenSizes'),
  },
  methods: actions('SET_STORE'),
  watch: {
    isOpenOnDesktop(isSidebarOpen) {
      if (this.screenSizes.includes('md')) {
        this.SET_STORE({ isSidebarOpen })
      }
    },
    screenSizes: {
      handler(sizes) {
        this.isSidebarOpen = sizes.includes('md')
          ? !!this.localStore('isSidebarOpen')
          : false
      },
    },
  },
}
</script>
