<template>
  <div class="QueryFilter">
    <DatePicker
      v-if="filter.type === 'date'"
      :label="filter.label"
      :value="value"
      @update:value="setValue($event, true)" />
    <Field
      v-else
      :label="filter.label"
      :name="inputName"
      :options="options"
      :type="filter.type"
      :value="value"
      @update:value="setValue" />
  </div>
</template>

<script>
import { actions, getters } from 'views/utils'
import DatePicker from 'components/fields/DatePicker'

const optionsDefault = [[null, 'Visi']]

export default {
  components: { DatePicker },
  props: {
    filter: Object,
    pagination: Object,
  },
  computed: {
    inputName() {
      return typeof this.filter.append === 'string'
        ? `filter-${this.filter.label}`
        : ''
    },
    options() {
      if (this.filter.type !== 'select') return []

      const filterOptions = typeof this.filter.options === 'function'
        ? this.filter.options()
        : this.filter.options

      return [
        ...this.optionsDefault,
        ...filterOptions,
      ]
    },
    optionsDefault() {
      if (this.filter.type !== 'select') return []
      if (typeof this.filter.optionsDefault === 'undefined') return optionsDefault
      if (!this.filter.optionsDefault) return []

      return this.filter.optionsDefault
    },
    value() {
      return this.pagination.filtersValues[this.filter.label]
    },
    valueArray() {
      if (this.value === null) return []

      return [this.value]
    },
    ...getters(),
  },
  methods: {
    setValue(value, doMapNullToUndefined) {
      if (value === null && doMapNullToUndefined) {
        value = undefined
      }

      this.SET_PAGINATION_FILTER({
        pagination: this.pagination,
        label: this.filter.label,
        value,
      })
    },
    ...actions('SET_PAGINATION_FILTER'),
  },
}
</script>

<style lang="scss">
.QueryFilter {
  height: 100%;
}
</style>
