<template>
  <Row class="StepAdd">
    <Route class="Btn info" to="AdminStepAdd">
      <Icon name="plus" />
      Pridėti
    </Route>
  </Row>
</template>

<script>
export default {}
</script>

<style lang="scss">
.StepAdd {
  &-type {
    font-size: $h5;
    text-align: center;
  }
}
</style>
