<template functional>
  <div :class="['Subcard', data.class, data.staticClass]">
    <slot />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.Subcard {
  background: $white;
  border-bottom: 1px solid $border-color;
  padding: 0.5rem 1rem;

  &:last-of-type {
    border: none;
  }
}
</style>
