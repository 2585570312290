<script>
import BoardTable from 'components/board/BoardTable'
import BoardView from 'components/board/BoardView'
import PaginateFetch from 'mixins/PaginateFetch'
import TableSearch from 'components/board/TableSearch'
import { defaultTo } from 'rambda'

export default {
  mixins: [PaginateFetch],
  components: { BoardTable, BoardView, TableSearch },
  data() {
    return {
      recordsSelectedIds: this.parseIdsFromQuery('recordIds'),
      recordsSelectedUserIds: this.parseIdsFromQuery('userIds'),
      paginationOptions: {
        dataColumns: [],
        tableBreakpoint: 'sm',
        columns: {
          xl: 3,
          lg: 2,
          xs: 1,
        },
      },
    }
  },
  computed: {
    dataColumns() {
      const dataColumns = this.paginationOptions.dataColumns
        .map(({ size, ...column }) => ({ ...column, size: defaultTo(1, size) }))

      const proportionTotal = dataColumns
        .reduce((total, { size }) => total + size, 0)

      return dataColumns
        .map(column => ({
          ...column,
          style: {
            width: `${(100 / proportionTotal) * column.size}%`,
          },
        }))
    },
    pagination() {
      if (this.paginationStored) return this.paginationStored

      return {
        ...this.paginationOptions,
        dataColumns: this.dataColumns,
      }
    },
  },
  methods: {
    parseIdsFromQuery(parameterName) {
      return (this.$route.query[parameterName] || '')
        .split(',')
        .filter(Boolean)
        .map(id => parseInt(id))
    },
    selectRecord(isChecked, { id, userId }) {
      if (isChecked) {
        this.recordsSelectedIds.push(id)
        this.recordsSelectedUserIds.push(userId)
      } else {
        this.recordsSelectedIds = this.recordsSelectedIds
          .filter(selectedId => selectedId !== id)
        this.recordsSelectedUserIds = this.recordsSelectedUserIds
          .filter(selectedUserId => selectedUserId !== userId)
      }
    },
  },
  render() {
    return ''
  },
}
</script>
