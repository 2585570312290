<template>
  <CardTable class="Step" :title="step.name">
    <Row>
      <Column :lg="4">
        <StepEvents
          label="Kai"
          :stepEvents="step.triggers" />
      </Column>
      <Column :lg="4">
        <StepEvents
          label="Tada"
          :stepEvents="step.outcomes" />
      </Column>
      <Column :lg="4">
        <StepEvents
          label="Nebent"
          :stepEvents="step.cancellations" />
      </Column>
    </Row>
    <div v-if="step.comment">
      <Notice class="info">
        {{ step.comment }}
      </Notice>
    </div>
    <div v-if="hasRole('>=ADMIN')" slot="actions">
      <span class="ordering">
        <Btn class="ib sm inv neutral" @click="offsetOrder(-1)">
          <Icon name="up" />
        </Btn>
        <Btn class="ib sm inv neutral" @click="offsetOrder(1)">
          <Icon name="down" />
        </Btn>
      </span>
      <Btn
        class="ib sm inv no-border danger"
        icon="danger"
        @click="PROMPT({ text: 'Ar tikrai norite ištrinti šį proceso žingsnį?', confirm: remove })">
        Ištrinti
      </Btn>
      <Route
        :id="step.id"
        class="Btn ib sm info"
        to="AdminStep">
        Redaguoti
      </Route>
    </div>
  </CardTable>
</template>

<script>
import { actions, getters } from 'views/utils'
import CardTable from '../CardTable'
import StepEvents from './StepEvents'

export default {
  components: { CardTable, StepEvents },
  props: {
    step: Object,
  },
  computed: getters(),
  methods: {
    offsetOrder(offset) {
      this.PATCH_STEP({
        id: this.step.id,
        order: (this.step.order || 1) + offset,
      })
    },
    remove() {
      this.DELETE_STEP(this.step)
    },
    ...actions(
      'DELETE_STEP',
      'PATCH_STEP',
      'PROMPT',
    ),
  },
}
</script>

<style lang="scss">
.Step {
}
</style>
