<template>
  <FormSect class="FormPaymentAdjustments">
    <Notice class="info">
      Indeksas bus naudojamas tų pačių metų priežiūros mokesčiams.
      Indeksas išvedamas iš praėjusių metų statistikos departamento duomenų.
    </Notice>
    <strong>
      Metinės infliacijos lubos: {{ inflationLimit }}
    </strong>
    <Loader
      v-if="isFetching"
      class="lg" />
    <InflationAdjustmentsTable
      v-if="hasFetched"
      :project.sync="project"
      :projectPercentYears.sync="projectPercentYears"
      :reservationsPercentYears="reservationsPercentYears"
      :years.sync="years" />
  </FormSect>
</template>

<script>
import { CONTRACT_TYPES, LIMIT_MAX, PAYMENT_TYPES } from '@store/consts'
import { groupBy, indexBy, prop } from 'rambda'
import InflationAdjustmentsTable from './InflationAdjustmentsTable'
import { actions } from 'views/utils'
import formPercentYears from './formPercentYears'
import formYearsRange from './formYearsRange'
import getMaintenanceDates from './getMaintenanceDates'
import { pluckId } from 'views/utils/collections'

export default {
  components: {
    InflationAdjustmentsTable,
  },
  props: {
    project: { type: Object, required: true },
  },
  data() {
    return {
      hasFetched: false,
      isFetching: false,
      projectPercentYears: {},
      reservationsPercentYears: [],
      years: [],
    }
  },
  computed: {
    inflationLimit() {
      if (!this.project.inflationLimit) return 'Nėra'

      return `${this.project.inflationLimit - 100}%`
    },
  },
  methods: {
    async fetchReservations() {
      await this.$wrap(
        async () => {
          const projectId = this.project.id

          const reservations = await this.FIND_RESERVATION({
            projectId,
            isCancelled: false,
            isReserved: true,
            $sort: { id: 1 },
          })

          const reservationsIds = pluckId('id', reservations)

          const [contracts, maintenancePayments, maintenanceSteps, users] = await Promise.all([
            this.FIND_CONTRACT({
              projectId,
              reservationId: { $in: reservationsIds },
              type: CONTRACT_TYPES.TRANSFER_ACCEPTANCE,
              $limit: LIMIT_MAX,
            }),

            this.FIND_PAYMENT({
              type: PAYMENT_TYPES.MAINTENANCE,
              projectId,
              reservationId: { $in: reservationsIds },
              $limit: LIMIT_MAX,
            }),

            this.FIND_USER_STEP({
              action: 'created',
              type: 'payment',
              resourceType: PAYMENT_TYPES.MAINTENANCE,
              reservationId: { $in: reservationsIds },
              $limit: LIMIT_MAX,
            }),

            this.FIND_USER({
              id: { $in: pluckId('userId', reservations) },
              $limit: LIMIT_MAX,
            }),
          ])

          const contractsByReservation = indexBy(prop('reservationId'), contracts)
          const usersById = indexBy(prop('id'), users)
          const paymentsByReservationId = groupBy(prop('reservationId'), maintenancePayments)
          const maintenanceStepsByReservationId = groupBy(prop('reservationId'), maintenanceSteps)

          const years = formYearsRange(
            new Date().getUTCFullYear(),
            contracts,
            reservations,
          )

          this.years = years

          this.projectPercentYears = formPercentYears(years, this.project.inflationAdjustments)

          this.reservationsPercentYears = reservations
            .map(reservation => ({
              percentYears: formPercentYears(years, reservation.inflationAdjustments),
              contract: contractsByReservation[reservation.id] || {},
              maintenanceDates: getMaintenanceDates(
                paymentsByReservationId[reservation.id] || [],
                maintenanceStepsByReservationId[reservation.id],
                years,
              ),
              reservation,
              user: usersById[reservation.userId] || {},
            }))

          this.hasFetched = true
        },
        'isFetching',
      )
    },
    ...actions(
      'FIND_CONTRACT',
      'FIND_PAYMENT',
      'FIND_RESERVATION',
      'FIND_USER',
      'FIND_USER_STEP',
    ),
  },
  mounted() {
    return this.fetchReservations()
  },
}
</script>

<style lang="scss">
.FormPaymentAdjustments {
}
</style>
