<template>
  <BoardView hasBoardTable :pagination="pagination" :title="(project || {}).name" :total="total">
    <Route slot="button" class="Btn info" icon="plus" to="AdminProjectPostAdd">
      Skelbti naujieną
    </Route>
    <BoardTable :hasResults="!!total" :isFetching="isFetching" :pagination="pagination">
      <CardProjectPost
        v-for="post in results"
        :key="post.id"
        :post="post" />
    </BoardTable>
    <router-view />
  </BoardView>
</template>

<script>
import CardProjectPost from './components/admin/CardProjectPost'
import Table from './mixins/Table'

export default {
  mixins: [Table],
  components: { CardProjectPost },
  data() {
    const projectId = Number(this.$route.params.id)

    return {
      paginationOptions: {
        name: `projectPost:${projectId}`,
        service: 'projectPost',
        searchIn: ['title'],
        customQuery: {
          projectId,
        },
      },
    }
  },
  computed: {
    project() {
      return this.query('project', Number(this.$route.params.id))
    },
  },
}
</script>
