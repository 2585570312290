<template>
  <BoardView hasBoardTable :pagination="pagination" title="Promo kodai" :total="total">
    <Route slot="button" class="Btn with-icon icon-side-left info" to="AdminPromosAdd">
      <Icon name="plus" />
      <span v-if="isDesktop">Naujas</span> promo
    </Route>
    <BoardTable :hasResults="!!total" :isFetching="isFetching" :pagination="pagination">
      <CardPromo
        v-for="promo in results"
        :key="promo.id"
        :promo="promo" />
    </BoardTable>
    <router-view />
  </BoardView>
</template>

<script>
import { actions, getters } from 'views/utils'
import CardPromo from './components/admin/CardPromo'
import Table from './mixins/Table'
import { pluckId } from 'views/utils/collections'

export default {
  mixins: [Table],
  components: { CardPromo },
  data() {
    return {
      paginationOptions: {
        name: 'promo',
        service: 'promo',
        searchIn: [
          'prefix',
        ],
        filters: [
          {
            label: 'Būsena',
            type: 'select',
            options: [
              // [null, 'Visi'],
              [1, 'Galiojantis'],
              [2, 'Nebegaliojantis'],
            ],
            append: (value) => ({
              expiresAt: {
                [value === 1 ? '$lt' : '$gt']: new Date(),
              },
            }),
          },
          {
            label: 'Promo grupė',
            append: value => ({
              prefix: {
                $like: value.toUpperCase(),
              },
            }),
          },
          {
            label: 'Sukūrimo data, nuo',
            type: 'date',
            append: ['createdAt', '$gte'],
          },
          {
            label: 'Sukūrimo data, iki',
            type: 'date',
            append: ['createdAt', '$lte'],
          },
        ],
      },
    }
  },
  computed: getters('isDesktop'),
  methods: actions(
    'FETCH_PROJECT',
  ),
  watch: {
    async results(promos) {
      if (!promos.length) return

      const projectIds = pluckId('projectId', promos)

      await this.LOAD(() => Promise.all([
        this.FETCH_PROJECT(projectIds),
      ]))
    },
  },
}
</script>

<style lang="scss">
</style>
