<template>
  <div class="FormStepEvent">
    <div class="selects">
      <StepEventQueries
        :stepEvent="stepEvent"
        @update:stepEvent="updateEvent" />
      <div>
        <StepDelay
          v-if="hasField('delay')"
          :hasLimitType="hasField('limitType')"
          :limitType="stepEvent.limitType"
          :value="stepEvent.delay"
          @update:limitType="setField('limitType', $event)"
          @update:value="setField('delay', $event)" />
      </div>
      <StepEventSelect
        label="Veiksmas"
        :required="true"
        :triggers="eventTypesAllowed"
        :value="concat"
        @update:value="setTypeAction" />
      <div>
        <Field
          v-if="typeOptions"
          label="Tipas"
          :options="typeOptions"
          type="select"
          :value="stepEvent.resourceType"
          @update:value="setField('resourceType', $event)" />
      </div>
      <div class="resourceSelection">
        <template v-if="stepEvent.action && (stepEvent.resourceType || !typeOptions)">
          <Field
            :label="getEventKind(stepEvent.type).label"
            :options="resourceOptions"
            type="select"
            :value="stepEvent.resourceId"
            @update:value="setField('resourceId', $event)" />
          <span v-if="stepEvent.resourceId > 0">
            <Btn
              class="info"
              :class="{ inv: !isEditing }"
              @click="isEditing = !isEditing">
              <Icon name="edit" />
            </Btn>
          </span>
        </template>
      </div>
      <Btn
        class="danger inv no-text"
        :disabled="!canRemove"
        @click="$emit('remove')">
        <Icon name="delete" />
      </Btn>
    </div>
    <div
      v-if="doShowResourceForm">
      <FormStepContract
        v-if="stepEvent.type === 'contract'"
        :isAmendment="stepEvent.action === 'amended'"
        :stepContract="stepEvent.resource"
        @update:stepContract="setField('resource', $event)" />
      <FormStepPayment
        v-else-if="stepEvent.type === 'payment'"
        :stepPayment="stepEvent.resource"
        @update:stepPayment="setField('resource', $event)" />
      <FormStepMessage
        v-else-if="stepEvent.type === 'message'"
        :stepMessage="stepEvent.resource"
        @update:stepMessage="setField('resource', $event)" />
    </div>
    <template v-if="hasRole('>=SUPERADMIN')">
      <div class="meta">
        <BtnCopy :text="stepEvent" />
      </div>
      {{ stepEvent }}
    </template>
  </div>
</template>

<script>
import { detach, getters } from '@/views/utils'
import BtnCopy from './BtnCopy'
import FormStepContract from './FormStepContract'
import FormStepMessage from './FormStepMessage'
import FormStepPayment from './FormStepPayment'
import StepDelay from './StepDelay'
import StepEventQueries from './StepEventQueries'
import StepEventSelect from './StepEventSelect'
import { propEq } from 'rambda'

const NEW_RESOURCE = 0

export default {
  components: {
    BtnCopy,
    FormStepContract,
    FormStepMessage,
    FormStepPayment,
    StepEventQueries,
    StepDelay,
    StepEventSelect,
  },
  props: {
    canRemove: { type: Boolean, default: true },
    eventTypesAllowed: Array,
    fieldsAdditional: { type: Array, default: () => [] },
    stepEvent: Object,
  },
  data() {
    return {
      isEditing: false,
    }
  },
  computed: {
    concat() {
      return `${this.stepEvent.type}.${this.stepEvent.action}`
    },
    doesNeedResourceId() {
      return [
        'contract.created',
        'contract.amended',
        'payment.created',
      ].includes(this.concat)
    },
    doShowResourceForm() {
      const { resource, resourceId, resourceType } = this.stepEvent

      return resource &&
        (resourceType || !this.typeOptions) &&
        (
          resourceId === NEW_RESOURCE ||
          this.isEditing
        )
    },
    typeOptions() {
      const { type } = this.stepEvent

      if (type === 'contract') {
        return this.contractTypes
      }

      if (type === 'payment') {
        return this.paymentTypes
      }

      return null
    },
    resources() {
      const sort = { $sort: { name: 1 } }

      return {
        contract: this.query('stepContract', sort),
        message: this.query('stepMessage', sort),
        payment: this.query('stepPayment', sort),
      }[this.stepEvent.type] || []
    },
    resourceOptions() {
      const { resourceType, type } = this.stepEvent

      if (!type) return

      const canCreateNew = ['contract', 'message', 'payment'].includes(type)
      const canBeAny = ['contract', 'payment', 'reservation'].includes(type)

      return [
        // ...!this.doesNeedResourceId
        ...canBeAny
          ? [{ value: null, label: 'Bet kuri' }]
          : [],
        ...canCreateNew
          ? [{ value: NEW_RESOURCE, label: 'Sukurti naują' }]
          : [],
        ...this.resources
          .filter(resource => !resourceType || resource.type === resourceType)
          .map(({ name, id }) => ({
            label: name,
            value: id,
          })),
      ]
    },
    ...getters(
      'contractTypes',
      'getEventKind',
      'paymentTypes',
      'stepContractFactory',
      'stepMessageFactory',
      'stepPaymentFactory',
    ),
  },
  methods: {
    hasField(key) {
      return this.fieldsAdditional.includes(key)
    },
    updateEvent(stepEvent) {
      this.$emit('update:stepEvent', stepEvent)
    },
    setTypeAction(eventNameConcat) {
      const [type, action] = eventNameConcat.split('.')

      this.updateEvent({
        ...this.stepEvent,
        type,
        action,
      })
    },
    setField(key, value) {
      this.updateEvent({
        ...this.stepEvent,
        [key]: value,
      })
    },
    buildResource() {
      const resource = (() => {
        const { type, resourceId, resourceType } = this.stepEvent

        if (resourceId === null) return null

        if (resourceId !== NEW_RESOURCE) {
          return detach(this.resources.find(propEq('id', resourceId)))
        }

        const resourceFactory = {
          contract: this.stepContractFactory,
          message: this.stepMessageFactory,
          payment: this.stepPaymentFactory,
        }[type]

        if (!resourceFactory) return null

        const resource = resourceFactory({
          resourceId: this.doesNeedResourceId
            ? NEW_RESOURCE
            : null,
        })

        if ('type' in resource) resource.type = resourceType

        return resource
      })()

      this.setField('resource', resource)
    },
  },
  watch: {
    isEditing(isEditing) {
      if (isEditing) return this.buildResource()
    },
    'stepEvent.resourceId': 'buildResource',
    'stepEvent.resourceType': 'buildResource',
  },
}
</script>

<style lang="scss">
.FormStepEvent {
  background: $white;
  border: 1px solid $border-color;
  border-radius: $radius;
  padding: $grid-unit;
  position: relative;

  > .selects {
    align-items: flex-end;
    display: grid;
    gap: $grid-unit;
    grid-template-columns: 4fr 2fr 4fr 4fr 6fr 1fr;
    margin-bottom: $grid-unit;

    .Field-cont {
      margin: 0;
    }

    .resourceSelection {
      align-items: flex-end;
      display: flex;

      > span {
        flex-grow: 1; // fixes small Field components not occupying given space
      }
    }
  }

  > .meta {
    position: absolute;
    right: $grid-unit;
    top: $grid-unit;
  }
}
</style>
