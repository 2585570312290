<template>
  <Subcard class="SubcardUser">
    <Row>
      <Column :md="4">
        <Set name="Vardas">
          {{ user | name }}
        </Set>
      </Column>
      <Column :md="4">
        <Set name="El. paštas">
          <Email :address="user.email" />
        </Set>
      </Column>
      <Column :md="4">
        <Set name="Registracijos laikas">
          {{ user.createdAt | dateTime }}
        </Set>
      </Column>
    </Row>
  </Subcard>
</template>

<script>
import Set from '../Set'
import Subcard from './Subcard'

export default {
  components: { Set, Subcard },
  props: {
    user: Object,
  },
}
</script>

<style lang="scss">
.SubcardUser {
  background: $white;
}
</style>
