<template>
  <PaginationNav
    class="TableNav"
    :hasPerPageControls="hasPerPageControls"
    :label="label"
    :pageIndex="pagination.pageIndex"
    :pageSizes="pageSizes"
    :perPage="pagination.perPage"
    :total="total"
    @update:pageIndex="PATCH_PAGINATION({ pagination, pageIndex: $event })"
    @update:perPage="PATCH_PAGINATION({ pagination, perPage: $event })" />
</template>

<script>
import PaginationNav from 'components/PaginationNav'
import { actions } from 'views/utils'

export default {
  components: { PaginationNav },
  props: {
    hasPerPageControls: {
      default: true,
      type: Boolean,
    },
    label: String,
    pagination: Object,
    pageSizes: {
      type: Array,
      default: () => [12, 50, 100, 200],
    },
    total: Number,
  },
  methods: actions('PATCH_PAGINATION'),
}
</script>

<style lang="scss">
</style>
