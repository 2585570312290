<template>
  <Subcard class="SubcardReservation">
    <Row>
      <Column :md="3">
        <Set :name="project.name">
          {{ user | name }}
        </Set>
      </Column>
      <Column :md="2">
        <Set name="Galingumas">
          {{ reservation.capacity | kw }}
        </Set>
      </Column>
      <template v-if="paymentUnpaid">
        <Column :md="2">
          <Set :name="getPaymentType(paymentUnpaid.type).label">
            {{ paymentUnpaid.amountTotal | currency }}
          </Set>
        </Column>
        <Column :md="2">
          <Set :name="getPaymentStatus(paymentUnpaid).label">
            {{ paymentUnpaid.deadlineAt | relativeTime }}
          </Set>
        </Column>
      </template>
      <Column class="buttons" :md="3">
        <Route :id="reservation.id" class="Btn inv no-border ib sm" to="AdminReservation">
          Peržiūrėti
        </Route>
      </Column>
    </Row>
  </Subcard>
</template>

<script>
import Set from '../Set'
import Subcard from './Subcard'
import { getters } from 'views/utils'

export default {
  components: { Set, Subcard },
  props: {
    reservation: Object,
  },
  computed: {
    paymentUnpaid() {
      return this.getReservationUnpaidPayment(this.reservation)
    },
    paymentUnpaidStatus() {
      return this.paymentUnpaid
        ? 'Neapmokėtas'
        : 'Apmokėtas'
    },
    project() {
      return this.query('project', this.reservation.projectId)
    },
    user() {
      return this.query('user', this.reservation.userId)
    },
    ...getters('getPaymentStatus', 'getPaymentType', 'getReservationUnpaidPayment'),
  },
}
</script>

<style lang="scss">
.SubcardReservation {
  background: $white;

  .buttons {
    margin-top: 0.2rem;
    text-align: right;

    .Btn {
      margin-right: 0.5rem;
    }
  }
}
</style>
