<template>
  <div class="TableTabs">
    <Tab
      v-for="(tab, index) in pagination.tabs"
      :key="index"
      :isActive="isActive(index)"
      :isMultiple="isMultiple"
      @click="toggleTab(index)">
      {{ tab.label }}
    </Tab>
  </div>
</template>

<script>
import Tab from 'components/Tab'
import { actions } from 'views/utils'

export default {
  components: { Tab },
  props: {
    pagination: Object,
  },
  computed: {
    isMultiple() {
      return this.pagination.isTabsMultiple
    },
  },
  methods: {
    isActive(index) {
      return this.pagination.tabsIndexes.includes(index)
    },
    setTabsIndexes(tabsIndexes) {
      return this.PATCH_PAGINATION({
        pagination: this.pagination,
        tabsIndexes,
      })
    },
    toggleTab(index) {
      const isActive = this.isActive(index)

      if (!this.isMultiple) {
        if (!isActive) this.setTabsIndexes([index])
        return
      }

      const { tabsIndexes } = this.pagination

      const tabsIndexesUpdated = isActive
        ? tabsIndexes.filter(tabIndex => tabIndex !== index)
        : [...tabsIndexes, index]

      if (tabsIndexesUpdated.length) {
        this.setTabsIndexes(tabsIndexesUpdated)
      }
    },
    ...actions('PATCH_PAGINATION'),
  },
}
</script>

<style lang="scss">
.TableTabs {}
</style>
