<template>
  <CardTable>
    <Row>
      <Column :md="6" :xl="4">
        <Set icon="mail" name="Gavėjo adresas">
          {{ contactEmail.email }}
        </Set>
      </Column>
      <Column :md="6" :xl="4">
        <Set icon="clock" name="Pridėtas">
          {{ contactEmail.createdAt | date }}
        </Set>
      </Column>
    </Row>
    <div slot="actions">
      <Btn
        class="ib sm inv no-border danger"
        @click="PROMPT({ text: 'Ar tikrai norite ištrinti šį gavėją?', confirm: remove })">
        Ištrinti
      </Btn>
    </div>
  </CardTable>
</template>

<script>
import CardTable from './CardTable'
import Set from '../Set'
import { actions } from '../../utils'

export default {
  components: { CardTable, Set },
  props: {
    contactEmail: Object,
  },
  methods: {
    remove() {
      this.DELETE_CONTACT_EMAIL(this.contactEmail)
    },
    ...actions('DELETE_CONTACT_EMAIL', 'PROMPT'),
  },
}
</script>
