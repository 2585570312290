<template>
  <BoardView title="Siųsti žinutę">
    <FormValidation @submit="create">
      <FormMessage :message="message" />
      <Btn class="info" :isLoading="isSaving" type="submit">
        Išsiųsti žinutę
      </Btn>
    </FormValidation>
  </BoardView>
</template>

<script>
import { actions, getters } from './utils'
import BoardView from 'components/board/BoardView'
import FormMessage from './components/forms/FormMessage'

export default {
  components: { BoardView, FormMessage },
  data() {
    return {
      isSaving: false,
      message: this.$store.getters.messageFactory({
        authorUserId: this.$store.getters.authUser.id,
        userId: Number(this.$route.params.userId),
      }),
    }
  },
  computed: getters(),
  methods: {
    async create() {
      try {
        this.isSaving = true
        await this.CREATE_MESSAGE(this.message)
        this.SUCCESS('Žinutė išsiųsta')
        this.$router.push({ name: 'AdminUsers' })
      } finally {
        this.isSaving = false
      }
    },
    ...actions('CREATE_MESSAGE'),
  },
}
</script>
