<template>
  <CardTable class="CardProjectAdmin scale" :title="project.name">
    <Row>
      <Column :lg="3" :sm="6">
        <Set icon="cog" name="Būsena">
          {{ getProjectStatus(project.status).label }}
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set icon="bolt" name="Galingumas">
          {{ project.capacityUsed | kw }} / {{ project.capacity | kw }}
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set icon="bolt" name="Rezervuota savitarnoje">
          {{ project.capacityReserved | kw }}
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set icon="euro" name="Pirkimo kaina su PVM">
          {{ project.price }} €/kW
        </Set>
      </Column>
    </Row>
    <Row>
      <Column :xl="3" :xs="6">
        <Set icon="bill" name="Aktyvūs užsakymai">
          {{ projectStat.reservationReservedCount }}
        </Set>
      </Column>
      <Column :xl="3" :xs="6">
        <Set icon="euro" name="Gauti apmokėjimai">
          {{ projectStat.paymentPaidAmountTotal | currency }}
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set :icon="project.isPublic ? 'eye' : 'eye-slash'" name="Viešai matomas">
          {{ project.isPublic ? 'Taip' : 'Ne' }}
        </Set>
      </Column>
      <Column :xl="3" :xs="6">
        <Set icon="star" name="Pagrindiniame puslapyje">
          {{ project.isListed ? 'Taip' : 'Ne' }}
        </Set>
      </Column>
    </Row>
    <div slot="meta">
      <div v-if="hasRole('>=ADMIN')" class="ordering">
        <Tag v-if="project.order > 1" class="info" @click.native="offsetOrder(-1)">
          <Icon name="up" />
        </Tag>
        <Tag class="info" @click.native="offsetOrder(1)">
          <Icon name="down" />
        </Tag>
      </div>
      <Field
        label="Naudojamas skaičiuoklei"
        type="checkbox"
        :value="project.isMain"
        @update:value="setIsMain" />
    </div>
    <div slot="actions">
      <Btn
        class="ib sm inv no-border danger"
        @click="PROMPT({
          confirm: remove,
          text: 'Ar tikrai norite ištrinti šį projektą?',
          typeConfirm: 'trinti',
        })">
        Ištrinti
      </Btn>
      <Route
        :id="project.id"
        class="Btn inv no-border ib sm info"
        to="AdminProjectPosts">
        Projekto naujienos
      </Route>
      <Route
        :id="project.id"
        class="Btn ib sm info"
        to="AdminProject">
        Redaguoti
      </Route>
    </div>
  </CardTable>
</template>

<script>
import { actions, getUniqueArray, getters } from '../../utils'
import CardTable from './CardTable'
import Set from '../Set'

export default {
  components: { CardTable, Set },
  props: {
    project: Object,
  },
  computed: {
    projectStat() {
      return this.queryOne('projectStat', {
        projectId: this.project.id,
      })
    },
    ...getters('getProjectStatus'),
  },
  methods: {
    async resetOffsets() {
      const projectsAll = this.query('project', { $sort: { order: 1 } })

      await Promise.all(projectsAll
        .map(({ id }, index) => this
          .PATCH_PROJECT({
            id,
            order: index + 1,
          })))
    },
    async offsetOrder(offset) {
      const projectsAll = this.query('project')
      const uniqueOrderCount = getUniqueArray(projectsAll.map(project => project.order)).length

      if (uniqueOrderCount !== projectsAll.length) {
        await this.resetOffsets()
      }

      const orderCurrent = this.project.order
      const isIncreased = offset > 0

      const projectToSwapOrder = this.queryOne('project', {
        order: {
          [isIncreased ? '$gt' : '$lt']: orderCurrent,
        },
        $sort: {
          order: isIncreased ? 1 : -1,
        },
      })

      const doSwap = !!projectToSwapOrder

      const orderSwapped = (!doSwap || projectToSwapOrder.order === orderCurrent)
        ? orderCurrent + offset
        : projectToSwapOrder.order

      await Promise.all([
        ...doSwap
          ? [this.PATCH_PROJECT({
            id: projectToSwapOrder.id,
            order: orderCurrent,
          })] : [],
        this.PATCH_PROJECT({
          id: this.project.id,
          order: orderSwapped,
        }),
      ])

      await this.FETCH_PROJECT({
        $isForced: true,
        $sort: {
          order: 1,
        },
      })
    },
    remove() {
      this.DELETE_PROJECT(this.project)
    },
    setIsMain() {
      this.project.isMain = true

      this.PATCH_PROJECT({
        id: this.project.id,
        isMain: true,
      })
    },
    ...actions(
      'DELETE_PROJECT',
      'FETCH_PROJECT',
      'PATCH_PROJECT',
      'PROMPT',
    ),
  },
}
</script>

<style lang="scss">
.CardProjectAdmin {
  .meta {
    .ordering, .Field-cont {
      display: inline-block;
      margin: 0 1rem 0 0;
    }

    .ordering .Tag {
      cursor: pointer;
      margin-right: 0.25rem;
    }
  }
}
</style>
