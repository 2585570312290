<template>
  <div class="ReservationApvaChange">
    <Icon
      data-cy="apvaChange"
      name="edit"
      @click="isOpen = true" />
    <ModalCont v-if="isOpen" @close="close">
      <FormValidation @submit="updateApvaSettings">
        <Field
          label="Valstybės parama"
          :options="apvaOptions"
          required
          type="select"
          :value.sync="apvaChoice" />
        <Field
          v-if="apvaChoice === 'HAS_APVA' && apvaStageOptions.length > 1"
          label="Pasirinkite APVA etapą"
          :options="apvaStageOptions"
          :required="apvaChoice === 'HAS_APVA'"
          type="select"
          :value.sync="apvaStageId" />
        <Row class="actionsRow">
          <Column
            :xs="6">
            <Btn
              class="neutral"
              :disabled="isSaving"
              @click="close">
              Atšaukti
            </Btn>
          </Column>
          <Column
            :xs="6">
            <Btn
              class="success"
              :isLoading="isSaving"
              type="submit">
              Pakeisti
            </Btn>
          </Column>
        </Row>
      </FormValidation>
    </ModalCont>
  </div>
</template>

<script>
import { actions, getters } from '@/views/utils'
import { APVA_CHOICES } from '@/store/consts'

export default {
  props: {
    reservation: Object,
  },
  data() {
    return {
      apvaChoice: null,
      apvaStageId: null,
      isOpen: false,
      isSaving: false,
      apvaOptions: Object.entries(APVA_CHOICES),
    }
  },
  computed: {
    apvaStageOptions() {
      return [
        [null, 'Pasirinkite etapą'],
        ...this.query('apvaStage', {
          isHidden: false,
          $sort: { startDate: -1 },
        })
          .map(apvaStage => [
            apvaStage.id,
            apvaStage.startDate
              .toLocaleString('lt-LT', { year: 'numeric', month: 'long', day: 'numeric' }),
          ]),
      ]
    },
    ...getters(),
  },
  methods: {
    close() {
      this.isOpen = false
    },
    async updateApvaSettings() {
      await this.$wrap(
        () => this.PATCH_RESERVATION({
          id: this.reservation.id,
          apvaChoice: this.apvaChoice,
          apvaStageId: this.apvaStageId,
        }),
        'isSaving',
        'APVA nustatymai pakeisti sėkmingai',
      )

      this.close()
    },
    ...actions('FETCH_APVA_STAGE', 'PATCH_RESERVATION'),
  },
  mounted() {
    this.FETCH_APVA_STAGE()
    this.apvaChoice = this.reservation.apvaChoice
  },
}
</script>

<style lang="scss">
.ReservationApvaChange {
  display: inline-block;

  form {
    padding: $grid-unit-half $grid-unit $grid-unit;
  }

  &:hover {
    cursor: pointer;
  }
}
</style>
