<template>
  <BoardView title="Pridėti promo">
    <FormValidation @submit="create">
      <FormPromo :promo="promo" />
      <Btn class="info" :isLoading="isSaving" type="submit">
        Sukurti promo
      </Btn>
    </FormValidation>
  </BoardView>
</template>

<script>
import { actions, getters } from './utils'
import BoardView from 'components/board/BoardView'
import FormPromo from './components/forms/FormPromo'

export default {
  components: { BoardView, FormPromo },
  data() {
    return {
      isSaving: false,
      promo: this.$store.getters.promoFactory(),
    }
  },
  computed: getters(),
  methods: {
    async create() {
      try {
        this.isSaving = true
        await this.CREATE_PROMO(this.promo)
        this.$router.push({ name: 'AdminPromos' })
      } finally {
        this.isSaving = false
      }
    },
    ...actions('CREATE_PROMO'),
  },
}
</script>
