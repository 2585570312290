<template>
  <FormSect class="ObjectOwnerForm" title="Objekto savininkas">
    <div class="fields">
      <Field
        label="Pavadinimas"
        :value.sync="objectOwnerDetails.name" />
      <Field
        label="Juridinio asmens kodas"
        :value.sync="objectOwnerDetails.displayCode" />
      <Field
        label="PVM mokėtojo kodas"
        :value.sync="objectOwnerDetails.vatCode" />
      <Field
        label="Adresas"
        :value.sync="objectOwnerDetails.address" />
      <Field
        label="El. paštas"
        :value.sync="objectOwnerDetails.email" />
      <Field
        label="Telefonas"
        :value.sync="objectOwnerDetails.phone" />
      <Field
        label="Atstovas (pasirašantis asmuo)"
        :value.sync="objectOwnerDetails.representative" />
    </div>
  </FormSect>
</template>

<script>
export default {
  props: {
    objectOwnerDetails: Object,
  },
}
</script>

<style lang="scss">
.ObjectOwnerForm {
  .fields {
    display: grid;
    gap: $grid-unit;
    // 2 columns, 4 rows
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: repeat(4, minmax(0, 1fr));
    margin-bottom: $grid-unit;

    .Field-cont {
      margin: 0;
    }
  }
}
</style>
