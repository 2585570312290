<template>
  <BoardView :title="title">
    <FormValidation @submit="save">
      <FormSolarInverter :solarInverter="solarInverter" />
      <Btn class="info" :isLoading="isSaving" type="submit">
        Išsaugoti
      </Btn>
    </FormValidation>
  </BoardView>
</template>

<script>
import { actions, getters } from './utils'
import BoardView from 'components/board/BoardView'
import FormSolarInverter from './components/forms/FormSolarInverter'

export default {
  components: { BoardView, FormSolarInverter },
  data() {
    return {
      isSaving: false,
      solarInverter: {},
    }
  },
  computed: {
    title() {
      return `${this.solarInverter.manufacturer} ${this.solarInverter.model}`
    },
    ...getters(),
  },
  methods: {
    async save() {
      try {
        this.isSaving = true
        await this.PATCH_SOLAR_INVERTER(this.solarInverter)
        this.$router.push({ name: 'AdminSolarInverters' })
      } finally {
        this.isSaving = false
      }
    },
    ...actions('PATCH_SOLAR_INVERTER'),
  },
  created() {
    this.POST_LOAD(() => {
      this.solarInverter = { ...this.query('solarInverter', Number(this.$route.params.id)) }
    })
  },
}
</script>

<style lang="scss">
</style>
