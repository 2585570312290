<template>
  <CardTable
    class="scale"
    :data-cy="paymentCardCy"
    :isChecked="isChecked"
    :title="getPaymentTypeLabel(payment.type)"
    @update:isChecked="$emit('update:isChecked', $event)">
    <Row>
      <Column :lg="3" :sm="6">
        <Set icon="euro" name="Suma">
          {{ payment.amountTotal | currency }}
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set icon="tag" name="Būsena">
          {{ status }}
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set icon="user" name="Vartotojas">
          {{ payee | name }} ({{ payee.id }})
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set icon="bolt" name="Projektas">
          {{ project.name }}
        </Set>
      </Column>
    </Row>
    <Row>
      <Column :lg="3" :sm="6">
        <Set icon="bill" name="Paysera užsakymo nr.">
          {{ payment.id }}
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set icon="asterisk" name="Sąskaita">
          {{ payment.invoiceNo }}
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set icon="bolt" name="Užsakymo dydis">
          {{ reservation.capacity | kw }}
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set :icon="payment.isSeen ? 'eye' : 'eye-slash'" name="Matytas">
          {{ payment.isSeen ? 'Taip' : 'Ne' }}
        </Set>
      </Column>
    </Row>
    <Row>
      <Column :lg="3" :sm="6">
        <Set icon="clock" name="Sukurtas">
          {{ payment.createdAt | dateTime }}
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set icon="clock" name="Apmokėti iki">
          {{ payment.deadlineAt | dateTime }}
        </Set>
      </Column>
    </Row>
    <div slot="actions">
      <Btn
        v-if="hasRole('>=SUPERADMIN')"
        class="ib sm inv no-border danger"
        icon="danger"
        :isLoading="isDeleting"
        @click="PROMPT({
          confirm: remove,
          text: 'Ar tikrai norite ištrinti šį mokėjimą? Tai ištrins ir mokėjimo failus (sąskaitą faktūrą). Kopijos buhalterijoje liks nepaliestos. Jas trinti reikia atskirai.',
          typeConfirm: 'trinti testui',
        })">
        Ištrinti
      </Btn>
      <Btn
        v-if="!payment.refundedAt"
        class="ib sm inv danger no-border"
        :isLoading="isRefunding"
        @click="PROMPT({
          confirm: setRefunded,
          text: 'Ar tikrai norite pažymėti šį mokėjimą kaip grąžintą? Tai atšauks ir šio mokėjimo rezervaciją (jeigu ji dar nebuvo atšaukta).',
        })">
        Grąžinti
      </Btn>
      <Btn
        v-if="!payment.paidAt && !payment.cancelledAt"
        class="ib sm info"
        data-cy="markAsPaidButton"
        :isLoading="isSaving"
        @click="PROMPT({
          confirm: setPaid,
          text: 'Ar tikrai norite pažymėti šį mokėjimą kaip apmokėtą?',
        })">
        Pažymėti kaip apmokėtą
      </Btn>
      <Btn
        v-if="payment.fileId"
        class="ib sm info"
        data-cy="download"
        icon="arrow-down"
        @click="DOWNLOAD_FILE({ id: payment.fileId })">
        Atsisiųsti
      </Btn>
    </div>
  </CardTable>
</template>

<script>
import { actions, camelCase, getters } from '@/views/utils'
import CardTable from './CardTable'
import Set from '../Set'

export default {
  components: { CardTable, Set },
  props: {
    payment: Object,
    isChecked: Boolean,
  },
  data() {
    return {
      isDeleting: false,
      isRefunding: false,
      isSaving: false,
    }
  },
  computed: {
    project() {
      return this.query('project', this.payment.projectId)
    },
    reservation() {
      return this.query('reservation', this.payment.reservationId)
    },
    payee() {
      return this.query('user', this.payment.userId)
    },
    status() {
      return this.getPaymentStatus(this.payment).label
    },
    paymentCardCy() {
      const { type } = this.payment
      const transformedType = camelCase(type)
      return `paymentCard${transformedType}`
    },
    ...getters(
      'getPaymentStatus',
      'getPaymentTypeLabel',
    ),
  },
  methods: {
    async setPaid() {
      this.$wrap(
        this.PATCH_PAYMENT({
          id: this.payment.id,
          paidAt: new Date(),
        }),
        'isSaving',
        'Mokėjimas pažymėtas kaip apmokėtas',
      )
    },
    async setRefunded() {
      this.$wrap(
        this.PATCH_PAYMENT({
          id: this.payment.id,
          refundedAt: new Date(),
        }),
        'isRefunding',
        'Mokėjimas pažymėtas kaip grąžintas',
      )
    },
    async remove() {
      this.$wrap(
        this.DELETE_PAYMENT(this.payment),
        'isDeleting',
        'Mokėjimas ištrintas',
      )
    },
    ...actions(
      'DELETE_PAYMENT',
      'DOWNLOAD_FILE',
      'PATCH_PAYMENT',
      'PROMPT',
    ),
  },
}
</script>

<style lang="scss">
</style>
