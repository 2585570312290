<script>
import { getters, mergeAll } from 'views/utils'

const unwrapIn = value => value.$in || [value]

const joinToIn = (...objects) => ({ $in: objects.flatMap(unwrapIn) })

const processAppend = (append, value) => {
  if (value === undefined || value === null) return {}
  if (typeof append === 'function') return append(value)
  if (typeof append === 'string') return { [append]: value }

  return {
    [append[0]]: {
      [append[1]]: Array.isArray(value)
        ? { $in: value }
        : value,
    },
  }
}

export default {
  data() {
    return {
      isFetching: false,
    }
  },
  computed: {
    boundsQuery() {
      const { bounds } = this.pagination

      if (!bounds || !bounds.keys.length) return {}

      return {
        $server: {
          $bounds: bounds,
        },
      }
    },
    customQuery() {
      const { customQuery } = this.pagination

      return customQuery
    },
    filtersQuery() {
      const { filters, filtersValues } = this.pagination

      return mergeAll(Object
        .entries(filtersValues)
        .map(([label, value]) => {
          // TODO: consider not using label for filters. Might result in a bug
          // when locale is changed
          const filterItem = filters.find(filter => filter.label === label)

          if (!filterItem || !filterItem.append) return {}

          return processAppend(filterItem.append, value)
        })
        .filter(Boolean))
    },
    searchQuery() {
      const { search, searchIn } = this.pagination

      return !searchIn || !search
        ? {}
        : {
          $or: searchIn.map(searchKey => ({
            [searchKey]: { $ilike: search },
          })),
        }
    },
    sortQuery() {
      const { sortIndex, sortOptions } = this.pagination

      return sortIndex !== null && sortOptions.length
        ? { $sort: this.pagination.sortOptions[this.pagination.sortIndex].sort }
        : {}
    },
    tabsQuery() {
      const { tabs, tabsIndexes } = this.pagination

      return tabsIndexes
        .map(tabIndex => tabs[tabIndex])
        .filter(({ append }) => append)
        .map(({ append, value }) => processAppend(append, value))
        .reduce((mergedQuery, value) => ({
          ...mergedQuery,
          ...value,
          ...Object.fromEntries(Object
            .keys(value)
            .filter(key => mergedQuery[key] !== undefined && mergedQuery[key] !== null)
            .map(key => [key, joinToIn(mergedQuery[key], value[key])])),
        }), {})
    },
    fetchQuery() {
      if (!this.pagination.isStored) return

      const forcedQuery = Number(this.$route.query.isRefetch)
        ? { $isForced: true }
        : {}

      return {
        $skip: this.skipped,
        ...this.boundsQuery,
        ...this.tabsQuery,
        ...this.searchQuery,
        ...this.filtersQuery,
        ...this.sortQuery,
        ...this.customQuery,
        ...forcedQuery,
      }
    },
    pageIds() {
      if (!this.pagination.isStored) return

      const queryPage = this
        .getQueryPage(this.pagination.service, this.fetchQuery, this.skipped)

      if (!queryPage) return

      return queryPage.slice(0, this.pagination.perPage)
    },
    skipped() {
      return this.pagination.pageIndex * this.pagination.perPage
    },
    paginationStored() {
      return this.getPagination(this.paginationOptions.name)
    },
    pagination() {
      return this.paginationStored || this.paginationOptions
    },
    total() {
      return this.getPageTotal(this.pagination.service, this.fetchQuery) || 0
    },
    results() {
      if (!this.pageIds) return []

      return this.pageIds
        .map(id => this.query(this.pagination.service, id))
        .filter(Boolean)
    },
    rows() {
      return this.splitIntoRows(this.results, this.pagination.columns)
    },
    ...getters(
      'getPageTotal',
      'getPagination',
      'getQueryPage',
      'getStoreModule',
      'isDesktop',
      'splitIntoRows',
    ),
  },
  methods: {
    async fetchByQuery() {
      if (!this.pagination.isStored) return
      if (typeof this.isPaginationFetchEnabled !== 'undefined' && !this.isPaginationFetchEnabled) {
        return
      }

      const { storeSuffix } = this.getStoreModule(this.pagination.service)

      try {
        this.isFetching = true

        await this.$store.dispatch(`FETCH_${storeSuffix}`, this.fetchQuery)
      } finally {
        // TODO: check if necessary?
        this.$nextTick(() => {
          this.isFetching = false
        })
      }
    },

    // TODO: generalize from PaginateAccess?
    patchCustomQuery(customQuery) {
      return this.$store.dispatch('PATCH_PAGINATION', {
        pagination: this.pagination,
        customQuery: {
          ...this.pagination.customQuery || {},
          ...customQuery,
        },
      })
    },
    setSearch(search) {
      return this.$store.dispatch('PATCH_PAGINATION', {
        pagination: this.pagination,
        search,
      })
    },
    resetPageIndex() {
      this.$store.dispatch('PATCH_PAGINATION', {
        pagination: this.pagination,
        pageIndex: 0,
      })
    },
  },
  watch: {
    'pagination.customQuery': {
      deep: true,
      handler() {
        if (!this.pagination.isStored) return
        this.resetPageIndex()
      },
    },
    'pagination.filtersValues': {
      deep: true,
      async handler() {
        this.resetPageIndex()
        await this.fetchByQuery()
      },
    },
    fetchQuery: {
      immediate: true,
      async handler() {
        await this.fetchByQuery()
      },
    },
  },
  created() {
    if (!this.$store.getters.getPagination(this.paginationOptions.name)) {
      this.$store.dispatch('PUSH_PAGINATION', this.pagination)
    }
  },
  render() {
    return ''
  },
}
</script>
