<template>
  <BoardView v-if="promo" :title="promo.prefix">
    <h3>Promo kodai:</h3>
    <ul>
      <li v-for="promoCode in promoCodes" :key="promoCode.id">
        {{ promoCode.code }} {{ promoCode.isUsed ? '- Panaudotas' : '' }}
      </li>
    </ul>
  </BoardView>
</template>

<script>
import { actions, getters } from './utils'
import BoardView from 'components/board/BoardView'

export default {
  components: { BoardView },
  computed: {
    promo() {
      return this.query('promo', Number(this.promoId))
    },
    promoCodes() {
      return this.query('promoCode', {
        promoId: this.promo.id,
      })
    },
    promoId() {
      return Number(this.$route.params.id)
    },
    ...getters(),
  },
  methods: actions('FETCH_PROMO_CODE'),
  created() {
    this.LOAD(() => this.FETCH_PROMO_CODE({ promoId: this.promoId }))
  },
}
</script>

<style lang="scss">
</style>
