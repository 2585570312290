<template>
  <BoardView :title="snippet.key">
    <FormValidation @submit="save">
      <FormSnippet :snippet="snippet" />
      <Btn class="info" :isLoading="isSaving" type="submit">
        Išsaugoti
      </Btn>
    </FormValidation>
  </BoardView>
</template>

<script>
import { actions, getters } from './utils'
import BoardView from 'components/board/BoardView'
import FormSnippet from './components/forms/FormSnippet'

export default {
  components: { BoardView, FormSnippet },
  data() {
    return {
      isSaving: false,
      snippet: {},
    }
  },
  computed: {
    ...getters(),
  },
  methods: {
    async save() {
      try {
        this.isSaving = true
        await this.PATCH_SNIPPET(this.snippet)
        this.$router.push({ name: 'AdminSnippets' })
      } finally {
        this.isSaving = false
      }
    },
    ...actions('PATCH_SNIPPET'),
  },
  created() {
    this.POST_LOAD(() => {
      this.snippet = { ...this.query('snippet', Number(this.$route.params.id)) }
    })
  },
}
</script>

<style lang="scss">
</style>
