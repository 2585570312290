import { filter, chain as flatMap, map, pipe, range } from 'rambda'
import { pluckTruthy } from 'views/utils/collections'

export default (yearCurrent, contracts, reservations) => {
  const yearFrom = getYearFrom(yearCurrent, contracts, reservations)

  return range(yearFrom, yearCurrent + 1).reverse()
}

const getYearFrom = (yearCurrent, contracts, reservations) => {
  const contractYearMin = pipe(
    pluckTruthy('createdAt'),
    map(date => date.getTime()),
    times => Math.min(...times),
    time => Number.isSafeInteger(time)
      ? new Date(time).getUTCFullYear()
      : null,
  )(contracts)

  const adjustmentsYears = pipe(
    pluckTruthy('inflationAdjustments'),
    flatMap(pipe(
      Object.keys,
      map(Number),
    )),
  )(reservations)

  return pipe(
    filter(Number.isSafeInteger),
    years => Math.min(...years),
  )([yearCurrent, contractYearMin, ...adjustmentsYears])
}
