<template>
  <FormSect class="PaymentAccountSelect" title="Mokėjimai">
    <div class="tabs">
      <Tab
        v-for="{ label, value } in paymentTabs"
        :key="label"
        :isActive="paymentTabActive === value"
        @click="paymentTabActive = value">
        {{ label }}
      </Tab>
    </div>
    <div>
      <div v-if="paymentTabActive === 'LIST' && paymentAccountsOptions.length">
        <Field
          :disabled="!canChange"
          :options="paymentAccountsOptions"
          placeholder="NĖRA MOKĖJIMŲ SĄSKAITOS"
          type="select"
          :value="paymentAccountId"
          @update:value="setPaymentAccountId" />
        <PaymentAccountForm
          v-if="paymentAccountActive"
          :disabled="!canChange"
          :isLocked="true"
          :paymentAccount="paymentAccountActive">
          <Btn class="ib" :isLoading="isSaving" @click="patchPaymentAccount">
            Atnaujinti sąskaitą
          </Btn>
        </PaymentAccountForm>
      </div>
      <div v-else-if="paymentTabActive === 'ADD'">
        <PaymentAccountForm
          :disabled="!canChange"
          :paymentAccount="paymentAccount">
          <Btn class="ib" :isLoading="isSaving" @click="createPaymentAccount">
            Sukurti sąskaitą
          </Btn>
        </PaymentAccountForm>
      </div>
    </div>
    <Notice v-if="!canChange" class="warning">
      Mokėjimų sąskaitą keisti galima tik kai projekto būsena yra "Ruošiama".
    </Notice>
  </FormSect>
</template>

<script>
import { actions, detach, getters } from '@/views/utils'
import PaymentAccountForm from './PaymentAccountForm'
import Tab from 'components/Tab'
import { omit } from 'rambda'

export default {
  components: {
    PaymentAccountForm,
    Tab,
  },
  props: {
    canChange: Boolean,
    paymentAccountId: Number,
  },
  data() {
    return {
      paymentAccounts: [],
      paymentAccount: this.$store.getters.paymentAccountFactory(),
      paymentAccountActive: null,

      paymentTabActive: 'LIST',
      paymentTabs: [
        { label: 'Egzistuojančios sąskaitos', value: 'LIST' },
        { label: 'Nauja sąskaita', value: 'ADD' },
      ],

      isSaving: false,
    }
  },
  computed: {
    paymentAccountsOptions() {
      return this.paymentAccounts
        .map(({ id, label }) => ({
          label,
          value: id,
        }))
    },
    ...getters(),
  },
  methods: {
    setPaymentAccountId(paymentAccountId) {
      this.$emit('update:paymentAccountId', paymentAccountId)
    },
    createPaymentAccount() {
      return this.$wrap(async () => {
        const paymentAccount = await this.CREATE_PAYMENT_ACCOUNT(this.paymentAccount)
        await this.syncPaymentAccounts()
        this.setPaymentAccountId(paymentAccount.id)
      }, 'isSaving', 'Sąskaita sukurta')
    },
    patchPaymentAccount() {
      return this.$wrap(async () => {
        const paymentAccountActive = omit(
          ['password', 'originProjectId'],
          this.paymentAccountActive,
        )

        await this.PATCH_PAYMENT_ACCOUNT(paymentAccountActive)
        await this.syncPaymentAccounts()
      }, 'isSaving', 'Sąskaita atnaujinta')
    },
    async syncPaymentAccounts() {
      this.paymentAccounts = await this.FIND_PAYMENT_ACCOUNT() || []
    },
    setDetachedPaymentAccount() {
      if (this.paymentAccountId) {
        const paymentAccountActive = this.paymentAccounts
          .find(({ id }) => id === this.paymentAccountId)

        if (!paymentAccountActive) return

        this.paymentAccountActive = detach(paymentAccountActive)
      } else {
        this.paymentAccountActive = null
      }
    },
    ...actions(
      'CREATE_PAYMENT_ACCOUNT',
      'FIND_PAYMENT_ACCOUNT',
      'PATCH_PAYMENT_ACCOUNT',
    ),
  },
  watch: {
    paymentAccounts: {
      immediate: true,
      handler: 'setDetachedPaymentAccount',
    },
    paymentAccountId: {
      immediate: true,
      handler: 'setDetachedPaymentAccount',
    },
  },
  async mounted() {
    await this.syncPaymentAccounts()
  },
}
</script>

<style lang="scss">
.PaymentAccountSelect {
  > .tabs {
    margin-bottom: $grid-unit;
  }
}
</style>
