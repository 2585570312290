<template>
  <CardTable
    class="scale"
    :data-cy="paymentCardCy"
    :title="getPaymentTypeLabel(payment.type)">
    <Row>
      <Column :lg="3" :sm="6">
        <Set icon="euro" name="Suma">
          {{ payment.amountTotal | currency }}
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set icon="tag" name="Būsena">
          {{ status }}
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set icon="user" name="Vartotojas">
          {{ payee | name }} ({{ payee.id }})
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set icon="bolt" name="Projektas">
          {{ project.name }}
        </Set>
      </Column>
    </Row>
    <Row>
      <Column :lg="3" :sm="6">
        <Set icon="bill" name="Paysera užsakymo nr.">
          {{ payment.id }}
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set icon="asterisk" name="Sąskaita">
          {{ payment.invoiceNo }}
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set icon="bolt" name="Užsakymo dydis">
          {{ reservation.capacity | kw }}
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set :icon="payment.isSeen ? 'eye' : 'eye-slash'" name="Matytas">
          {{ payment.isSeen ? 'Taip' : 'Ne' }}
        </Set>
      </Column>
    </Row>
    <Row>
      <Column :lg="3" :sm="6">
        <Set icon="clock" name="Sukurtas">
          {{ payment.createdAt | dateTime }}
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set icon="clock" name="Apmokėti iki">
          {{ payment.deadlineAt | dateTime }}
        </Set>
      </Column>
    </Row>
    <div v-if="paymentPreview">
      {{ paymentPreview }}
    </div>
    <div slot="actions">
      <Btn
        class="ib sm info"
        :isLoading="isGeneratingPreview"
        @click="generatePaymentPreview">
        Paruošti peržiūrą
      </Btn>
      <Btn
        class="ib sm info"
        :isLoading="isGeneratingPreviewUpcoming"
        @click="generatePaymentPreviewUpcoming">
        Paruošti ateinančio mokėjimo peržiūrą
      </Btn>
      <Btn
        class="ib sm info"
        :disabled="!paymentPreview"
        :isLoading="isGenerating"
        @click="generatePayment">
        Pergeneruoti mokėjimą
      </Btn>
      <!--
      <Btn
        v-if="payment.fileId"
        class="ib sm info"
        data-cy="download"
        icon="arrow-down"
        @click="DOWNLOAD_FILE({ id: payment.fileId })">
        Atsisiųsti
      </Btn>
      -->
    </div>
  </CardTable>
</template>

<script>
import { actions, camelCase, getters } from '@/views/utils'
import CardTable from './CardTable'
import Set from '../Set'

export default {
  components: { CardTable, Set },
  props: {
    payment: Object,
  },
  data() {
    return {
      isGeneratingPreview: false,
      isGeneratingPreviewUpcoming: false,
      isGenerating: false,
      paymentPreview: null,
    }
  },
  computed: {
    project() {
      return this.query('project', this.payment.projectId)
    },
    reservation() {
      return this.query('reservation', this.payment.reservationId)
    },
    payee() {
      return this.query('user', this.payment.userId)
    },
    status() {
      return this.getPaymentStatus(this.payment).label
    },
    paymentCardCy() {
      const typeCamel = camelCase(this.payment.type)
      return `paymentCard${typeCamel}`
    },
    ...getters(
      'getPaymentStatus',
      'getPaymentTypeLabel',
    ),
  },
  methods: {
    async generatePayment() {
      this.paymentPreview = await this.$wrap(
        this.PATCH_RAW_PAYMENT({
          id: this.payment.id,
          $doRegenerate: true,
        }),
        'isGenerating',
        'Mokėjimas pergeneruotas',
      )
    },
    async generatePaymentPreview() {
      this.paymentPreview = await this.$wrap(
        this.PATCH_RAW_PAYMENT({
          id: this.payment.id,
          $doRegenerate: true,
          $isDryCall: true,
        }),
        'isGeneratingPreview',
        'Mokėjimo peržiūra paruošta',
      )
    },
    async generatePaymentPreviewUpcoming() {
      this.paymentPreview = await this.$wrap(
        this.PATCH_RAW_PAYMENT({
          id: this.payment.id,
          $doRegenerateUpcoming: true,
          $isDryCall: true,
          createdAt: new Date(),
        }),
        'isGeneratingPreviewUpcoming',
        'Mokėjimo peržiūra paruošta',
      )
    },
    ...actions(
      'PATCH_RAW_PAYMENT',
      'DOWNLOAD_FILE',
      'PROMPT',
    ),
  },
}
</script>

<style lang="scss">
</style>
