<template>
  <CardTable>
    <Row>
      <Column :lg="3" :sm="6">
        <Set v-if="author" icon="user" name="Eksportavo">
          {{ author | name }} ({{ esoSnapshot.authorUserId }})
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set icon="bolt" name="Projektas">
          {{ project.name }}
        </Set>
      </Column>
      <Column :lg="3" :sm="6">
        <Set icon="mail" name="Pateiktas">
          {{ esoSnapshot.sentAt | dateTime | fallback }}
        </Set>
      </Column>
    </Row>
    <div slot="meta">
      {{ esoSnapshot.createdAt | dateTime }}
    </div>
    <div slot="actions">
      <Btn
        v-if="!isSent && hasRole('>=SUPERADMIN')"
        class="ib sm inv no-border danger"
        icon="danger"
        @click="PROMPT({
          confirm: () => DELETE_ESO_SNAPSHOT(esoSnapshot),
          text: 'Ar tikrai norite ištrinti šį ESO dokumentą?',
        })">
        Trinti
      </Btn>
      <Btn
        class="ib sm inv no-border warning"
        icon="check"
        :isLoading="isToggling"
        @click="PROMPT({
          confirm: isSent ? markAsNonSent : markAsSent,
          text: 'Ar tikrai norite pažymėti šį ESO dokumentą kaip išsiųstą?',
        })">
        Pažymėti kaip {{ isSent ? 'nepateiktą' : 'pateiktą' }}
      </Btn>
      <Btn
        class="ib sm info"
        icon="arrow-down"
        @click="DOWNLOAD_ALL(esoSnapshot)">
        Atsisiųsti
        <template v-if="esoSnapshot.fileIds.length > 1">
          ({{ esoSnapshot.fileIds.length }} failai)
        </template>
      </Btn>
    </div>
  </CardTable>
</template>

<script>
import { actions, getters } from 'views/utils'
import CardTable from './CardTable'
import Set from '../Set'

export default {
  components: { CardTable, Set },
  props: {
    esoSnapshot: Object,
  },
  data() {
    return {
      isToggling: false,
    }
  },
  computed: {
    author() {
      return this.query('user', this.esoSnapshot.authorUserId)
    },
    isSent() {
      return this.esoSnapshot.sentAt
    },
    project() {
      return this.query('project', this.esoSnapshot.projectId)
    },
    ...getters(),
  },
  methods: {
    markAsSent() {
      this.toggleIsSent(new Date(), 'pateiktas')
    },
    markAsNonSent() {
      this.toggleIsSent(null, 'nepateiktas')
    },
    setSentAt(sentAt) {
      return this.PATCH_ESO_SNAPSHOT({
        id: this.esoSnapshot.id,
        sentAt,
      })
    },
    toggleIsSent(sentAt, stateMessage) {
      this.$wrap(
        this.setSentAt(sentAt),
        'isToggling',
        `ESO dokumentas pažymėtas kaip ${stateMessage}`,
      )
    },
    ...actions(
      'DELETE_ESO_SNAPSHOT',
      'DOWNLOAD_ALL',
      'PATCH_ESO_SNAPSHOT',
      'PROMPT',
    ),
  },
}
</script>

<style lang="scss">
</style>
